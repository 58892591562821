import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import map from 'lodash/map';

import { FilterConfig } from 'FilterTypes';

import { LegacySavingStates } from '@kwara/models/src/models/Saving';

import { FILTER_TYPES } from '../../../components/Filter/utils/filterTypes';
import { StatusFilter } from '../../Members/components/MemberList/StatusFilter';

export function useLegacyFilter() {
  const { formatMessage } = useIntl();

  return useMemo<FilterConfig>(() => {
    return {
      [FILTER_TYPES.STATUS]: {
        data: [
          {
            value: '',
            name: formatMessage({ id: 'LoanList.noFilter' }),
            belongsTo: FILTER_TYPES.STATUS
          },
          ...map(LegacySavingStates, value => ({
            value,
            name: formatMessage({ id: `StatusTag.${value}` }),
            belongsTo: FILTER_TYPES.STATUS
          }))
        ],
        page: StatusFilter
      }
    };
  }, [formatMessage]);
}
