import React from 'react';

import { Table } from './Table';

import { Field, Select } from '@kwara/components/src/Form';
import { useChargesTable } from './useChargesTable';

export function Charges({ data, formProps, onChange }) {
  const chargeOptions = data.chargeOptions;
  const charges = formProps?.values?.charges || [];
  const { selectableChargeOptions, selectedCharges, onRemove, onRestore, onAdd } = useChargesTable({
    chargeOptions,
    charges,
    onChange
  });

  return (
    <>
      <Field>
        <Select
          size="regular"
          onChange={e => {
            if (e.target.value) {
              onAdd(e.target.value);
            }
          }}
        >
          <Select.Option value="" translationId="LoanAdd.Charges.Add.options.default" />
          {selectableChargeOptions.map(option => (
            <Select.Option key={option.id} value={option.id}>
              {option.name}
            </Select.Option>
          ))}
        </Select>
      </Field>
      <Table charges={selectedCharges} onRemove={onRemove} onRestore={onRestore} />
    </>
  );
}

Charges.validate = {};
