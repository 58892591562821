import React from 'react';

import { Text } from '@kwara/components/src/Intl';
import { Loadable } from '@kwara/components/src/Loadable';
import { Statistic } from '@kwara/components/src/Statistic';
import { JournalEntryT } from '@kwara/models/src/models/JournalEntry';

import { useJournalEntry } from '../../../../../hooks';
import { Row, Cell } from '../../../../../components/Table';

type JournalEntryDetailsProps = {
  transactionId: string;
};

export function JournalEntryDetails({ transactionId }: JournalEntryDetailsProps) {
  const r = useJournalEntry(transactionId);

  return (
    <Row className="bg-light-grey-300">
      <Loadable {...r}>
        {(t: JournalEntryT) => (
          <>
            <Cell colSpan={2}>
              <Statistic
                size="small"
                title={<Text id="Finance.JournalEntries.Table.accountId" />}
                value={t.accountId()}
              />
            </Cell>
            <Cell colSpan={2}>
              <Statistic
                size="small"
                title={<Text id="Finance.JournalEntries.Table.memberId" />}
                value={t.memberId()}
              />
              <Statistic
                size="small"
                title={<Text id="Finance.JournalEntries.Table.memberName" />}
                value={t.memberName()}
              />
            </Cell>
            <Cell colSpan={4}>
              <Statistic size="small" title={<Text id="Finance.JournalEntries.Table.notes" />} value={t.notes} />
            </Cell>
          </>
        )}
      </Loadable>
    </Row>
  );
}
