import React from 'react';
import { useQuery, QueryFunctionContext } from 'react-query';
import { BalanceSheet } from '@kwara/models/src/models/v1/BalanceSheet/Report';
import { formatIsoDate } from '@kwara/lib/src/dates';

type QueryKey = ['balance_sheet', string];

async function fetchBalanceSheet({ queryKey }: QueryFunctionContext<QueryKey>) {
  const [_key, endDate] = queryKey;

  return BalanceSheet.extraParams({
    end_date: formatIsoDate(endDate)
  })
    .find()
    .then(res => res.data);
}

export function useBalanceSheet({ endDate }: { endDate: string }) {
  return useQuery(['balance_sheet', endDate], fetchBalanceSheet, {
    enabled: !!endDate,
    retry: false
  });
}

export function useBalanceSheetPage() {
  const [params, setParams] = React.useState<{ endDate: string | null }>({ endDate: null });

  const { data: report, isLoading } = useBalanceSheet(params);

  const onSubmit = React.useCallback(({ endDate }) => setParams(prev => ({ ...prev, endDate })), []);

  return { report, onSubmit, isLoading };
}
