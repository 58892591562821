import React, { useRef, useState } from 'react';
import concat from 'lodash/concat';
import getOr from 'lodash/fp/getOr';

import { GuarantorCardBaseProps, SaveAs, OnClickHandlerArg } from 'GuarantorCardTypes';
import { LoanSubstepProps } from 'GlobalTypes';

import { If, Button, Text } from '@kwara/components/src';
import { MemberType, Guarantor as GuarantorModel, GuaranteeType } from '@kwara/models/src';
import { AccessibleLoader } from '@kwara/components/src/AccessibleLoader';
import { LinkButton } from '@kwara/components/src/LinkButton';

import Expander from '../Expander/Expander';
import { useSaveGuarantor } from './useSaveGuarantor';
import { composeErrors, httpErrorHandler } from '../../services';
import { TemporaryHider } from '../TemporaryHider/TemporaryHider';
import { useBoolean } from '../../../../shared-lib/src/hooks/useBoolean';
import { validate } from './GuarantorImp';
import { GuarantorSearch } from './components/GuarantorSearch/GuarantorSearch';
import { GuarantorCard } from './components/GuarantorCard/GuarantorCard';
import { GuarantorsLister } from './components/GuarantorsLister';
import { SAVE_AS } from './components/GuarantorCard/useGuarantorCard';

interface GuarantorsPropTypes extends LoanSubstepProps {
  withControls?: boolean;
  readonly?: boolean;
  saveAs?: SaveAs;
  disableEditing?: boolean; // temporary prop to disable editing for v1 flows
}

function Guarantors(props: GuarantorsPropTypes) {
  const {
    formProps,
    data,
    onChange,
    addData,
    TextArea,
    disableEditing = false,
    withControls = false,
    readonly = false,
    saveAs = SAVE_AS.CLIENT
  } = props;
  const { guarantors, product } = data;

  const [isLoading, setIsLoading] = useState(false);
  const [shownAddNotes, setShownNotes] = useState(false);
  const [guarantorIndexToEdit, setGuarantorIndexToEdit] = useState(-1);
  const guarantorCardEditorRef = useRef<HTMLDivElement>(null);
  const [startAddingGuarantor, { setToTrue: onStartAddingGuarantor }] = useBoolean(!withControls);
  const { mutateAsync: saveGuarantor, isLoading: isSavingGuarantor, error, isError, reset } = useSaveGuarantor();

  const { hasError, errorCodes, hasBlockingError, resetError } = composeErrors({
    errors: isError ? httpErrorHandler.generateError(error) : {},
    resetters: [reset],
    blockingErrorCodes: ['DUPLICATED_GUARANTOR_WITHOUT_SAVINGS_ACCOUNT']
  });
  const showEditor = guarantorIndexToEdit >= 0;
  const formState = formProps.form && formProps.form.getState();
  const validGuarantors = (guarantors || []).filter(guarantor => !guarantor.isMarkedForDestruction);
  const hasGuarantors = !!validGuarantors?.length;

  function onHideEditor() {
    setGuarantorIndexToEdit(-1);
  }

  async function onAddMember(member: MemberType) {
    const inst = new GuarantorModel({ member, memberId: member.id });
    const augmentedGuarantors = concat(guarantors || [], inst);
    const size = augmentedGuarantors.length;

    await onChange({ guarantors: augmentedGuarantors }).then(() => {
      setGuarantorIndexToEdit(size === 0 ? size : size - 1);
      resetError();
    });
  }

  function onClickHOF(index: number) {
    return async (args: OnClickHandlerArg) => {
      const copiedGuarantors = [...guarantors];
      const formGuarantee: GuaranteeType = getOr({}, `values.guarantors[${index}]`, formState);

      copiedGuarantors.splice(index, 1, {
        ...copiedGuarantors[index],
        amount: formGuarantee.amount,
        isMarkedForDestruction: args.type === 'REMOVE'
      });

      try {
        await onChange({ guarantors: copiedGuarantors });
        args.onSuccess?.(onHideEditor);
      } catch (_) {
        args.onError?.();
      }
    };
  }

  function getGuarantorCardProps(): GuarantorCardBaseProps {
    const member = showEditor && guarantors[guarantorIndexToEdit].member;
    const formGuarantee: GuaranteeType = getOr({}, `values.guarantors[${guarantorIndexToEdit}]`, formState);
    const isMarkedForDestruction = formGuarantee.isMarkedForDestruction;

    return {
      index: guarantorIndexToEdit,
      isMarkedForDestruction,
      loanId: data.loan?.id,
      member,
      product,
      addData,
      saveAs,
      amount: formGuarantee.amount,
      hasError,
      errorCodes,
      isSavingGuarantor,
      saveGuarantor,
      onHideEditor,
      hasBlockingError,
      onClickHandler: onClickHOF(guarantorIndexToEdit)
    };
  }

  return (
    <Expander.Root aria-label="Guarantors">
      {({ isExpanded, state, toggle }) => (
        <>
          <Expander.Header
            isExpanded={isExpanded}
            onToggle={() => {
              toggle();
              onStartAddingGuarantor();
            }}
          >
            <div className="flex justify-between">
              <Text id="Guarantor.heading" />
              <If
                condition={withControls && !disableEditing}
                do={
                  <div>
                    <Button
                      type="secondary"
                      disabled={isLoading || isSavingGuarantor || showEditor}
                      onClick={() => {
                        onStartAddingGuarantor();
                        toggle();
                      }}
                    >
                      <Text id="Guarantor.add" />
                    </Button>
                  </div>
                }
              />
            </div>
          </Expander.Header>

          <Expander.Body state={state}>
            <If
              condition={startAddingGuarantor && !disableEditing}
              do={
                <GuarantorSearch
                  data={data}
                  onSelect={onAddMember}
                  onFetchStart={() => setIsLoading(true)}
                  onFetchEnd={() => setIsLoading(false)}
                />
              }
            />

            <If condition={isLoading} do={<AccessibleLoader isLoading={isLoading} itsTextLoader time={10} />} />

            {!disableEditing && (
              <div ref={guarantorCardEditorRef} className="mb3">
                <If condition={showEditor} do={<GuarantorCard {...getGuarantorCardProps()} />} />
              </div>
            )}

            <If
              condition={hasGuarantors}
              do={
                <GuarantorsLister
                  guarantors={guarantors}
                  setGuarantorIndexToEdit={setGuarantorIndexToEdit}
                  guarantorCardEditorRef={guarantorCardEditorRef}
                  readonly={readonly}
                />
              }
            />
            <div className="mt2 mb4">
              <If
                condition={shownAddNotes}
                do={
                  <TextArea name="notes" labelId="Guarantor.notes.label" placeholderId="Guarantor.notes.placeholder" />
                }
                else={
                  <TemporaryHider>
                    <LinkButton as="button" onClick={() => setShownNotes(true)}>
                      <Text id="Guarantor.addNotes" />
                    </LinkButton>
                  </TemporaryHider>
                }
              />
            </div>
          </Expander.Body>
        </>
      )}
    </Expander.Root>
  );
}

export default {
  Component: Guarantors,
  validate
};
