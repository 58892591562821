import Saving from '@kwara/models/src/models/Saving';

import { useAuth } from '../../hooks';
import { useFilterContext } from '../../components/Filter';
import { FILTER_TYPES } from '../../components/Filter/utils/filterTypes';
import { useOverviewList, fetchWithPagination, PaginationQueryType } from '../../models/hooks/base';

const legacyIncludes = ['member', 'product'];
const v1Includes = [];

function fetchSavings(options: PaginationQueryType) {
  return fetchWithPagination(Saving, options);
}

function useFilter() {
  const { getValueFor } = useFilterContext();
  const auth = useAuth();

  const isV1 = auth.isV1();
  const state = getValueFor(FILTER_TYPES.STATUS);

  if (!isV1) return { where: { state } };

  const where = {
    state,
    savings_product_id: getValueFor(FILTER_TYPES.SAVINGS_PRODUCT_ID)
  };

  return { where };
}

export function usePaginatedSavings() {
  const auth = useAuth();

  const isV1 = auth.isV1();

  return useOverviewList({
    queryKey: 'savings',
    useWhere: useFilter,
    fetchFn: fetchSavings,
    includes: isV1 ? v1Includes : legacyIncludes,
    select: null
  });
}
