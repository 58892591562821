import * as React from 'react';
import last from 'lodash/fp/last';
import { Field as FinalFormField } from 'react-final-form';

import { LoanSubstepProps } from 'GlobalTypes';

import { If } from '@kwara/components/src/If/If';
import { LoanType } from '@kwara/models/src/models/Loan';
import { Text, Currency } from '@kwara/components/src/Intl';
import { LoanFormData } from '@kwara/components/src/pages/LoanAdd';
import { ErrorTextWithI18n } from '@kwara/components/src/ErrorText';
import { totalBalance } from '@kwara/models/src/models/LoanApplication';

import AmountList from './AmountList';

import { LoanAddData } from '../..';
import { useAuth } from '../../../../../hooks';
import { usePayoffValues } from './usePayoffValues';
import { LoanCard } from '../../../../../components/LoanCard';
import { Notes } from '../../../../../components/Notes/Notes';
import { HorizontalScroller } from '../../../../../components/HorizontalScroller';
import { AnticipatedPayoutBreakdown } from '../../../components/AnticipatedPayoutBreakdown';

import styles from './index.module.scss';

export function getLoansFromLoanIds(loanIds: string[], allLoans: LoanType[]) {
  return allLoans.filter(loan => loanIds.includes(loan.id));
}

type FormPropsType = {
  values: LoanFormData;
  errors: { [key: string]: any };
  [key: string]: any;
};

function PayOff({ data, formProps, onChange }: LoanSubstepProps) {
  const auth = useAuth();

  const isV1 = auth.isV1();
  const { errors }: FormPropsType = formProps;
  const { loans = [], member, amount, product, loan = {}, feeApplications } = data;
  const error = last(errors.payOffLoans);
  const approvedLoans = loans.filter(loan => loan.isApproved());
  const { payOffLoans } = formProps.values;

  const {
    anticipatedPayout,
    principalAmount,
    mambuFees,
    additionalFees,
    loanPayoffAmount,
    totalAmountForPayOff,
    isFetching
  } = usePayoffValues({
    loans,
    member,
    amount,
    feeApplications,
    onChange,
    payOffLoans,
    product,
    isV1
  });

  return (
    <>
      <div className="h2">
        <If
          condition={error != undefined}
          do={
            <ErrorTextWithI18n
              id="LoanAdd.Loans.totalBalance.error.rangeOverflow"
              values={{ val: <Currency format="currency" value={amount} /> }}
            />
          }
        />
      </div>

      <ul className={styles.amountListbox}>
        <AmountList.Root>
          <AmountList.Heading title="requestedLoanAmount" />
          <AmountList.Body amount={amount} />
        </AmountList.Root>

        {isV1 ? null : (
          <AmountList.Root>
            <AmountList.Heading title="eligibleLoanAmount" />
            <AmountList.Body amount={member.eligibleAmount} />
          </AmountList.Root>
        )}

        <AmountList.Root>
          <AmountList.Heading title="totalAmountForPayOff" />
          <AmountList.Body amount={totalAmountForPayOff} />
        </AmountList.Root>

        {isV1 ? null : (
          <AmountList.Root>
            <div className="flex align-center">
              <AmountList.Heading title="anticipatedPayout" />
              &nbsp;
              <AnticipatedPayoutBreakdown
                principalAmount={principalAmount}
                mambuFees={mambuFees}
                additionalFees={additionalFees}
                loanPayoffAmount={loanPayoffAmount}
                anticipatedPayout={anticipatedPayout}
              />
            </div>
            <AmountList.Body amount={anticipatedPayout} isFetching={isFetching} />
          </AmountList.Root>
        )}
      </ul>

      <div className={styles.allLoansWrapper}>
        <h1 className="kw-text-large">
          <Text id="LoanAdd.PayOff.allLoans" />
        </h1>
        <HorizontalScroller<LoanType> data={approvedLoans} fallbackId="LoanAdd.LoanPortfolio.Loans.noLoans">
          {({ startIndex, endingIndex }) => {
            const currentLoans = approvedLoans.slice(startIndex, endingIndex);

            return (
              <ul className={styles.loansListbox}>
                {currentLoans.map(currentLoan => {
                  const id = currentLoan.id;

                  return (
                    <li key={id}>
                      <FinalFormField
                        name="payOffLoans"
                        type="checkbox"
                        value={id}
                        render={({ input }) => {
                          return (
                            <>
                              <label htmlFor={id} className={'db'}>
                                <LoanCard
                                  className={!!input.checked ? styles.activeLoan : ''}
                                  loan={currentLoan}
                                  amount={currentLoan.totalBalance}
                                  showState
                                />
                              </label>
                              <input
                                {...input}
                                hidden
                                id={id}
                                type="checkbox"
                                disabled={id === loan.id}
                                onChange={event => input.onChange(event)}
                              />
                            </>
                          );
                        }}
                      />
                    </li>
                  );
                })}
              </ul>
            );
          }}
        </HorizontalScroller>
      </div>

      <Notes name="notes.pay_off.no_section" className={styles.addNotes} formValues={formProps.values} />
    </>
  );
}

export default {
  Component: PayOff,
  validate: {
    payOffLoans: {
      custom: (loanIds: string[], allData: LoanAddData) => {
        const { amount, loans } = allData;
        const payOffLoans = getLoansFromLoanIds(loanIds, loans);

        if (Number(totalBalance(payOffLoans)) >= Number(amount)) {
          return 'Error';
        }

        return null;
      }
    }
  }
};
