import React, { useState, useMemo } from 'react';

import { useConfig } from './useConfig';
import { useAuth } from '../../../../hooks';
import { Tabs, TabTypes, SetActiveTab } from './Tabs';
import { BodyContentWrapper } from '../../../../layouts';
import { HeaderSubHeader } from '../../../../components/HeaderSubHeader';
import { EntriesOverviewListV2Root } from './EntriesOverviewListV2Root';
import { PendingOverviewListV2Root } from './PendingOverviewListV2Root';

type Props = {
  activeTab: TabTypes;
  setActiveTab: SetActiveTab;
};

const InternalJournalEntryModule = function InternalJournalEntryModule({ activeTab, setActiveTab }: Props) {
  const config = useConfig();

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Table>
          <HeaderSubHeader titleId="Finance.JournalEntries.title" subtitleId="Finance.JournalEntries.subtitle" />
          <Tabs config={config} activeTab={activeTab} setActiveTab={setActiveTab} />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
};

export function JournalEntryModule() {
  const [activeTab, setActiveTab] = useState<TabTypes>('entries');
  const auth = useAuth();

  const isV1 = auth.isV1();

  const Roots = useMemo(() => {
    return isV1
      ? { entries: EntriesOverviewListV2Root }
      : {
          entries: EntriesOverviewListV2Root,
          pending: PendingOverviewListV2Root
        };
  }, [isV1]);

  const Root = Roots[activeTab];

  return (
    <Root>
      <InternalJournalEntryModule activeTab={activeTab} setActiveTab={setActiveTab} />
    </Root>
  );
}
