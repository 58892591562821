import * as React from 'react';
import get from 'lodash/fp/get';

import { If } from '@kwara/components/src/If/If';
import { LoanType } from '@kwara/models/src';
import { LoadingSkeleton } from '@kwara/components/src/Loadable';
import { Text, Date, Duration } from '@kwara/components/src/Intl';

import { currency } from './LoanDetails';
import { useAuth } from '../../../../../hooks';
import { useAnticipatedPayoutApiFetch, useFeeValues } from '../../../hooks';
import { getTwoThirdsRuleValues } from '../../../utils/getTwoThirdsRuleValues';
import { AnticipatedPayoutBreakdown } from '../../../components/AnticipatedPayoutBreakdown';

type GetAttributeRowsArgTypes = {
  loan: LoanType;
  anticipatedPayout: number | string;
  principalAmount: number | string;
  mambuFees: number | string;
  additionalFees: number | string;
  loanPayoffAmount: number | string;
  isFetchingAnticipatedPayout: boolean;
};
function getAttributeRows(arg: GetAttributeRowsArgTypes) {
  const {
    loan,
    anticipatedPayout,
    principalAmount,
    mambuFees,
    additionalFees,
    loanPayoffAmount,
    isFetchingAnticipatedPayout
  } = arg;
  const { remittance, firstRepaymentDate, duration, loanApplication = {} } = loan;
  const {
    netIncome,
    totalMonthlyLoanRepayment,
    totalPayoffAmount,
    otherDeductibles,
    otherIncomeAmount
  } = loanApplication;
  const repaymentMethod = get('method', remittance);
  const repaymentAmount = get('amount', remittance);

  const args = {
    totalMonthlyLoanPayments: totalMonthlyLoanRepayment,
    netIncome,
    otherIncomeAmount,
    otherDeductibles,
    repaymentAmount
  };
  const { passesTwoThirdsRule } = getTwoThirdsRuleValues(args);

  const repaymentAttributesRow1 = [
    {
      title: <Text id="LoanDisbursal.LoanDetails.NavigationTabs.repayment.attributesRow1.income" />,
      value: currency(netIncome)
    },
    {
      title: <Text id="LoanDisbursal.LoanDetails.NavigationTabs.repayment.attributesRow1.repaymentAmount" />,
      value: currency(repaymentAmount)
    },
    {
      title: <Text id="LoanDisbursal.LoanDetails.NavigationTabs.repayment.attributesRow1.twoThirdsRule" />,
      value: (
        <Text
          id={`LoanDisbursal.LoanDetails.NavigationTabs.repayment.attributesRow1.twoThirdsRule.${passesTwoThirdsRule}`}
        />
      )
    }
  ];
  const repaymentAttributesRow2 = [
    {
      title: <Text id="LoanDisbursal.LoanDetails.NavigationTabs.repayment.attributesRow2.otherLoanRepayments" />,
      value: currency(totalMonthlyLoanRepayment)
    },
    {
      title: <Text id="LoanDisbursal.LoanDetails.NavigationTabs.repayment.attributesRow2.loanPayoffs" />,
      value: currency(totalPayoffAmount)
    },
    {
      title: (
        <>
          <Text id="LoanDisbursal.LoanDetails.NavigationTabs.repayment.attributesRow2.anticipatedPayout" />
          &nbsp;
          <AnticipatedPayoutBreakdown
            principalAmount={principalAmount}
            mambuFees={mambuFees}
            additionalFees={additionalFees}
            loanPayoffAmount={loanPayoffAmount}
            anticipatedPayout={anticipatedPayout}
          />
        </>
      ),
      value: <If condition={isFetchingAnticipatedPayout} do={<LoadingSkeleton />} else={currency(anticipatedPayout)} />
    }
  ];
  const repaymentAttributesRow3 = [
    {
      title: <Text id="LoanDisbursal.LoanDetails.NavigationTabs.repayment.attributesRow3.firstRepaymentDate" />,
      value: <Date value={firstRepaymentDate} />
    },
    {
      title: <Text id="LoanDisbursal.LoanDetails.NavigationTabs.repayment.attributesRow3.repaymentPeriod" />,
      value: <Duration value={duration} />
    },
    {
      title: <Text id="LoanDisbursal.LoanDetails.NavigationTabs.repayment.attributesRow3.repaymentMethod" />,
      value: <Text id={`TransactionMethod.${repaymentMethod}`} />
    }
  ];

  return { repaymentAttributesRow1, repaymentAttributesRow2, repaymentAttributesRow3 };
}

export function useRepaymentTabData(loan) {
  const { loanApplication = {} } = loan;
  const { member, amount, product } = loan;
  const { payOffLoans = [], loanApplicationFees = [] } = loanApplication;

  const auth = useAuth();
  const { totalAdditionalFees } = useFeeValues({ product, feeApplications: loanApplicationFees, amount });
  const args = {
    member,
    payOffLoans: payOffLoans.map((loan: LoanType) => loan.id),
    amount,
    product,
    totalAdditionalFees,
    isV1: auth.isV1()
  };
  const {
    anticipatedPayout,
    principalAmount,
    mambuFees,
    loanPayoffAmount,
    additionalFees,
    isFetching: isFetchingAnticipatedPayout
  } = useAnticipatedPayoutApiFetch(args);

  const { repaymentAttributesRow1, repaymentAttributesRow2, repaymentAttributesRow3 } = React.useMemo(
    () =>
      getAttributeRows({
        loan,
        anticipatedPayout,
        principalAmount,
        mambuFees,
        additionalFees,
        loanPayoffAmount,
        isFetchingAnticipatedPayout
      }),
    [additionalFees, anticipatedPayout, isFetchingAnticipatedPayout, loan, loanPayoffAmount, mambuFees, principalAmount]
  );

  return { repaymentAttributesRow1, repaymentAttributesRow2, repaymentAttributesRow3 };
}
