import { useRef } from 'react';

import { Event, IncludesT } from '@kwara/models/src';

import { useAuth } from '../../../../hooks';
import { useFilterContext } from '../../../../components/Filter';
import { FILTER_TYPES } from '../../../../components/Filter/utils/filterTypes';
import { SuggestDates } from '../../../../components/Filter/utils/suggestDates';
import { DATE_NAMES } from '../../../../components/Filter/pages/DateBaseFilter/useDateBaseFilter';
import { useOverviewList, fetchWithPagination, PaginationQueryType } from '../../../../models/hooks/base';

function useFilter() {
  const { getValueFor } = useFilterContext();

  const fromValue = getValueFor(FILTER_TYPES.ACTIVITY_FEED_DATE, DATE_NAMES.FROM);
  const where = {
    user_id: getValueFor(FILTER_TYPES.ACTIVITY_FEED_USER),
    branch_id: getValueFor(FILTER_TYPES.BRANCH_ID),
    name: getValueFor(FILTER_TYPES.ACTIVITY_FEED_EVENT),
    time: {
      from: fromValue === SuggestDates.DATES.ALL_TIME.from ? '' : fromValue,
      to: getValueFor(FILTER_TYPES.ACTIVITY_FEED_DATE, DATE_NAMES.TO)
    },
    properties: {
      member_id: getValueFor(FILTER_TYPES.MEMBER_ID),
      account_id: getValueFor(FILTER_TYPES.ACCOUNT_ID)
    }
  };

  return { where };
}

export function usePaginatedEvents(includes: IncludesT = ['user']) {
  const { current: event } = useRef<Event>(Event);
  const auth = useAuth();

  if (auth.isV1() && !event.apiNamespace.includes('audit')) {
    event.apiNamespace = `${Event.apiNamespace}/audit`;
  }

  return useOverviewList({
    queryKey: 'events',
    includes,
    select: null,
    useWhere: useFilter,
    fetchFn: (opts: PaginationQueryType) => fetchWithPagination(Event, opts)
  });
}
