import React from 'react';

import { usePaymentMethods } from '../PaymentForm/usePaymentMethods';
import { PaymentMethodSelector } from '../PaymentForm/components/PaymentMethodSelector';

export function V1PaymentMethod() {
  const { data, isLoading } = usePaymentMethods();

  return <PaymentMethodSelector name="paymentMethodId" methods={data} disabled={isLoading} />;
}
