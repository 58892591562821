import { CheckableItem } from '@kwara/models/src';
import { CheckableItemStates } from '@kwara/models/src/models/CheckableItem';

import { useAuth } from '../../../../hooks';
import { useFilterContext } from '../../../../components/Filter';
import { FILTER_TYPES } from '../../../../components/Filter/utils/filterTypes';
import { useOverviewList, fetchWithPagination, PaginationQueryType } from '../../../../models/hooks/base';

const GET_CHECKABLE_JOURNAL_ENTRY_QUERY_KEY = 'CheckableJournalEntry';
const includes = ['created_by', 'reviewed_by'];

function fetchCheckableJournalEntry(opts: PaginationQueryType) {
  return fetchWithPagination(CheckableItem.journalEntries, opts);
}

function useFilter() {
  const { getValueFor } = useFilterContext();

  const status = getValueFor(FILTER_TYPES.STATUS) || CheckableItemStates.pending;
  const filter = {
    filterValue: status as string,
    where: {
      state: status,
      branch_id: getValueFor(FILTER_TYPES.PENDING_JOURNAL_BRANCH_ID)
    }
  };

  return filter;
}

function usePaginatedCheckableJournalEntry() {
  const auth = useAuth();

  const isV1 = auth.isV1();

  return useOverviewList({
    queryKey: GET_CHECKABLE_JOURNAL_ENTRY_QUERY_KEY,
    fetchFn: fetchCheckableJournalEntry,
    includes,
    useWhere: useFilter,
    enabled: !isV1
  });
}

export { GET_CHECKABLE_JOURNAL_ENTRY_QUERY_KEY, usePaginatedCheckableJournalEntry };
