import React from 'react';

import { If } from '@kwara/components/src/If/If';
import { RequestProps } from '@kwara/components/src';
import { SavingsTransactionType, SavingType, LoanType, LoanTransactionType } from '@kwara/models/src';

import { TransactionModelType } from './useSubmit';
import { TransactionAdjustModal } from './TransactionAdjustModal';
import { useTransactionAdjustment } from '../../TransactionAdjustment';
import { TransactionViewModal } from '../../TransactionAdjustment/TransactionViewModal';

type PropTypes = {
  query: RequestProps<SavingType[] | SavingsTransactionType[] | LoanType[] | LoanTransactionType[]>;
  TransactionModel: TransactionModelType;
};

export function AdjustmentModals({ query, TransactionModel }: PropTypes) {
  const {
    permission,
    adjustTransactionsPermission,
    currentTransaction,
    showView,
    showAdjust,
    setShowAdjust,
    setShowView
  } = useTransactionAdjustment();

  return (
    <>
      <If
        condition={showView}
        do={<TransactionViewModal transaction={currentTransaction} onClose={() => setShowView(false)} />}
      />
      <If
        condition={showAdjust && permission.to(adjustTransactionsPermission)}
        do={
          <TransactionAdjustModal
            TransactionModel={TransactionModel}
            transaction={currentTransaction}
            onSuccess={query.refetch}
            onClose={() => setShowAdjust(false)}
          />
        }
      />
    </>
  );
}
