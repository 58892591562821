import React from 'react';

import { HttpErrorObject } from 'GlobalTypes';

import { LoanType } from '@kwara/models/src/models/Loan';

import LoanListTable from '../LoanListTable';
import OverviewListV2 from '../../../../components/OverviewListV2';

import { ExportButton } from '../ExportButton/ExportButton';
import { CreateLoanButtonWithPermissions } from './CreateLoanButtonWithPermissions';

type Props = {
  hasMore: boolean;
  loading: boolean;
  loans: LoanType[];
  errors: HttpErrorObject[] | null;
  totalNumResults: number;
  onLoadMoreData(): void;
};

export function LoanList({ totalNumResults, loading, loans = [], ...rest }: Props) {
  return (
    <OverviewListV2
      withFilter
      items={loans}
      filterDisabled={loading}
      headerId="LoanList.heading"
      labelId="LoanList.filteredCount"
      exportButton={<ExportButton />}
      totalNumResults={totalNumResults}
      table={<LoanListTable {...rest} loading={loading} loans={loans} />}
      actions={[<CreateLoanButtonWithPermissions key="createLoanButton" />]}
    />
  );
}
