import React from 'react';
import map from 'lodash/fp/map';

import { PaymentMethodGlLinkT } from '@kwara/models/src';
import { SubscribedSelectField as SelectField } from '@kwara/components/src/Form';

const Option = SelectField.Option;

export function GlLinkSelector({
  glLinks = [],
  name = 'glLinkId'
}: {
  glLinks?: PaymentMethodGlLinkT[];
  name?: string;
}) {
  return (
    <SelectField disabled={glLinks.length === 0} name={name} size="medium" labelId="PaymentForm.glAccount.labelId">
      <Option key={'empty'} value={''}></Option>
      {map<PaymentMethodGlLinkT, React.ReactElement<typeof Option>>(
        link => (
          <Option key={link.id} value={link.id}>
            {link.name}
          </Option>
        ),
        glLinks
      )}
    </SelectField>
  );
}
