import map from 'lodash/fp/map';
import React from 'react';
import { SavingType } from '@kwara/models/src';
import { SubscribedSelectField as SelectField } from '@kwara/components/src/Form';

const Option = SelectField.Option;

export function AccountSelector({ accounts = [], name = 'accountId' }: { accounts: SavingType[]; name: string }) {
  if (accounts.length === 0) return null;

  return (
    <SelectField name={name} size="medium" labelId="PaymentForm.account">
      {map<SavingType, React.ReactElement<typeof Option>>(
        account => (
          <Option key={account.id} value={account.id}>
            {account.id} &mdash; {account.name || account.product.name}
          </Option>
        ),
        accounts
      )}
    </SelectField>
  );
}
