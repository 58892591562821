import Loan from '@kwara/models/src/models/Loan';

import { useAuth } from '../../hooks';
import { useFilterContext } from '../../components/Filter';
import { FILTER_TYPES } from '../../components/Filter/utils/filterTypes';
import { useOverviewList, fetchWithPagination, PaginationQueryType } from '../../models/hooks/base';

const legacyIncludes = ['member,loan_application.disbursement.appraisals.user'];
const v1Includes = [];

function fetchLoans(options: PaginationQueryType) {
  return fetchWithPagination(Loan, options);
}

function useFilter() {
  const { getValueFor } = useFilterContext();
  const auth = useAuth();

  const isV1 = auth.isV1();
  const state = getValueFor(FILTER_TYPES.STATUS);

  if (!isV1) return { where: { state } };

  const where = {
    state,
    loan_product_id: getValueFor(FILTER_TYPES.LOAN_PRODUCT_ID)
  };

  return { where };
}

export function usePaginatedLoans() {
  const auth = useAuth();

  const isV1 = auth.isV1();

  return useOverviewList({
    queryKey: 'loans',
    useWhere: useFilter,
    fetchFn: fetchLoans,
    includes: isV1 ? v1Includes : legacyIncludes,
    select: null
  });
}
