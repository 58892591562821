import React from 'react';

import { Text } from '@kwara/components/src/Intl';

import { BodyContentWrapper } from '../../../../layouts';

import { Form } from './components/Form';
import { ReportsTable as Table } from './components/Table';
import { useTrialBalancePage } from './useTrialBalancePage';

export function TrialBalance() {
  const { onSubmit, report } = useTrialBalancePage();

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root>
        <div className="kw-text-x-large kw-weight-bold pa3">
          <Text id="AccountingReports.V1.TrialBalance.title" />
        </div>
        <Form onSubmit={onSubmit} />
      </BodyContentWrapper.Row.Root>
      <BodyContentWrapper.Row.Root>
        {report ? (
          <BodyContentWrapper.Row.Table>
            <Table report={report} isLoading={false} />
          </BodyContentWrapper.Row.Table>
        ) : null}
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
}
