import React from 'react';
import get from 'lodash/fp/get';

import StatisticRow from '@kwara/components/src/StatisticRow';

import { Text, Percent } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import { LoanProductType } from '@kwara/models/src/models/LoanProduct';

type StatisticsProps = {
  product: LoanProductType;
};

export function Statistics({ product }: StatisticsProps) {
  return (
    <StatisticRow widthClass="w-third">
      <Statistic size="medium" title={<Text id="LoanSchedulePreview.Preview.productId" />} value={product.id} />
      <Statistic size="medium" title={<Text id="LoanSchedulePreview.Preview.productName" />} value={product.name} />
      <Statistic
        size="medium"
        title={<Text id="LoanSchedulePreview.Preview.interestRate" />}
        value={<Percent value={get('interestRate.percentage', product) / 100} />}
      />
    </StatisticRow>
  );
}
