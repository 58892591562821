import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import pickBy from 'lodash/fp/pickBy';

import { Loadable } from '@kwara/components/src/Loadable';
import { useMember, useMemberSavings } from '@kwara/models/src/models/request/hooks';
import { SavingsTransaction, MemberType, SavingType } from '@kwara/models/src';
import { formatIsoDate, getCurrentDate } from '@kwara/lib/src/dates';

import Wizard from '../../../../components/Wizard';
import { WizardPageProps } from '../../..';
import { memberPath } from '../../../../lib/urls';

import { steps } from './steps';

type Props = WizardPageProps<{ memberId: string }>;

type TransactionFormDataT = {
  accountId: string;
  amount: number;
  method: string;
  notes: string;
  reference: string;
  valueDate: string;
  glLinkId: string;
};

async function makeWithdrawal(data: TransactionFormDataT) {
  const { amount, accountId: savingsId, method, notes, reference, valueDate, glLinkId } = data;

  const transaction = SavingsTransaction.create(
    pickBy(v => !!v, {
      type: 'WITHDRAWAL',
      savingsId,
      amount,
      paymentMethodId: method,
      notes,
      reference,
      valueDate: formatIsoDate(valueDate),
      glLinkId
    })
  );

  const didSave = await transaction.save();

  if (!didSave) {
    throw transaction.errors;
  }
}

export const MemberWithdrawal = (props: Props) => {
  const queryClient = useQueryClient();
  const memberId = props.match.params.memberId;
  const r = useMember(memberId);
  const savingsR = useMemberSavings(memberId);

  return (
    <Loadable {...r}>
      {(member: MemberType) => (
        <Loadable {...savingsR}>
          {(savings: SavingType[]) => {
            member.savings = savings;

            return member.savingsEligibleForTransactions().length > 0 ? (
              <Wizard
                analyticsId="MemberWithdrawal"
                baseUrl={memberPath({ id: member.id })}
                history={history}
                initialData={{
                  member,
                  accounts: member.savingsEligibleForTransactions(),
                  accountId: member.savingsEligibleForTransactions()[0].id,
                  valueDate: getCurrentDate()
                }}
                cancelReturnsTo={memberPath({ id: member.id })}
                currentStep="withdrawal"
                onSubmit={makeWithdrawal}
                onSubmitCompletion={() => {
                  r.refetch();
                  savingsR.refetch();
                  queryClient.invalidateQueries('member_details_metrics');
                }}
                steps={steps}
                startId="withdrawal"
                titleId="SavingPayment.title"
              />
            ) : (
              <Redirect to={memberPath({ id: member.id })} />
            );
          }}
        </Loadable>
      )}
    </Loadable>
  );
};
