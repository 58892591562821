import * as React from 'react';
import reject from 'lodash/fp/reject';
import invoke from 'lodash/fp/invoke';
import flatten from 'lodash/fp/flatten';

import { isEmpty } from '@kwara/lib/src/lodash';
import { LoanTransactionType, MemberType, LoanType, GuaranteeType, LoanTransaction } from '@kwara/models/src';
import { Date, Text, Time } from '@kwara/components/src/Intl';
import Button from '@kwara/components/src/Button';
import { DownloadButton } from '@kwara/components/src/DownloadButton';
import { Loadable, RequestProps } from '@kwara/components/src/Loadable';
import { useMemberGuarantees } from '@kwara/models/src/models/request/hooks';
import { Checkbox } from '@kwara/components/src/Form';
import { If } from '@kwara/components/src/If/If';

import { AppPermissions } from '../../../models/Permission';
import { loanPath } from '../../../lib/urls';
import { Cell, Heading, Row } from '../../../components/Table';
import TransactionsTable from '../../../components/TransactionsTable';
import { Currency } from '../../../components/Currency';
import LoanAccountCard from './LoanAccountCard';
import { LoanGuaranteedCard } from './LoanGuaranteedCard';
import { AccountList } from './AccountList';
import DetailSubsection from '../../../components/DetailSubsection';
import TransactionAdjustment from '../../../components/TransactionAdjustment';
import { shouldHideRow } from '../../../components/TransactionAdjustment/utils';
import { AdjustmentButtonsPropTypes } from '../../../components/TransactionAdjustment/AdjustmentButtons';
import { useAuth } from '../../../hooks';

import styles from './Loans.module.css';

const renderRow = (
  transaction: LoanTransactionType,
  showAdjustedTransactions: boolean,
  AdjustmentButtons: React.FunctionComponent<AdjustmentButtonsPropTypes>
) => {
  const hideRow = shouldHideRow(showAdjustedTransactions, transaction);
  return (
    <If
      key={transaction.id}
      condition={!hideRow}
      do={
        <Row className={transaction.isAdjustment ? 'grey-300' : ''}>
          <Cell>
            <div className="grey-300">{transaction.id}</div>
            <div>
              <Date value={transaction.createdAt} />
            </div>
            <div>
              <Time value={transaction.createdAt} />
            </div>
          </Cell>
          <Cell>
            <div className="grey-300">{transaction.account.id}</div>
            <div>{transaction.account.product.name}</div>
          </Cell>
          <Cell>
            <div className="grey-300">{transaction.reference}</div>
            <span>{transaction.paymentMethod}</span> <Text id={`TransactionType.${transaction.type}`} />
            {transaction.notes && <div className="grey-300">{transaction.notes}</div>}
          </Cell>
          <Cell align="right">
            <Currency value={transaction.amount} />
          </Cell>
          <Cell align="right">
            <Currency value={transaction.balance} />
          </Cell>
          <Cell align="right">
            <AdjustmentButtons transaction={transaction} />
          </Cell>
          <Cell />
        </Row>
      }
    />
  );
};

export const Loans = ({
  member,
  showClosedLoans,
  loanR
}: {
  member: MemberType;
  showClosedLoans: boolean;
  loanR: RequestProps<LoanType[]>;
}) => {
  const [showAdjustedTransactions, setShowAdjustedTransactions] = React.useState(false);
  const auth = useAuth();
  const guaranteesR = useMemberGuarantees({ memberId: member.id });

  const hideDownloadPdfBtn = isEmpty(guaranteesR.data) || auth.isV1();

  const heading = (
    <Row>
      <Heading translationId="TransactionsTable.date" />
      <Heading className={styles.AccountColumn} translationId="TransactionsTable.loan" />
      <Heading className="w-25" translationId="TransactionsTable.type" />
      <Heading align="right" translationId="TransactionsTable.amount" />
      <Heading align="right" translationId="TransactionsTable.balance" />
      <Heading width="175px" align="right" translationId="TransactionsTable.actions" />
      <Heading iconSpacer />
    </Row>
  );

  return (
    <>
      <DetailSubsection ariaLabel={'All Loans'} title={<Text id="MemberDetail.allLoans" />}>
        <Loadable {...loanR}>
          {(loans: LoanType[]) => {
            const accounts = showClosedLoans ? loans : reject(loan => loan.isClosed(), loans);

            return (
              <AccountList
                accounts={accounts}
                columns={3}
                width="w-33"
                actionButton={
                  <Button
                    className="mt2"
                    type="primary"
                    to={loanPath({ action: 'create/loanPortfolio/1', memberId: member.id }, { asObject: true })}
                  >
                    <Text id="MemberDetail.accounts.Loans.Button.add" />
                  </Button>
                }
                renderAccount={(loan: LoanType) => (
                  <LoanAccountCard key={loan.id} account={loan} actionTo={loanPath({ id: loan.id })} />
                )}
              />
            );
          }}
        </Loadable>
      </DetailSubsection>
      <DetailSubsection
        ariaLabel={'Loans Guaranteed'}
        headerRight={
          hideDownloadPdfBtn ? null : <DownloadButton instance={member} downloadFn={member.downloadGuarantorPDF} />
        }
        title={<Text id="MemberDetail.loansGuaranteed" />}
      >
        <Loadable {...guaranteesR}>
          {(allGuarantees: GuaranteeType[]) => {
            const guarantees = showClosedLoans
              ? allGuarantees
              : reject(guaranty => invoke('loan.isClosed', guaranty) || !guaranty.loan, allGuarantees);

            return (
              <AccountList
                accounts={guarantees}
                columns={3}
                width="w-33"
                renderAccount={(guaranty: GuaranteeType) => (
                  <LoanGuaranteedCard
                    key={guaranty.id}
                    guaranty={guaranty}
                    actionTo={guaranty.loan ? loanPath({ id: guaranty.loan.id }) : null}
                  />
                )}
              />
            );
          }}
        </Loadable>
      </DetailSubsection>
      <DetailSubsection title={<Text id="MemberDetail.payments" />}>
        <Loadable {...loanR}>
          {(loans: LoanType[]) => {
            const accounts: LoanType[] = showClosedLoans ? loans : reject(loan => loan.isClosed(), loans);

            const transactions = flatten(
              accounts.map((loan: LoanType) =>
                loan.transactions.map((transaction: LoanTransactionType) => {
                  transaction.account = loan;
                  return transaction;
                })
              )
            );

            return (
              <TransactionAdjustment.Root adjustTransactionsPermission={AppPermissions.AdjustLoanTransactions}>
                <TransactionAdjustment.AdjustmentModals query={loanR} spraypaintModel={LoanTransaction} />
                <Checkbox
                  name="showAdjustedLoanTransactions"
                  labelId="MemberDetail.Transactions.Subsection.Checkbox.showAdjusted"
                  onChange={() => setShowAdjustedTransactions(prev => !prev)}
                />
                <TransactionsTable
                  heading={heading}
                  renderRow={(transaction: LoanTransactionType) =>
                    renderRow(transaction, showAdjustedTransactions, TransactionAdjustment.AdjustmentButtons)
                  }
                  transactions={transactions}
                  whitelist={['REPAYMENT']}
                  maximumEntries={100}
                />
              </TransactionAdjustment.Root>
            );
          }}
        </Loadable>
      </DetailSubsection>
    </>
  );
};
