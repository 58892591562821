import { attr } from 'spraypaint';

import { InferredModel } from 'GlobalTypes';

import Base from '../../Base';

export const AccountTransfer = Base.extend({
  static: {
    jsonapiType: 'transfers',
    endpoint: '/account_transfers'
  },
  attrs: {
    amount: attr(),
    sourceAccountId: attr(),
    sourceAccountType: attr(),
    destinationAccountId: attr(),
    destinationAccountType: attr(),
    valueDate: attr(),
    notes: attr()
  }
});

export interface AccountTransferT extends Omit<InferredModel<typeof AccountTransfer>, 'errors'> {
  amount: string;
  sourceAccountId: string;
  sourceAccountType: string;
  destinationAccountId: string;
  destinationAccountType: string;
  valueDate?: string;
  notes?: string;
}
