import get from 'lodash/fp/get';

import { PhoneNumberInfoType } from '@kwara/models/src/models/PhoneNumberInfo';
import { LoanDisbursementType } from '@kwara/models/src/models/LoanDisbursement';
import { useGetPhoneNumberInfo } from '@kwara/models/src/models/PhoneNumberInfo/useGetPhoneNumberInfo';

import { useAuth } from '../../../../../hooks';
import { LoanDisbursalPropTypes } from '../..';
import { useDisbursersActiveStates } from './useDisbursersActiveStates';

const ZERO_AMOUNT = 0;

interface ArgType extends LoanDisbursalPropTypes {
  disbursement: LoanDisbursementType;
}
export function useBaseLogic(arg: ArgType) {
  const {
    disbursement,
    data: { loan, member },
    formProps: { values },
    onChange,
    addData,
    disableNextButton,
    enableNextButton
  } = arg;

  const auth = useAuth();

  const isV1 = auth.isV1();

  useGetPhoneNumberInfo(
    member.phoneNumber,
    {
      onStart: disableNextButton,
      onError: _ => enableNextButton(),
      async onSuccess({ carrier }: PhoneNumberInfoType) {
        await addData({ phoneNumberProvider: carrier });
        await onChange({ phoneNumberProvider: carrier });
        enableNextButton();
      }
    },
    !isV1
  );

  const { disburserTwoAmount, disburserOneAmount, phoneNumberProvider } = values;
  const [firstDisburser, secondDisburser] = get('appraisals', disbursement) || [];
  const disbursementAmount =
    Number(loan.hasDisbursementRecord() ? disburserTwoAmount : disburserOneAmount) || ZERO_AMOUNT;

  const { isFirstDisburserActive, isSecondDisburserActive, isSecondDisburserRequired } = useDisbursersActiveStates(
    firstDisburser,
    secondDisburser
  );

  return {
    firstDisburser,
    secondDisburser,
    isFirstDisburserActive,
    disbursementAmount,
    phoneNumberProvider,
    isSecondDisburserActive,
    isSecondDisburserRequired
  };
}
