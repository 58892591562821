import get from 'lodash/fp/get';
import { CollateralsT, LoanAttachmentT } from '@kwara/models/src';
import { FileNameT } from '@kwara/components/src/UploadWidgetUI';

import { convert, addAttachment, removeAttachment, isDirty } from './utils';

export function useCollateralFileInputLoanApplication({
  onChange,
  collaterals
}: {
  onChange: (data: { [k: string]: any; attachments: LoanAttachmentT[] }) => Promise<void>;
  collaterals: CollateralsT[];
}) {
  const fileName = 'proofOfAsset' as FileNameT;

  const hasFile = (index: number) => {
    const collateral = get(index, collaterals);
    return isDirty(collateral?.attachments, 'proofOfAsset');
  };

  async function onFileRemove(_e, index: number) {
    const collateral = get(index, collaterals);
    await onChange(removeAttachment(collateral, fileName));
  }

  async function onFileInputChange(e, index) {
    const collateral = get(index, collaterals);

    if (hasFile(index)) {
      await onFileRemove(e, index);
    }
    await convert(e, fileName).then(v => onChange(addAttachment(collateral, v)));
  }

  return { onFileRemove, onFileInputChange, fileName, hasFile };
}
