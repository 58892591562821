import last from 'lodash/fp/last';
import pick from 'lodash/fp/pick';
import compact from 'lodash/fp/compact';

import { useState, useCallback } from 'react';

import { LoanType } from '@kwara/models/src/models/Loan';
import { MemberType } from '@kwara/models/src/models/Member';
import { saveAttachments } from '@kwara/models/src/models/Attachment';
import { useSearchParams } from '@kwara/lib/src/hooks/useSearchParams';
import { removeNullishValues } from '@kwara/lib/src/utils/removeNullishValues';
import { setRemittanceDetails, setDisbursementBankDetails } from '@kwara/lib/src/remittance';
import { V1, Loan, LoanApplication, Guarantor, GuaranteeType, snakeCaseObjectKeys } from '@kwara/models/src';

import { LoanAddData } from '.';
import { useMember } from './useMember';
import { Store } from '../../../models/Store/Store';
import { useAuth } from '../../../hooks/useAuth/useAuth';
import { getSelectedFeeApplications } from '../hooks/useFeeValues';
import { useDisbursementModeAdapter } from './useDisbursementModeAdapter';

type Params = {
  memberId: string;
};

type NotesArg = {
  member_profile?: {
    income?: string;
  };
  pay_off?: {
    no_section?: string;
  };
  loan_product?: {
    no_section?: string;
  };
};

/**
 * @prepareNotes processes notes data for a loan application by extracting relevant
 * information and filtering out empty values.
 * @param {NotesArg} notes - The `notes` parameter is an object with keys representing
 * @returns The `prepareNotes` function takes an object `notes` as an argument with a default value of
 * an empty object. It then extracts the keys of the `notes` object, maps over them to create a new
 * array of objects with properties `flow`, `step`, `section`, and `value`. It filters out any objects
 * where the `value` property is falsy. The final result is then returned.
 */
function prepareNotes(notes: NotesArg = {}) {
  return Object.keys(notes)
    .map(step => {
      const section = Object.keys(notes[step])[0];
      const value = notes[step][section];

      return { flow: 'loan_application', step, section, value };
    })
    .filter(obj => !!obj.value);
}

const ZERO_AMOUNT = 0;

/**
 * @setGuarantors adds a self-guarantee amount to an array of guarantors if the amount is
 * greater than zero.
 * @param guarantors - The `guarantors` parameter is an array of `GuaranteeType` objects that represent
 * individuals or entities providing guarantees for a financial transaction.
 * @param {number} selfGuaranteeAmount - The `selfGuaranteeAmount` parameter represents the amount that
 * a member is guaranteeing for themselves. This amount is used to create a new `Guarantor` object with
 * the specified `amount` and `memberId`. If the `selfGuaranteeAmount` is greater than `ZERO
 * @param {string} memberId - The `memberId` parameter is a string that represents the unique
 * identifier of a member who is providing a guarantee.
 * @returns An array of Guarantor objects with a new Guarantor object added if the selfGuaranteeAmount
 * is greater than ZERO_AMOUNT, otherwise the original guarantors array is returned.
 */
function setGuarantors(guarantors: Array<GuaranteeType> = [], selfGuaranteeAmount: number, memberId: string) {
  if (Number(selfGuaranteeAmount) > ZERO_AMOUNT) {
    return [...guarantors, new Guarantor({ amount: selfGuaranteeAmount, memberId })];
  }

  return guarantors;
}

function setCharges(chargesData: { chargeId: string; isMarkedForDestruction: boolean; amount: string }[] = []) {
  const result = chargesData
    .filter(charge => !charge.isMarkedForDestruction)
    .map(charge => V1.LoanProducts.Charge.create({ chargeId: charge.chargeId, amount: charge.amount }));
  return result;
}

/**
 * @saveMember asynchronously saves a member along with their addresses and next of kins.
 * @param {MemberType} member - The `member` parameter in the `saveMember` function is of type
 * `MemberType`, which contains information about a member such as their personal details,
 * addresses, and next of kin.
 */
async function saveMember(member: MemberType) {
  const memberSaveSuccess = await member.save({ with: ['addresses', 'nextOfKins', 'idDocuments'] });

  if (!memberSaveSuccess) throw member.errors;
}

/**
 * @saveLoan asynchronously saves a loan object with specified related entities and
 * throws errors if unsuccessful.
 * @param {LoanType} loan - LoanType
 */
async function saveLoan(loan: LoanType, isV1: boolean) {
  const withIt = [
    'guarantors',
    'loanApplication',
    { collaterals: ['attachments'] },
    isV1 ? null : { remittance: ['bankAccount', 'collectingBank.id'] },
    isV1 ? 'charges' : null
  ].filter(Boolean);

  const success = await loan.save({
    with: withIt
  });

  if (!success) throw loan.errors;
}

/**
 * @uploadAttachments asynchronously saves attachments for a member and throws errors if
 * the attachments are not successfully saved.
 * @param {MemberType} member - The `member` parameter is of type `MemberType`, which represents
 * a member object with information and attachments that need to be saved/uploaded.
 */
async function uploadAttachments(member: MemberType) {
  const uploaded = await saveAttachments(member, member.attachments);

  if (!uploaded) throw member.errors;
}

function useLoanAdd() {
  const auth = useAuth();
  const [createdLoan, setCreatedLoan] = useState(null);
  const disbursementModes = useDisbursementModeAdapter();
  const searchParams = useSearchParams<Params>();
  const memberIdParam = searchParams.get('memberId');
  const r = useMember({ id: memberIdParam, opts: { enabled: !!memberIdParam?.length } });

  const isV1 = auth.isV1();

  const createLoan = useCallback(
    async (data: LoanAddData, store: Store) => {
      const {
        member,
        remittance,
        bankAccounts,
        product,
        amount,
        payOffLoans,
        loanDuration,
        repaymentPeriod,
        anticipatedDisbursementDate
      } = data;
      const loanApplication = new LoanApplication(
        removeNullishValues({
          employmentStatus: member.employmentStatus,
          grossIncome: member.grossIncome,
          netIncome: member.netIncome,
          otherDeductibles: member.otherDeductibles,
          otherIncomeAmount: member.otherIncomeAmount,
          disposableIncomeAmount: member.disposableIncomeAmount,
          incomeSource: member.incomeSource,
          termsOfService: member.termsOfService
        })
      );
      setRemittanceDetails({ remittance, data, bankAccounts, store });
      const loan = new Loan({
        amount,
        payOffLoans,
        remittance,
        loanDuration,
        repaymentPeriod,
        repaymentPeriodUnit: data.repaymentPeriodUnit,
        loanApplication,
        productId: product.id,
        accountHolderId: member.id,
        applicationNotes: prepareNotes(data.notes),
        disbursementMode: disbursementModes[data.disbursementMode],
        loanClassificationId: last(compact(data.classifications)),
        disbursementBankDetails: setDisbursementBankDetails(data, store),
        guarantors: setGuarantors(data.guarantors, data.selfGuaranteeAmount, member.id),
        charges: setCharges(data.charges),
        feeApplications: getSelectedFeeApplications({ fees: product.fees, feeApplications: data.feeApplications }).map(
          snakeCaseObjectKeys
        ),
        ...removeNullishValues({ anticipatedDisbursementDate }),
        ...pick(['purpose', 'specification', 'collaterals'], data)
      });
      saveMember(member);
      uploadAttachments(member);
      await saveLoan(loan, isV1);
      setCreatedLoan(loan);
    },
    [disbursementModes, isV1]
  );

  return { r, createLoan, createdLoan };
}

export { useLoanAdd, prepareNotes };
