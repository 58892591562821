import React from 'react';

import { LoanSubstepProps } from 'LoanSharedTypes';

import { LoanTermsFields } from '@kwara/components/src/pages/LoanAdd/components/Configure/LoanTermsFields';
import { getMinMaxLoanProductAmount } from '@kwara/components/src/pages/LoanAdd/components/Configure/PrincipalAmountField';

import { Data } from '..';
import { Buttons } from './Buttons';
import { Schedule } from './Schedule';
import { Statistics } from './Statistics';
import { FormFields } from './FormFields';
import { useActions } from './useActions';

export type PreviewProps = LoanSubstepProps<Data>;

export function Preview(props: PreviewProps) {
  const {
    StackChild,
    formProps: { invalid, values: data }
  } = props;

  const { schedule, isLoading, error, clear, onSubmit } = useActions(data);

  return (
    <StackChild size="widest">
      <Statistics product={data.product} />
      <FormFields {...props} />
      <Buttons invalid={invalid} isLoading={isLoading} schedule={schedule} clear={clear} onSubmit={onSubmit} />
      <Schedule isLoading={isLoading} error={error} schedule={schedule} />
    </StackChild>
  );
}

Preview.validate = {
  amount: {
    isRequired: () => true,
    currency: true,
    nonZero: true,
    custom: (target: string, allData: Data) => {
      const amount = Number(target);
      const { principalMax: maximum, principalMin: minimum } = getMinMaxLoanProductAmount(allData);

      if (minimum != null && amount < minimum) return 'rangeUnderflow';
      if (maximum != null && amount > maximum) return 'rangeOverflow';
      return null;
    }
  },
  anticipatedDisbursementDate: { isRequired: (_: string, allData: Data) => allData.isV1 },
  firstRepaymentDate: { isRequired: () => true },
  ...LoanTermsFields.validate
};
