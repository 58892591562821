import { AccountingReport } from '@kwara/models/src';

import { usePagination } from '../../models/hooks/usePagination';

async function fetchAccountingReports({ pageParam = 1, pageSizeParam = 10, queryKey }) {
  const [_, includes] = queryKey;

  return await AccountingReport.stats({ total: ['count', 'pages'] })
    .includes(includes)
    .per(pageSizeParam)
    .page(pageParam)
    .all();
}

const defaultIncludes = ['user'];
export function useAccountingReports(includes = defaultIncludes) {
  return usePagination({ queryKey: 'accounting_reports', fetchFn: fetchAccountingReports, includes });
}
