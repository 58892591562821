import { InferredModel } from 'GlobalTypes';
import { attr } from 'spraypaint';
import axios from 'axios';
import SparkMD5 from 'spark-md5';

import Base from '../../Base';

async function getMD5Checksum(file: File) {
  const arrayBuffer = await file.arrayBuffer();
  const hexChecksum = SparkMD5.ArrayBuffer.hash(arrayBuffer);
  const base64Checksum = Buffer.from(hexChecksum, 'hex').toString('base64');

  return base64Checksum;
}

export const FileUpload = Base.extend({
  static: {
    jsonapiType: 'file_uploads',

    async s3UploadWithPresignedUrl(file: File, opts: { uploadType: string }) {
      const checksum = await getMD5Checksum(file);

      const s3Uploader = FileUpload.create({
        uploadType: opts.uploadType,
        filename: file.name,
        byteSize: file.size,
        contentType: file.type,
        checksum: checksum
      });

      // If this save is successful, it populates the two key fields
      // on the instance from the response: uploadUrl and uploadHeaders,
      // which are used in the next step
      const success = await s3Uploader.save();
      if (!success) {
        throw s3Uploader.errors;
      }

      return s3Uploader.upload(file).then(() => s3Uploader);
    }
  },
  attrs: {
    uploadType: attr(),
    filename: attr(),
    byteSize: attr(),
    checksum: attr(),
    contentType: attr(),
    uploadUrl: attr({ persist: false }),
    uploadHeaders: attr({ persist: false })
  },
  methods: {
    async upload(file: File) {
      const url = this.uploadUrl;
      const headers = { ...this.uploadHeaders, 'Content-Type': this.contentType };
      const opts = { headers };

      return axios.put(url, file, opts);
    }
  }
});

export interface FileUploadT extends Omit<InferredModel<typeof FileUpload>, 'errors'> {
  uploadType: string;
  filename: string;
}
