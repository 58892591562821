import React from 'react';
import cx from 'classnames';
import { Text, Currency } from '@kwara/components/src/Intl';
import { default as BaseTable, Heading, Row, Cell, Footer } from '../../../../../components/Table';

import styles from './Table.module.css';

export function Table({ charges = [], onRemove, onRestore }) {
  const heading = (
    <Row>
      <Heading translationId="LoanAdd.Charges.Table.header.name" />
      <Heading translationId="LoanAdd.Charges.Table.header.amount" />
      <Heading translationId="LoanAdd.Charges.Table.header.calculationType" />
      <Heading translationId="LoanAdd.Charges.Table.header.chargeTime" />
      <Heading translationId="LoanAdd.Charges.Table.header.actions" />
    </Row>
  );

  return (
    <BaseTable
      heading={heading}
      footer={<Footer items={charges} colSpan={5} translationBaseId="LoanAdd.Charges.Table.footer" />}
    >
      {charges.map(charge => (
        <Row key={charge.chargeId}>
          <Cell className={cx({ [styles['crossed-out']]: charge.isMarkedForDestruction })}>{charge.name}</Cell>
          <Cell className={cx({ [styles['crossed-out']]: charge.isMarkedForDestruction })}>
            <Currency value={charge.amount} />
          </Cell>
          <Cell className={cx({ [styles['crossed-out']]: charge.isMarkedForDestruction })}>
            {charge.calculationType}
          </Cell>
          <Cell className={cx({ [styles['crossed-out']]: charge.isMarkedForDestruction })}>{charge.chargeTime}</Cell>
          <Cell>
            {charge.isMarkedForDestruction ? (
              <button
                className={cx(styles.button, 'indigo-500')}
                onClick={() => onRestore && onRestore(charge.chargeId)}
              >
                <Text id="LoanAdd.Charges.Table.Restore.button" />
              </button>
            ) : (
              <button className={cx(styles.button, 'red-500')} onClick={() => onRemove && onRemove(charge.chargeId)}>
                <Text id="LoanAdd.Charges.Table.Remove.button" />
              </button>
            )}
          </Cell>
        </Row>
      ))}
    </BaseTable>
  );
}
