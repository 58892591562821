import * as React from 'react';

import {
  MemberUploadWidget,
  addAttachment,
  removeAttachment,
  isDirty,
  allowedAttachments
} from '@kwara/components/src/MemberUploadWidget';

import { SubStepComponentProps, StackChildSize } from '@kwara/components/src/Wizard/deprecated/Wizard';

const fieldNames = allowedAttachments.typesByContext(['members']);

type DocumentPropTypes = SubStepComponentProps<{}> & {
  stackChildSize?: StackChildSize;
};

export const Documents = ({ StackChild, data, onChange, stackChildSize }: DocumentPropTypes) => (
  <StackChild size={stackChildSize}>
    {fieldNames.map(f => (
      <MemberUploadWidget
        key={f.name}
        onChange={v => onChange(addAttachment(data, v))}
        onRemove={fileName => onChange(removeAttachment(data, fileName))}
        member={data}
        fileName={f.name}
        checked={isDirty(data.attachments, f.name)}
        textId={`DocumentUploads.${f.label}.label`}
      />
    ))}
  </StackChild>
);
