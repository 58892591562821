import React, { useMemo } from 'react';

import { LoanType } from '@kwara/models/src/models/Loan';
import { Text, Currency, Date } from '@kwara/components/src/Intl';

import { LoanDisbursalData } from '../..';
import { useAuth } from '../../../../../hooks';
import { useApprovalsValues } from './useApprovalsValues';
import { AnticipatedPayoutBreakdown } from '../../../components/AnticipatedPayoutBreakdown';

type AddData = (updated: Partial<LoanDisbursalData>) => Promise<void>;
export function useAttributes(loan: LoanType, addData: AddData) {
  const {
    disbursementType,
    eligibleAmount,
    appliedAmount,
    anticipatedPayout,
    principalAmount,
    mambuFees,
    additionalFees,
    loanPayoffAmount,
    disbursementDate,
    disbursementMode,
    recommendedAmount,
    isFetchingAnticipatedPayout,
    disbursement
  } = useApprovalsValues(loan, {
    async onSuccess({ data: { attributes } }) {
      await addData({
        anticipatedPayout: attributes.anticipated_payout_amount
      });
    }
  });
  const auth = useAuth();

  const isV1 = auth.isV1();

  const attributeOne = useMemo(
    () =>
      [
        {
          title: <Text id="LoanDisbursal.Approvals.appliedAmount" />,
          value: <Currency value={appliedAmount} format="currency" />
        },
        {
          title: <Text id="LoanDisbursal.Approvals.eligibleAmount" />,
          value: <Currency value={eligibleAmount} format="currency" />
        },
        {
          title: <Text id="LoanDisbursal.Approvals.recommendedAmount" />,
          value: <Currency value={recommendedAmount} format="currency" />
        },
        isV1
          ? null
          : {
              title: (
                <>
                  <Text id="LoanDisbursal.Approvals.anticipatedPayout" />
                  &nbsp;
                  <AnticipatedPayoutBreakdown
                    principalAmount={principalAmount}
                    mambuFees={mambuFees}
                    additionalFees={additionalFees}
                    loanPayoffAmount={loanPayoffAmount}
                    anticipatedPayout={anticipatedPayout}
                  />
                </>
              ),
              value: <Currency value={anticipatedPayout} format="currency" />,
              isLoading: isFetchingAnticipatedPayout
            }
      ].filter(Boolean),
    [
      additionalFees,
      anticipatedPayout,
      appliedAmount,
      eligibleAmount,
      isFetchingAnticipatedPayout,
      isV1,
      loanPayoffAmount,
      mambuFees,
      principalAmount,
      recommendedAmount
    ]
  );

  const attributeTwo = useMemo(
    () => [
      {
        title: <Text id="LoanDisbursal.Approvals.typeOfDisbursement" />,
        value: (
          <Text
            id={disbursementType ? `LoanAppraisal.Recommendations.DisbursementType.Option.${disbursementType}` : '-'}
          />
        )
      },
      {
        title: <Text id="LoanDisbursal.Approvals.disbursementMethod" />,
        value: <Text id={`TransactionMethod.${disbursementMode}`} />
      },
      {
        title: <Text id="LoanDisbursal.Approvals.dateOfDisbursement" />,
        value: <Date value={disbursementDate} />
      }
    ],
    [disbursementDate, disbursementMode, disbursementType]
  );

  return {
    disbursement,
    attributeOne,
    attributeTwo
  };
}
