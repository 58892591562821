import * as React from 'react';

import { LoanFormData } from 'LoanSharedTypes';

import { Loadable } from '@kwara/components/src/Loadable';
import { useLoanProduct } from '@kwara/models/src/models/request/hooks';

import Wizard from '../../components/Wizard';

import { steps } from './config';
import { WizardPageProps } from '..';
import { settingPath } from '../../lib/urls';

type T = {
  isV1: boolean;
  principalMax: number;
  principalMin: number;
  firstRepaymentDate: Date;
};

export interface Data extends LoanFormData<T> {}

type Props = WizardPageProps<{ productId: string }>;

export function LoanSchedulePreview({ baseUrl, match, history }: Props) {
  const response = useLoanProduct(match.params.productId);

  return (
    <Loadable {...response}>
      {product => {
        const { unit, value } = product.toRepaymentFrequencyUI();
        return (
          <Wizard
            baseUrl={baseUrl}
            history={history}
            initialData={{
              product,
              repaymentPeriod: value,
              repaymentPeriodUnit: unit || 'MONTHS',
              // For translations
              productName: product.name
            }}
            cancelReturnsTo={settingPath({ action: 'loans' })}
            currentStep={match.params.step}
            currentSubStep={match.params.subStep != null ? parseInt(match.params.subStep, 10) : null}
            onSubmit={() => Promise.resolve()}
            steps={steps}
            startId="preview"
            titleId="LoanSchedulePreview.title"
            type="approval"
          />
        );
      }}
    </Loadable>
  );
}
