import * as React from 'react';
import { LoanClassificationFields } from '@kwara/components/src/pages/LoanAdd/components/Configure/LoanClassificationFields';

import { LoanAddPropTypes } from '../..';

function SasraClassification(props: LoanAddPropTypes) {
  return <LoanClassificationFields {...props} />;
}

export default {
  Component: SasraClassification,
  validate: LoanClassificationFields.validate
};
