import map from 'lodash/fp/map';
import React from 'react';
import { PaymentMethodT } from '@kwara/models/src';
import { SubscribedSelectField as SelectField } from '@kwara/components/src/Form';

const Option = SelectField.Option;

export function PaymentMethodSelector({
  methods = [],
  name = 'method',
  disabled,
  onChange
}: {
  methods?: PaymentMethodT[];
  name?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}) {
  if (methods.length === 0) return null;

  return (
    <SelectField
      required
      name={name}
      disabled={disabled}
      size="medium"
      labelId="PaymentForm.method"
      inputOnChange={onChange}
    >
      <Option key={'empty'} value={''}></Option>
      {map<PaymentMethodT, React.ReactElement<typeof Option>>(
        method => (
          <Option key={method.id} value={method.id}>
            {method.name}
          </Option>
        ),
        methods
      )}
    </SelectField>
  );
}
