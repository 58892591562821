import * as React from 'react';
import size from 'lodash/fp/size';
import filter from 'lodash/fp/filter';
import get from 'lodash/fp/get';

import { MemberType } from '@kwara/models/src';
import { ProfilePhoto } from '@kwara/components/src/ProfilePhoto';
import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import { InfoPanel } from '@kwara/components/src/InfoPanel';
import { Statistic } from '@kwara/components/src/Statistic';
import { capitalize } from '@kwara/models/src/models/Member';
import { LoadableStatistic } from '@kwara/components/src/Statistic/LoadableStatistic';
import StatisticRow from '@kwara/components/src/StatisticRow';
import { Loadable, LoadingSkeleton } from '@kwara/components/src/Loadable';
import { maskStringUntilLastNCharacters } from '@kwara/lib/src/utils/maskStringUntilLastNCharacters/maskStringUntilLastNCharacters';

import { OverviewMetadata } from './OverviewMetadata';

import { DetailOverviewSection } from '../../../components/Detail/DetailOverviewSection';
import { LoanEligibilityPanel, LoanEligibilityPanelUI } from '../../../components/LoanEligibilityPanel';
import { LoansPanel } from '../../../components/LoansPanel';
import { SavingsPanel } from '../../../components/SavingsPanel';
import { Currency } from '../../../components/Currency';

import { SavingsResponseT, LoansResponseT, MemberMetricsResponseT } from '..';
import { useMemberEligibility, useAuth } from '../../../hooks';

type Props = {
  member: MemberType;
  actions: React.ReactNode;
  navigation: React.ReactNode;
  showBack: boolean;
  statusOverride: void | 'APPLICANT' | 'GUARANTOR' | null;
  savingsResponse: SavingsResponseT;
  loansResponse: LoansResponseT;
  memberMetricsResponse: MemberMetricsResponseT;
};

export default ({
  member, // member light
  navigation,
  actions,
  showBack,
  statusOverride,
  savingsResponse,
  loansResponse,
  memberMetricsResponse
}: Props) => {
  const auth = useAuth();
  const memberEligibilityResponse = useMemberEligibility({ memberId: member.id, enabled: auth.isV1() });
  const [showFullNationalId, setShowFullNationalId] = React.useState(false);
  const toggleView = () => setShowFullNationalId(curr => !curr);

  return (
    <DetailOverviewSection
      metadata={
        <OverviewMetadata
          member={member}
          actions={actions}
          showBack={showBack}
          className="hide-on-print"
          statusOverride={statusOverride}
        />
      }
      secondary={navigation}
      title={<Text id="MemberDetail.title" values={{ id: member.id, fullName: member.fullName() }} />}
      headerRight={<ProfilePhoto memberId={member.id} attachments={member.attachments} size="regular" isExpandable />}
    >
      <section className="mb5">
        <StatisticRow widthClass="w-50">
          <LoadableStatistic
            title={
              <>
                <Text id="MemberDetail.Overview.deposits" />{' '}
                <InfoPanel>
                  <Loadable {...savingsResponse}>{savings => <SavingsPanel savings={savings} />}</Loadable>
                </InfoPanel>
              </>
            }
            r={memberMetricsResponse}
            value={member => <Currency value={member.totalSavings || 0} />}
            color={'teal-600'}
            size={'5x-large'}
          />
          <LoadableStatistic
            title={
              <>
                <Text id="MemberDetail.Overview.loans" />{' '}
                <InfoPanel>
                  <Loadable {...loansResponse}>{loans => <LoansPanel loans={loans} />}</Loadable>
                </InfoPanel>
              </>
            }
            r={memberMetricsResponse}
            value={member => (
              <>
                <Currency value={member.totalLoansBalance || 0} />{' '}
              </>
            )}
            border={true}
            color={'red-600'}
            size={'5x-large'}
          />
        </StatisticRow>
        <StatisticRow widthClass="w-50" border={true}>
          {auth.isV1() ? (
            <Statistic
              title={
                <>
                  <Text id="MemberDetail.Overview.eligibleAmount" />{' '}
                  <InfoPanel>
                    <LoanEligibilityPanelUI response={memberEligibilityResponse} />
                  </InfoPanel>
                </>
              }
              value={
                <Loadable loading={<LoadingSkeleton />} isLoading={memberEligibilityResponse.isLoading}>
                  <Currency value={get('meta.max_eligibility', memberEligibilityResponse)} />
                </Loadable>
              }
              border={true}
              size={'5x-large'}
            />
          ) : (
            <LoadableStatistic
              title={
                <>
                  <Text id="MemberDetail.Overview.eligibleAmount" />{' '}
                  <InfoPanel>
                    <LoanEligibilityPanel member={member} />
                  </InfoPanel>
                </>
              }
              r={memberMetricsResponse}
              value={member => (member.isEligibleForLoan() ? <Currency value={member.eligibleAmount} /> : '-')}
              border={true}
              size={'5x-large'}
            />
          )}
          <LoadableStatistic
            data-testid="member.shareCapitalAmount"
            title={<Text id="MemberDetail.Overview.shareCapitalAmount" />}
            r={memberMetricsResponse}
            value={member => (member.shareCapitalAmount ? <Currency value={member.shareCapitalAmount} /> : null)}
            border={true}
            size={'5x-large'}
          />
        </StatisticRow>
        <StatisticRow widthClass="w-25" border>
          <LoadableStatistic
            title={<Text id="MemberDetail.Overview.netValue" />}
            r={memberMetricsResponse}
            value={member => <Currency value={member.standing} />}
            color={member => (member && member.standing < 0 ? 'red-600' : '')}
            border={true}
            size={'large'}
          />
          <LoadableStatistic
            title={<Text id="MemberDetail.Overview.pending" />}
            r={loansResponse}
            value={loans => size(filter(l => l.isPendingApproval(), loans))}
            border={true}
            size={'large'}
          />
          <LoadableStatistic
            title={<Text id="MemberDetail.Overview.guaranteedLoans" />}
            r={memberMetricsResponse}
            value={member => <Currency value={member.totalLiabilities} />}
            border={true}
            size={'large'}
          />
          <Statistic
            title={<Text id="MemberDetail.Overview.mobileBanking" />}
            value={
              member.mbankingStatus ? (
                <Text id={`MemberDetail.Overview.mobileBanking.${member.mbankingStatus}`} />
              ) : null
            }
            border={true}
            size={'large'}
          />
        </StatisticRow>
        <StatisticRow widthClass={member.historicalId() ? 'w-25' : 'w-third'} border>
          <Statistic
            title={<Text id="MemberDetail.Overview.email" />}
            value={member.email}
            border={true}
            size={'large'}
            breakWord
          />
          <Statistic
            title={<Text id="MemberDetail.Overview.nationalId" />}
            value={
              <span className="pointer" onClick={toggleView}>
                <If
                  condition={showFullNationalId}
                  do={member.nationalId()}
                  else={<span>{maskStringUntilLastNCharacters(member.nationalId(), 3)}</span>}
                />
              </span>
            }
            border={true}
            size={'large'}
          />
          <Statistic
            title={<Text id="MemberDetail.Overview.mobile" />}
            value={member.phoneNumber}
            border={true}
            size={'large'}
          />
          <Statistic
            title={<Text id="MemberDetail.Overview.source" />}
            value={member.source ? capitalize(member.source) : '-'}
            border={true}
            size={'large'}
          />

          {member.historicalId() && (
            <Statistic
              title={<Text id="MemberDetail.Overview.historicalId" />}
              value={member.historicalId()}
              border={true}
              size={'large'}
            />
          )}
        </StatisticRow>
      </section>
    </DetailOverviewSection>
  );
};
