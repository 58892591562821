import React from 'react';

import { tomorrow } from '@kwara/lib/src/dates';
import { Text } from '@kwara/components/src/Intl';
import {
  DurationField,
  RepaymentPeriodFields
} from '@kwara/components/src/pages/LoanAdd/components/Configure/LoanTermsFields';
import { getMinMaxLoanProductAmount } from '@kwara/components/src/pages/LoanAdd/components/Configure/PrincipalAmountField';

import { PreviewProps } from './Preview';
import { Grid } from '../../../components/Grid';
import { SubscribedDatePicker } from '../../../components/Form';

export function FormFields(props: PreviewProps) {
  const {
    TextField,
    formProps: { values: data }
  } = props;

  const { principalMax: maximum, principalMin: minimum } = getMinMaxLoanProductAmount(data);

  return (
    <Grid columns={2} width="w-50" border={false}>
      <TextField
        name="amount"
        type="number"
        titleId="LoanSchedulePreview.Preview.amount.titleId"
        info={maximum ? <Text id="LoanSchedulePreview.Preview.amount.info" values={{ maximum, minimum }} /> : null}
        required
        isCurrency
      />
      <RepaymentPeriodFields {...props} />
      <SubscribedDatePicker
        name="firstRepaymentDate"
        titleId="LoanSchedulePreview.Preview.firstRepaymentDate.titleId"
        disabledDays={{ before: tomorrow() }}
        required
      />
      <DurationField {...props} />
    </Grid>
  );
}
