import { IconType } from '../assets/IconType';
import { IconStatus } from '../assets/IconStatus';
import { IconSearch } from '../assets/IconSearch';
import { IconCalendar } from '../assets/IconCalendar';

/**
 * All new filters should be added here as a key.
 * The purpose is to serve as a single source of
 * truth for accessing filters type anywhere needed
 */
export const FILTER_TYPES = Object.freeze({
  STATUS: 'status',
  RISK_LEVEL: 'riskLevel',
  MEMBER_ID: 'memberId',
  ACCOUNT_ID: 'accountId',
  BRANCH_ID: 'branchId',
  DATE: 'date',
  GL_ACCOUNT: 'glAccount',
  LOAN_PRODUCT_ID: 'loanProductId',
  SAVINGS_PRODUCT_ID: 'savingsProductId',

  ACTIVITY_FEED_EVENT: 'activityFeedEvent',
  ACTIVITY_FEED_USER: 'activityFeedUser',
  ACTIVITY_FEED_DATE: 'activityFeedDate',

  LOAN_DISBURSEMENT_STATUS: 'loanDisbursementStatus',
  LOAN_DISBURSEMENT_DATE: 'loanDisbursementDate',
  LOAN_DISBURSEMENT_SEARCH: 'loanDisbursementSearch',

  TRANSACTION_SEARCH: 'transactionSearch',

  PENDING_JOURNAL_BRANCH_ID: 'pendingJournalBranchId'
});

export const FILTER_ICONS = {
  [FILTER_TYPES.STATUS]: IconStatus,
  [FILTER_TYPES.RISK_LEVEL]: IconSearch,
  [FILTER_TYPES.ACCOUNT_ID]: IconSearch,
  [FILTER_TYPES.MEMBER_ID]: IconSearch,
  [FILTER_TYPES.BRANCH_ID]: IconType,
  [FILTER_TYPES.DATE]: IconCalendar,
  [FILTER_TYPES.GL_ACCOUNT]: IconType,
  [FILTER_TYPES.LOAN_PRODUCT_ID]: IconType,
  [FILTER_TYPES.SAVINGS_PRODUCT_ID]: IconType,

  [FILTER_TYPES.ACTIVITY_FEED_EVENT]: IconType,
  [FILTER_TYPES.ACTIVITY_FEED_USER]: IconType,
  [FILTER_TYPES.ACTIVITY_FEED_DATE]: IconCalendar,

  [FILTER_TYPES.LOAN_DISBURSEMENT_STATUS]: IconType,
  [FILTER_TYPES.LOAN_DISBURSEMENT_DATE]: IconCalendar,
  [FILTER_TYPES.LOAN_DISBURSEMENT_SEARCH]: IconSearch,

  [FILTER_TYPES.TRANSACTION_SEARCH]: IconSearch,

  [FILTER_TYPES.PENDING_JOURNAL_BRANCH_ID]: IconType
} as const;

/**
 * @SELECTION_TYPES is used to determine if a filter-category
 * is to accept multiple filters or just a single filter.
 * A real use case for multi-selection is having `dateRangerFilter`
 * category that accepts an array of `from-date-object` and `to-date-object`
 */
export const SELECTION_TYPES = {
  SINGLE: 'single',
  MULTI: 'multi'
} as const;

/**
 * @FILTER_SELECTION_VERBS is used to determine how to control an
 * incoming filter-value(either to add, update, or delete it from the state)
 * @CREATE_OR_DELETE creates a new filter or delete a selected filter
 * @UPDATE replaces an existing filter with the new value-structure
 */
export const FILTER_SELECTION_VERBS = {
  UPDATE: 'update',
  CREATE_OR_DELETE: 'createOrDelete'
} as const;
