import { TrialBalance, DownloadXlsxParams } from '@kwara/models/src/models/v1/TrialBalance/Report';

import { useWaitingCursor } from '../../../../hooks/useWaitingCursor';

export function useDownloadTrialBalance() {
  const { onWaitingStart, onWaitingEnd, isWaiting: isDownloading } = useWaitingCursor();

  function onDownloadXlsx(params: DownloadXlsxParams) {
    onWaitingStart();
    return TrialBalance.downloadXlsx(params).finally(onWaitingEnd);
  }

  return { onDownloadXlsx, isDownloading };
}
