import React from 'react';

import { GlContexts } from '@kwara/models/src';
import { SubscribedPaymentSelectField, GlAccountSelect } from '@kwara/components/src/Form';

import { LoanPayOffPropTypes } from '.';
import { useGetPayOffConfig } from './useGetPayOffConfig';

export function LegacyPaymentMethod({ data: { loan }, Condition, addData }: LoanPayOffPropTypes) {
  const payOffConfig = useGetPayOffConfig(loan);

  return (
    <>
      <SubscribedPaymentSelectField
        required
        name="method"
        config={payOffConfig}
        inputOnChange={() => addData({ bankGlId: '' })}
      />
      <Condition when="method" not="">
        {({ input: { value: method } }) => (
          <GlAccountSelect method={method} addData={addData} context={GlContexts.REPAYMENT} />
        )}
      </Condition>
    </>
  );
}
