import * as React from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import compact from 'lodash/fp/compact';

import Button from '@kwara/components/src/Button';
import { PrintButton } from '@kwara/components/src';
import { Loadable, LoadingKwara } from '@kwara/components/src/Loadable';
import PageLayoutWithOverview from '@kwara/components/src/PageLayoutWithOverview';
import { SavingType } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { useSaving } from '@kwara/models/src/models/request/hooks';

import { memberPath, savingPath } from '../../lib/urls';
import { AsNavigation, BackToTopNavigation } from '../../components/DetailNavigation';
import { ButtonMenu } from '../../components/ButtonMenu';
import { DetailSection } from '../../components/Detail/DetailSection';
import DetailSubsection from '../../components/DetailSubsection';
import Head from '../../components/Head';
import Overview from './components/Overview';
import Visible from '../../components/Visible';

import { useAuth } from '../../hooks';
import MemberCard from './components/MemberCard';
import { Transactions } from './components/Transactions';
import { Activity } from './components/Activity';
import { Remittance } from './components/Remittance';
import { FeesAndPenalties } from './components/FeesAndPenalties';
import { useSavingTransactions, useSavingsActivity, usePermissions } from '../../hooks';

import { SavingPageProps } from '..';

type Props = SavingPageProps;

export const PrimaryButtonForSaving = (saving: SavingType, refetch: Function) => {
  const { AppPermissions } = usePermissions();

  if (saving.transactionsPermitted()) {
    return (
      <Visible to={AppPermissions.AddDeposits}>
        <Button
          type="primary"
          to={savingPath({ id: saving.id, action: 'deposit' })}
          size="medium"
          // The ButtonBar component SHOULD handle applying the margin left className
          // but because this is wrapped in `<Visible />` it doesn't work
          // TO DO: Make it work programatically via the addClassToButtons
          // function in /ButtonBar
          className="ml3"
        >
          <Text id="SavingDetail.add" />
        </Button>
      </Visible>
    );
  }

  if (saving.canBeReopened()) {
    return (
      <Visible to={AppPermissions.ReopenSavings}>
        <Button
          onClick={() =>
            saving.reopen().then(() => {
              refetch();
            })
          }
          size="medium"
          className="ml3"
        >
          <Text id="SavingDetail.reopen" />
        </Button>
      </Visible>
    );
  }

  return null;
};

// At the moment we keep this pattern,
// because `AsNavigation` seems to require
// it to generate the side navbar items
//
const renderBody = ({ transactionsR, activityR, saving, refetch, isV1 }) => {
  return (
    <>
      <DetailSection
        id="transactions"
        title={<Text id="SavingDetail.transactions" />}
        headerRight={
          isV1 ? null : (
            <PrintButton size={'medium'} className={'mr3'} to={savingPath({ id: saving.id, action: 'statement' })} />
          )
        }
        subtitle={<BackToTopNavigation />}
      >
        <DetailSubsection title="Transactions">
          <Loadable {...transactionsR}>
            {transactions => <Transactions transactions={transactions} transactionsR={transactionsR} />}
          </Loadable>
        </DetailSubsection>

        {isV1 ? null : (
          <DetailSubsection title={<Text id="SavingDetail.feesAndPenalties" />} classNames="hide-on-print">
            <FeesAndPenalties saving={saving} />
          </DetailSubsection>
        )}
      </DetailSection>

      {isV1 ? null : (
        <DetailSection
          id="remittance"
          title={<Text id="SavingDetail.Remittance.title" />}
          subtitle={<BackToTopNavigation />}
        >
          <Remittance saving={saving} refetch={refetch} />
        </DetailSection>
      )}

      <DetailSection
        id="member"
        title={<Text id="SavingDetail.member" />}
        classNames="hide-on-print"
        subtitle={<BackToTopNavigation />}
      >
        <div className="w-third">
          <MemberCard member={saving.member} actionTo={memberPath({ id: saving.member.id })} />
        </div>
      </DetailSection>

      {isV1 ? null : (
        <DetailSection
          id="activity"
          title={<Text id="SavingDetail.activity" />}
          classNames="hide-on-print"
          subtitle={<BackToTopNavigation />}
        >
          <Activity savingsId={saving.id} r={activityR} />
        </DetailSection>
      )}
    </>
  );
};

const SavingDetail = (props: Props) => {
  const { permission, AppPermissions } = usePermissions();
  const savingId = props.match.params.savingId;

  const auth = useAuth();

  const isV1 = auth.isV1();

  const r = useSaving(savingId);
  const transactionsR = useSavingTransactions({ id: savingId });
  const activityR = useSavingsActivity({ id: savingId, enabled: !isV1 });

  const historyState = () => props.history.location.state || {};
  const back = () => {
    if (historyState().useBrowserBack) {
      props.history.goBack();
    } else {
      props.history.push(savingPath());
    }
  };

  return (
    <Loadable {...r} loading={<LoadingKwara />}>
      {saving => {
        const { member } = saving;
        const primaryButton = PrimaryButtonForSaving(saving, r.refetch);

        const disableCloseButton = !saving.canBeClosed();
        const disableTransferButton = !saving.transactionsPermitted();
        const disableWithdrawButton = !saving.isWithdrawable();
        const disableAddFee = !saving.canAddPenalty();
        const disableMenuButton = disableWithdrawButton && disableCloseButton && disableTransferButton;

        const menuItems = [
          permission.to(AppPermissions.AddWithdrawal) ? (
            <ButtonMenu.Item
              key="withdrawal"
              disabled={disableWithdrawButton}
              to={savingPath({ id: saving.id, action: 'withdrawal' })}
            >
              <Text id="SavingDetail.more.Withdraw" />
            </ButtonMenu.Item>
          ) : null,
          permission.to(AppPermissions.MakeTransfer) ? (
            <ButtonMenu.Item
              key="transfer"
              disabled={disableTransferButton}
              to={savingPath({ id: saving.id, action: 'transfer' })}
            >
              <Text id="SavingDetail.more.Transfer" />
            </ButtonMenu.Item>
          ) : null,
          permission.to(AppPermissions.AddSavingsFee) ? (
            <ButtonMenu.Item
              key="addPenaltyFee"
              disabled={disableAddFee}
              to={savingPath({ id: saving.id, action: 'fee' })}
            >
              <Text id="SavingDetail.FeesAndPenalties.addPenaltyFee" />
            </ButtonMenu.Item>
          ) : null,
          permission.to(AppPermissions.CloseSavings) ? (
            <ButtonMenu.Item
              key="close"
              disabled={disableCloseButton}
              to={savingPath({ id: saving.id, action: 'close' })}
            >
              <Text id="SavingDetail.more.Close" />
            </ButtonMenu.Item>
          ) : null
        ];

        const buttonMenu = !isEmpty(compact(menuItems)) ? (
          <ButtonMenu titleId="SavingDetail.more" type="secondary" disabled={disableMenuButton}>
            {menuItems}
          </ButtonMenu>
        ) : null;

        const body = renderBody({
          ...props,
          saving,
          member,
          transactionsR,
          activityR,
          refetch: r.refetch,
          isV1
        });
        const Navigation = AsNavigation(body);

        return (
          <PageLayoutWithOverview
            className="pt5"
            printOverview={false}
            overview={
              <Overview
                navigation={<Navigation />}
                saving={saving}
                backLabel={historyState().backLabel}
                onBack={back}
                actions={[buttonMenu, primaryButton]}
              />
            }
          >
            <Head titleId="SavingDetail.title" values={{ id: saving.id }} />
            {body}
          </PageLayoutWithOverview>
        );
      }}
    </Loadable>
  );
};

export default SavingDetail;
