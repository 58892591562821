import React from 'react';

import { Currency, Date, DateTime } from '@kwara/components/src/Intl';
import { JournalEntryT } from '@kwara/models/src/models/JournalEntry';

import { JournalEntryDetails } from './JournalEntryDetails';
import { Row, Cell, Collapsible } from '../../../../../components/Table';

type LegacyTableRowProps = {
  journalEntry: JournalEntryT;
};

export function LegacyTableRow({ journalEntry }: LegacyTableRowProps) {
  return (
    <Collapsible key={journalEntry.id}>
      <Row>
        <Cell>{journalEntry.id}</Cell>
        <Cell>
          <Date value={journalEntry.bookingDate} />
        </Cell>
        <Cell>
          <DateTime value={journalEntry.createdAt} />
        </Cell>
        <Cell>{journalEntry.transactionId}</Cell>
        <Cell>
          {journalEntry.glAccount.id} <br />
          <span className="grey-400">{journalEntry.glAccount.name}</span>
        </Cell>
        <Cell>{journalEntry.branch?.name}</Cell>
        <Cell align="right">
          {journalEntry.type === 'DEBIT' ? <Currency hideDecimals={false} value={journalEntry.amount} /> : null}
        </Cell>
        <Cell align="right">
          {journalEntry.type === 'CREDIT' ? <Currency hideDecimals={false} value={journalEntry.amount} /> : null}
        </Cell>
      </Row>
      <JournalEntryDetails transactionId={journalEntry.id} />
    </Collapsible>
  );
}
