import React from 'react';

import { PaymentMethodSelector } from '../../../../../components/v1/PaymentForm/components/PaymentMethodSelector';
import { GlLinkSelector } from '../../../../../components/v1/PaymentForm/components/GlLinkSelector';
import { useGlLinks } from '../../../../../components/v1/PaymentForm/useGlLinks';
import { usePaymentMethods } from '../../../../../components/v1/PaymentForm/usePaymentMethods';

export function V1PaymentMethod({
  paymentMethodId,
  addData
}: {
  paymentMethodId?: string;
  addData: (d: { glLinkId: string }) => void;
}) {
  const { data: paymentMethods, isLoading } = usePaymentMethods();
  const { data: glLinks } = useGlLinks({ paymentMethodId, transactionType: 'disbursal' });
  return (
    <>
      <PaymentMethodSelector
        name="paymentMethodId"
        disabled={isLoading}
        methods={paymentMethods || []}
        onChange={() => addData({ glLinkId: '' })}
      />
      <GlLinkSelector name="glLinkId" glLinks={glLinks} />
    </>
  );
}
