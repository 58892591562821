import * as React from 'react';
import { withRouter } from 'react-router-dom';

import { HttpErrorObject } from 'GlobalTypes';

import Button from '@kwara/components/src/Button';

import { Text } from '@kwara/components/src/Intl';
import { SavingType } from '@kwara/models/src/models/Saving';

import Visible from '../../../../components/Visible';
import OverviewList from '../../../../components/OverviewListV2';

import { ListTable } from './ListTable';
import { savingPath } from '../../../../lib/urls';
import { usePermissions } from '../../../../hooks';

const AddSavingButton = withRouter(() => (
  <Button size="regular" type="primary" to={savingPath({ action: 'create' })}>
    <Text id="SavingList.addSaving" />
  </Button>
));

const AddSavingButtonWithPermission = () => {
  const { AppPermissions } = usePermissions();

  return (
    <Visible to={AppPermissions.AddSavings}>
      <AddSavingButton />
    </Visible>
  );
};

type Props = {
  loading: boolean;
  hasMore: boolean;
  errors: HttpErrorObject[] | null;
  items: SavingType[];
  totalNumResults: number;
  onLoadMoreData(): void;
};

export function SavingsList({ errors, hasMore, loading, onLoadMoreData, totalNumResults, items = [] }: Props) {
  return (
    <OverviewList
      withFilter
      items={items}
      filterDisabled={loading}
      headerId="SavingList.heading"
      labelId="SavingList.filteredCount"
      totalNumResults={totalNumResults}
      actions={[<AddSavingButtonWithPermission key="createSavingButton" />]}
      table={
        <ListTable
          hasMore={hasMore}
          loading={loading}
          errors={errors}
          savings={items}
          onLoadMoreData={onLoadMoreData}
        />
      }
    />
  );
}
