import * as React from 'react';
import cx from 'classnames';
import map from 'lodash/fp/map';
import isEmpty from 'lodash/fp/isEmpty';
import get from 'lodash/fp/get';
import sortBy from 'lodash/fp/sortBy';

import { MemberType, MemberEligibilityT } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { Loadable } from '@kwara/components/src/Loadable';

import { Currency } from '../Currency';

import styles from './index.module.css';

import { useMemberEligibility } from '../../hooks';

type PropsThumbnail = {
  eligibility: MemberEligibilityT;
};

type PropsPanel = {
  member: MemberType;
};

function filterEligibilities(eligibilities: MemberEligibilityT[]) {
  return sortBy(el => -Number(el.eligibleAmount), eligibilities);
}

const NoEligibility = ({ children }: { children: React.ReactNode }) => <div className="ph2 nowrap">{children}</div>;

const EligibilityThumbnail = ({ eligibility }: PropsThumbnail) => {
  const { eligibleAmount } = eligibility;
  return (
    <div className={cx(styles.EligibilityThumbnail, 'tl pv2 ph1 bb b--light-gray kw-text-small')}>
      <div className="pv2 grey-400">{<Currency format="currency" value={eligibleAmount} />}</div>
      <div>{eligibility.productName || get('product.name', eligibility)}</div>
    </div>
  );
};

const includes = ['product'];
export const LoanEligibilityPanel = ({ member }: PropsPanel) => {
  const r = useMemberEligibility({ memberId: member.id, includes });

  return <LoanEligibilityPanelUI response={r} />;
};

export function LoanEligibilityPanelUI({ response }) {
  return (
    <Loadable {...response}>
      {data => {
        const eligibilities = filterEligibilities(data);

        if (isEmpty(eligibilities)) {
          return (
            <NoEligibility>
              <Text id="MemberDetail.Overview.eligibility.empty" />
            </NoEligibility>
          );
        }

        return (
          <div className={cx(styles.EligibilityPanel, 'pa2')}>
            {map(
              eligibility => (
                <EligibilityThumbnail key={eligibility.id} eligibility={eligibility} />
              ),
              eligibilities
            )}
          </div>
        );
      }}
    </Loadable>
  );
}
