import * as React from 'react';
import map from 'lodash/map';

import { DocumentListBase, DocumentListPropTypes } from '@kwara/components/src/DocumentListBase';

import { DocumentItem } from './DocumentItem';
import { useAuth } from '../../hooks';

export function DocumentList(props: DocumentListPropTypes) {
  const auth = useAuth();
  return (
    <DocumentListBase {...props} isV1={auth.isV1()}>
      {({ attachments, setShownAttachment }) => (
        <div>
          {map(attachments, attachment => (
            <DocumentItem
              key={attachment.id}
              name={attachment.name}
              onClickHandler={() => setShownAttachment(attachment)}
            />
          ))}
        </div>
      )}
    </DocumentListBase>
  );
}
