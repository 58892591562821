import { useMemo } from 'react';
import get from 'lodash/fp/get';
import filter from 'lodash/fp/filter';

import { LoanType } from '@kwara/models/src/models/Loan';

import { SavingType } from '@kwara/models/src/models/Saving';
import { TransactionChannels, getTransactionTypes, contexts } from '@kwara/models/src/models/Transactions';

import { store } from '../../models/Store/Store';

export function useGetPayOffConfig(loan: LoanType) {
  const isKBS = store.isKBS;

  return useMemo(() => {
    const disableAccountIf = (saving: SavingType) => !saving.isWithdrawable() || saving.balance < loan.totalBalance;
    const transactionTypes = getTransactionTypes(contexts.LoanPayOff).values;
    const methods = isKBS ? filter(m => m.value === TransactionChannels.fosa, transactionTypes) : transactionTypes;

    return {
      methods,
      disableAccountIf,
      bank: 'bankName',
      memberId: get('member.id', loan),
      showAccountSelectionFor: [TransactionChannels.fosa],
      showChequeFieldsFor: [TransactionChannels.cheque],
      showBanksFieldsFor: [TransactionChannels.bankTransfer, TransactionChannels.cheque]
    };
  }, [isKBS, loan]);
}
