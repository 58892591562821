import React from 'react';

import OverviewListV2 from '../../components/OverviewListV2';

import { useAuth } from '../../hooks';
import { useV1Filter } from './filters/useV1Filter';
import { BodyContentWrapper } from '../../layouts';
import { Indicators } from './components/Indicators';
import { SavingsList } from './components/SavingsList';
import { usePaginatedSavings } from './usePaginatedSavings';
import { useLegacyFilter } from './filters/useLegacyFilter';
import { usePermissions } from '../../hooks/usePermissions/usePermissions';

export function InternalSavings({ isV1 }: { isV1: boolean }) {
  const { AppPermissions, permission } = usePermissions();

  const {
    isLoading,
    isFetchingNextPage,
    allData,
    fetchNextPage,
    totalResults,
    hasNextPage,
    error
  } = usePaginatedSavings();

  const loading = isLoading || isFetchingNextPage;

  return (
    <BodyContentWrapper.Root headId="SavingList.title">
      {permission.to(AppPermissions.ViewSavingsMetrics) && !isV1 ? (
        <BodyContentWrapper.Row.Root>
          <BodyContentWrapper.Row.Indicators>
            <Indicators />
          </BodyContentWrapper.Row.Indicators>
        </BodyContentWrapper.Row.Root>
      ) : null}
      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Table>
          <SavingsList
            items={allData}
            hasMore={hasNextPage}
            loading={loading}
            errors={error}
            onLoadMoreData={fetchNextPage}
            totalNumResults={totalResults}
          />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
}

export function Savings() {
  const auth = useAuth();
  const isV1 = auth.isV1();

  const useFilter = isV1 ? useV1Filter : useLegacyFilter;
  const filters = useFilter();

  return (
    <OverviewListV2.Root filters={filters}>
      <InternalSavings isV1={isV1} />
    </OverviewListV2.Root>
  );
}
