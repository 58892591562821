import React from 'react';
import cx from 'classnames';
import get from 'lodash/fp/get';

import { LoanSubstepProps, ApiError } from 'GlobalTypes';

import { Text, If, Button } from '@kwara/components/src';
import { LinkButton } from '@kwara/components/src/LinkButton';
import { FileNameT } from '@kwara/components/src/UploadWidgetUI';

import Expander from '../Expander/Expander';

import { validate } from './CollateralsImp';
import { CollateralCard } from './components/CollateralCard/CollateralCard';
import { useCollateralsForm } from './hooks/useCollateralsForm';
import { CollateralsLister } from './components/CollateralsLister/CollateralsLister';

export interface CollateralsPropTypes extends Partial<LoanSubstepProps> {}

export interface CollateralBasePropTypes extends CollateralsPropTypes {
  withControls?: boolean;
  withNarration?: boolean;
  withUploadWidget?: boolean;
  onCollateralRemove?: (index: number) => Promise<void>;
  onCollateralSave?: (index: number) => Promise<void>;
  patchError?: ApiError;
  getPath?: (index: number) => string;
  onFileInputChange?: (e, index?: number) => Promise<void>;
  onFileRemove?: (e, index?: number) => Promise<void>;
  fileName?: FileNameT;
  hasFile?: (p: any) => boolean;
  disableEditing?: boolean; // temporary prop to disable editing for v1 flows
}

function Collaterals(props: CollateralBasePropTypes) {
  const {
    data: { collaterals = [], product },
    formProps,
    onChange,
    withControls = false,
    withNarration = false,
    disableEditing = false,
    withUploadWidget,
    onCollateralRemove,
    onCollateralSave,
    patchError,
    getPath,
    onFileRemove,
    onFileInputChange,
    fileName,
    hasFile = () => false
  } = props;

  const {
    collateralCardRef,
    disabledExpanderAddCollateral,
    workingIndex,
    isEditingCollateralList,
    isEditingACollateral,
    onStartAddingCollateral,
    hideCollateralEditor,
    onEditStart
  } = useCollateralsForm({ collaterals, withNarration, product, onChange });

  const path = getPath && getPath(workingIndex);

  return (
    <Expander.Root aria-label="Collateral">
      {({ isExpanded, state, toggle }) => (
        <>
          <Expander.Header isExpanded={isExpanded} onToggle={toggle} data-testid="colsExpHeader">
            <div className="flex justify-between">
              <Text id="Collateral.heading" />
              <If
                condition={withControls && !disableEditing}
                do={
                  <div>
                    <Button
                      type="secondary"
                      disabled={disabledExpanderAddCollateral}
                      onClick={() => {
                        if (isEditingCollateralList) return;
                        onStartAddingCollateral();
                        if (!isExpanded) {
                          toggle();
                        }
                      }}
                    >
                      <Text id="Collateral.add" />
                    </Button>
                  </div>
                }
              />
            </div>
          </Expander.Header>

          <Expander.Body state={state}>
            <If
              condition={product?.hasCollateralsEnabled()}
              do={
                <>
                  <If
                    condition={isEditingCollateralList && !disableEditing}
                    do={
                      <CollateralCard
                        {...props}
                        ref={collateralCardRef}
                        index={workingIndex}
                        path={path}
                        formProps={formProps}
                        patchError={patchError}
                        isEditingACollateral={isEditingACollateral}
                        withUploadWidget={withUploadWidget}
                        onCollateralRemove={onCollateralRemove}
                        onCollateralSave={onCollateralSave}
                        hideCollateralEditor={hideCollateralEditor}
                        onFileRemove={onFileRemove}
                        onFileInputChange={onFileInputChange}
                        fileName={fileName}
                        checked={hasFile(workingIndex)}
                      />
                    }
                    else={
                      <If
                        condition={!withControls && !disableEditing}
                        do={
                          <LinkButton
                            className={cx('mt2 mb3')}
                            as="button"
                            onClick={onStartAddingCollateral}
                            data-testid="addColLink"
                          >
                            <Text id="Collateral.add" />
                          </LinkButton>
                        }
                      />
                    }
                  />

                  <If
                    condition={!!collaterals.length}
                    do={
                      <CollateralsLister
                        loanApplication={get('data.loan.loanApplication', props)}
                        collaterals={collaterals}
                        fileName={fileName}
                        onEditStart={onEditStart}
                        withNarration={withNarration}
                      />
                    }
                    else={
                      <p className="kw-text-small pl4 neutral-400">
                        <Text id="Collateral.noCollaterals" />
                      </p>
                    }
                  />
                </>
              }
              else={
                <p className="pb4 flex justify-between items-center">
                  <Text id="Collateral.disabled" />
                </p>
              }
            />
          </Expander.Body>
        </>
      )}
    </Expander.Root>
  );
}

export default {
  Component: Collaterals,
  validate
};
