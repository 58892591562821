import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import map from 'lodash/map';

import { FilterConfig } from 'FilterTypes';

import { V1SavingStates } from '@kwara/models/src/models/Saving';

import { ProductFilter } from './ProductFilter';
import { useSavingProducts } from '../../../hooks';
import { FILTER_TYPES } from '../../../components/Filter/utils/filterTypes';
import { StatusFilter } from '../../Members/components/MemberList/StatusFilter';

export function useV1Filter() {
  const { formatMessage } = useIntl();
  const { data: products = [] } = useSavingProducts();

  return useMemo<FilterConfig>(() => {
    return {
      [FILTER_TYPES.STATUS]: {
        data: [
          {
            value: '',
            name: formatMessage({ id: 'SavingList.noFilter' }),
            belongsTo: FILTER_TYPES.STATUS
          },
          ...map(V1SavingStates, value => ({
            value,
            name: formatMessage({ id: `StatusTag.${value}` }),
            belongsTo: FILTER_TYPES.STATUS
          }))
        ],
        page: StatusFilter
      },
      [FILTER_TYPES.SAVINGS_PRODUCT_ID]: {
        data: [
          {
            value: '',
            name: formatMessage({ id: 'SavingList.noFilter' }),
            belongsTo: FILTER_TYPES.SAVINGS_PRODUCT_ID
          },
          ...map(products, ({ name, id }) => ({
            value: id,
            name,
            belongsTo: FILTER_TYPES.SAVINGS_PRODUCT_ID
          }))
        ],
        page: ProductFilter
      }
    };
  }, [formatMessage, products]);
}
