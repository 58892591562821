import React from 'react';
import find from 'lodash/fp/find';
import pick from 'lodash/fp/pick';
import includes from 'lodash/fp/includes';

import { LoanProductChargeT } from '@kwara/models/src/models/v1/LoanProducts';

import { LoanAddData } from '../..';

export function useChargesTable({
  chargeOptions,
  charges,
  onChange
}: {
  chargeOptions: LoanProductChargeT[];
  charges: LoanProductChargeT[];
  onChange: (data: { charges: { chargeId: string; amount?: string }[] }) => Promise<LoanAddData>;
}) {
  const getChargeOptionById = React.useCallback(
    (id: string) => find(option => !option.penalty && option.id === id, chargeOptions),
    [chargeOptions]
  );
  const getChargeById = React.useCallback((id: string) => find(charge => charge.chargeId === id, charges), [charges]);

  const selectedCharges = charges.map(charge => {
    const option = getChargeOptionById(charge.chargeId);
    // merge the static charge attributes with the incoming "live" values,
    // so it's ready to be displayed on the table
    return { ...pick(['name', 'chargeTime', 'calculationType'], option), ...charge };
  });

  const chargeIds = charges.map(charge => charge.chargeId);

  const selectableChargeOptions = chargeOptions.filter(option => {
    return !option.penalty && !includes(option.id, chargeIds);
  });

  const onRemove = React.useCallback(
    (chargeId: string) => {
      const chargeToRemove = { ...getChargeById(chargeId) };
      chargeToRemove.isMarkedForDestruction = true;
      const updatedCharges = charges.map(charge => {
        if (charge.chargeId === chargeId) {
          return chargeToRemove;
        } else {
          return { ...charge };
        }
      });
      return onChange({ charges: updatedCharges });
    },
    [onChange, charges, getChargeById]
  );

  const onRestore = React.useCallback(
    (chargeId: string) => {
      const chargeToRestore = { ...getChargeById(chargeId) };
      chargeToRestore.isMarkedForDestruction = false;
      const updatedCharges = charges.map(charge => {
        if (charge.chargeId === chargeId) {
          return chargeToRestore;
        } else {
          return { ...charge };
        }
      });
      return onChange({ charges: updatedCharges });
    },
    [onChange, charges, getChargeById]
  );

  const onAdd = React.useCallback(
    (chargeId: string) => {
      const option = getChargeOptionById(chargeId);
      const amount = option.amount;
      const chargeToAdd = { chargeId, amount };
      const updatedCharges = [...charges, chargeToAdd];
      return onChange({ charges: updatedCharges });
    },
    [onChange, charges, getChargeOptionById]
  );

  return { selectableChargeOptions, selectedCharges, onRemove, onAdd, onRestore };
}
