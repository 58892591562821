import { GlReport, DownloadXlsxParams } from '@kwara/models/src/models/v1/GLReport/Report';

import { useWaitingCursor } from '../../../../hooks/useWaitingCursor';

export function useDownloadGlReport() {
  const { onWaitingStart, onWaitingEnd, isWaiting: isDownloading } = useWaitingCursor();

  function onDownloadXlsx(params: DownloadXlsxParams) {
    onWaitingStart();
    return GlReport.downloadXlsx(params).finally(onWaitingEnd);
  }

  return { onDownloadXlsx, isDownloading };
}
