import { useMemo } from 'react';

import { SavingType } from '@kwara/models/src/models/Saving';

import { useAuth } from '../../../../hooks';
import { ISODateString, SavingState } from '../../../../lib/types';

type FormattedSaving = {
  id: string;
  name: string;
  account: string;
  balance: number;
  interestRate: number;
  state: SavingState;
  updated: ISODateString | null;
};

export function useFormatter(model: SavingType) {
  const auth = useAuth();

  const isV1 = auth.isV1();

  return useMemo<FormattedSaving>(
    () => ({
      id: model.id,
      balance: model.balance,
      state: model.state.current,
      interestRate: model.interestObject().rate,
      name: isV1 ? model.memberName : model.member.fullName(),
      account: isV1 ? model.name : model.product.name,
      updated: isV1 ? model.createdAt : model.updatedAt
    }),
    [isV1, model]
  );
}
