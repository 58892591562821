import * as React from 'react';
import map from 'lodash/fp/map';

import { JournalEntry } from '@kwara/models/src';
import { formatIsoDate } from '@kwara/lib/src/dates';

import { financePath } from '../../lib/urls';
import Wizard from '../../components/Wizard';

import { steps } from './config';
import { useAuth } from '../../hooks';
import { store } from '../../models/Store/Store';

function legacyData({ date, credits: formCredits, debits: formDebits, ...rest }) {
  const valueDate = date ? { date: formatIsoDate(date) } : {};
  const debits = map(c => ({ ...c, debit_amount: Number(c.debit_amount) }), formDebits);
  const credits = map(c => ({ ...c, credit_amount: Number(c.credit_amount) }), formCredits);
  const data = { debits, credits, ...valueDate, ...rest };

  return data;
}

function v1Data({ date, credits: formCredits, debits: formDebits, notes }) {
  const debits = map(c => ({ gl_account_id: c.debit_account, amount: Number(c.debit_amount) }), formDebits);
  const credits = map(c => ({ gl_account_id: c.credit_account, amount: Number(c.credit_amount) }), formCredits);
  const data = {
    debits,
    credits,
    comments: notes,
    currency_code: store.currency,
    transaction_date: formatIsoDate(date)
  };

  return data;
}

const createJournalEntry = async (allData, isV1) => {
  const data = isV1 ? v1Data(allData) : legacyData(allData);
  const journalEntry = new JournalEntry(data);
  const didSave = await journalEntry.save();

  if (!didSave) throw journalEntry.errors;
};

export const JournalEntryAdd = ({ baseUrl, match }) => {
  const auth = useAuth();

  const isV1 = auth.isV1();

  return (
    <Wizard
      baseUrl={baseUrl}
      initialData={{
        credits: [{}],
        debits: [{}]
      }}
      cancelReturnsTo={financePath({ baseExtension: 'journalEntries' })}
      currentStep={match.params.step}
      currentSubStep={match.params.subStep != null ? parseInt(match.params.subStep, 10) : null}
      onSubmit={async data => {
        await createJournalEntry(data, isV1);
      }}
      steps={steps}
      startId="confirm"
      titleId="JournalEntryAdd.title"
    />
  );
};
