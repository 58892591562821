import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Form } from 'react-final-form';
import find from 'lodash/fp/find';
import get from 'lodash/fp/get';

import { LoanRemittance, LoanApplication, Loan } from '@kwara/models/src';
import createValidator from '@kwara/lib/src/validator';
import Button from '@kwara/components/src/Button';
import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import { FeeApplicationT } from '@kwara/models/src/models/LoanApplication';

import { Notes } from '../../../../../components/Notes/Notes';
import { LoanRepaymentFields } from '../../../../Loan/LoanAdd/components/LoanConfigure/LoanRepaymentFields';
import { useSaccoProfileContext } from '../../../../../models/Profile/ProfileProvider';
import { LoanDetailSection } from './components/LoanDetailSection';
import { ScheduleTable } from './components/ScheduleTable';
import { EditableForm } from './components/EditableForm';
import { useAuth } from '../../../../../hooks';

import styles from './index.module.scss';

function useValidateNotEditing(addData: (data: { isEditing: boolean }) => void) {
  const [isEditing, setIsEditing] = React.useState(false);
  React.useEffect(() => {
    addData({ isEditing });
  }, [addData, isEditing]);

  const onEditStart = () => setIsEditing(true);
  const onEditEnd = () => setIsEditing(false);

  return { onEditStart, onEditEnd };
}

export type LoanDetailLocationStateType = {
  shouldModify?: boolean;
};

type FormProps = {
  repaymentPeriod: string;
  repaymentPeriodUnit: 'MONTH' | 'WEEKS' | 'DAYS';
  loanDuration: string;
  remittance: {
    amount: string;
    method: string;
  };
  feeApplications: FeeApplicationT[];
};

type ButtonBarProps = {
  onEditStart: () => void;
  isEditView: boolean;
  onDiscardChanges: () => void;
  onSave: () => void;
  submitting: boolean;
  valid: boolean;
};

function ButtonBar({ onEditStart, isEditView, onDiscardChanges, onSave, submitting, valid }: ButtonBarProps) {
  return (
    <div className={styles.button_bar}>
      <If
        condition={isEditView}
        do={
          <>
            <Button className="mr3" onClick={onDiscardChanges}>
              <Text id="LoanAppraisal.LoanDetails.button.discard" />
            </Button>
            <Button
              disabled={submitting || !valid}
              type="primary"
              onClick={onSave}
              glyphRightId={submitting ? Button.Glyphs.Spinner : null}
            >
              <Text id="LoanAppraisal.LoanDetails.button.save" />
            </Button>
          </>
        }
        else={
          <Button glyphRightId="Pencil" onClick={onEditStart}>
            <Text id="LoanAppraisal.LoanDetails.button.modify" />
          </Button>
        }
      />
    </div>
  );
}

export function LoanDetails(props) {
  const history = useHistory();
  const { state, pathname } = useLocation<LoanDetailLocationStateType>();
  const store = useSaccoProfileContext();
  const auth = useAuth();

  const { formProps } = props;
  const loan = props.data.loan;
  const remittance = loan.remittance || LoanRemittance.create();
  const loanApplication = loan.loanApplication || LoanApplication.create();
  const onChange = props.onChange;
  const isV1 = auth.isV1();

  const { onEditStart, onEditEnd } = useValidateNotEditing(props.addData);
  const [isEditView, setIsEditView] = React.useState<boolean>(() => !!state?.shouldModify);
  function onEdit() {
    onEditStart();
    setIsEditView(true);
  }

  function resetLocationState() {
    history.replace(pathname);
  }

  function onCloseEdit() {
    onEditEnd();
    setIsEditView(false);
    resetLocationState();
  }

  async function onSubmit({
    repaymentPeriod,
    repaymentPeriodUnit,
    loanDuration,
    remittance: formRemittance,
    feeApplications: formFeeApplications
  }: FormProps) {
    loan.repaymentPeriod = repaymentPeriod;
    loan.repaymentPeriodUnit = repaymentPeriodUnit;
    loan.loanDuration = loanDuration;

    remittance.amount = formRemittance.amount;
    remittance.method = formRemittance.method;

    const memberBankAccounts = get('formProps.values.bankAccounts', props);
    const bankAccountId = get('bankAccount.id', formRemittance);
    const selectedBankAccount = find(account => account.id === bankAccountId, memberBankAccounts);

    const collectingBank = store.getBank(get('collectingBank.id', formRemittance));

    remittance.bankAccount = selectedBankAccount;
    remittance.collectingBank = collectingBank;

    loanApplication.loanApplicationFees = formFeeApplications;

    if (!loan.loanApplication) {
      loan.loanApplication = loanApplication;
    }

    if (!loan.remittance) {
      loan.remittance = remittance;
    }

    const didSave = await loan.save({
      with: { loanApplication: ['loanApplicationFees'], remittance: ['bankAccount', 'collectingBank'] },
      returnScope: Loan.includes('loan_application')
    });

    if (!didSave) {
      throw loan.errors;
    }

    await onChange({ loan: loan.deserialize() });
    onCloseEdit();
  }

  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={createValidator(LoanRepaymentFields.validate)}
        initialValues={{
          repaymentPeriod: loan.repaymentPeriod,
          repaymentPeriodUnit: loan.repaymentPeriodUnit,
          loanDuration: loan.loanDuration,
          remittance: loan.remittance,
          feeApplications: get('loanApplication.loanApplicationFees', loan) || []
        }}
        render={formProps => {
          const { form, handleSubmit, submitting, valid } = formProps;
          return (
            <div className="relative">
              <If
                condition={!isV1}
                do={
                  <ButtonBar
                    isEditView={isEditView}
                    onEditStart={onEdit}
                    onDiscardChanges={() => {
                      onCloseEdit();
                      form.reset();
                    }}
                    onSave={handleSubmit}
                    submitting={submitting}
                    valid={valid}
                  />
                }
              />
              <If
                condition={!isEditView}
                do={
                  <>
                    <LoanDetailSection {...props} />
                    <ScheduleTable {...props} />
                  </>
                }
                else={<EditableForm {...props} formProps={formProps} />}
              />
            </div>
          );
        }}
      />
      <If
        condition={!isV1}
        do={<Notes name="notes.loan_details.no_section" className="mt3" formValues={formProps.values} />}
      />
    </>
  );
}

export default {
  Component: LoanDetails,
  validate: {
    isEditing: {
      custom: (isEditing: boolean) => !isEditing
    }
  }
};
