import React from 'react';

import Modal from '@kwara/components/src/Modal';

import { Text } from '@kwara/components/src/Intl';
import { ModelErrorBanner } from '@kwara/components/src/ModelErrorBanner';

import { Panel } from '../../ActionModal';
import { TransactionForm } from './TransactionForm';
import { TransactionDetails } from './TransactionDetails';
import { TransactionType, TransactionModelType, useSubmit } from './useSubmit';
import { AutoDismissableCompletion, Types as CompletionTypes } from '../../Completion';

import styles from './index.module.scss';

type TransactionAdjustModalProps = {
  transaction: TransactionType;
  TransactionModel: TransactionModelType;
  onClose(): void;
  onSuccess(): void;
};

export function TransactionAdjustModal({
  transaction,
  onClose,
  onSuccess,
  TransactionModel
}: TransactionAdjustModalProps) {
  const { success, errors, onSubmit } = useSubmit(TransactionModel, onSuccess);

  if (!transaction) return null;

  return (
    <Modal isOpen overlayClassName={styles['overlay']}>
      <div className="flex w-100 h-100 items-center justify-center">
        <Panel>
          <ModelErrorBanner errors={errors} />
          {success ? (
            <AutoDismissableCompletion onConfirm={onClose} type={CompletionTypes.good} />
          ) : (
            <div className={styles['container']}>
              <h2 className="mb0 kw-text-large kw-weight-bold neutral-900">
                <Text id="MemberDetail.Transactions.Subsection.Actions.Adjust.Modal.title" />
              </h2>
              <TransactionDetails transaction={transaction} />
              <TransactionForm transaction={transaction} onSubmit={onSubmit} onClose={onClose} />
            </div>
          )}
        </Panel>
      </div>
    </Modal>
  );
}
