import * as React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import set from 'lodash/set';
import get from 'lodash/fp/get';
import some from 'lodash/fp/some';

import { NextOfKin as NextOfKinModel, NextOfKinT } from '@kwara/models/src/models/NextOfKin';
import { phoneNumber as phoneNumberValidator } from '@kwara/lib/src/validator/records';
import { VALID } from '@kwara/lib/src/validator';

import { Text } from '@kwara/components/src/Intl';
import { Field as BasicField } from '@kwara/components/src/Form';
import { LinkButton } from '@kwara/components/src/LinkButton';
import { Render } from '@kwara/components/src/Render/Render';
import { ComponentProps } from '@kwara/components/src/Wizard/latest/components/SubStep/SubStep';

import { Row } from './components/Row';

export interface NextOfKinProps extends ComponentProps<{ nextOfKins: Array<NextOfKinT> }> {
  size?: string;
  name?: string;
}

export function NextOfKin({
  data,
  StackChild,
  SelectField,
  TextField,
  PhoneField,
  onChange,
  size = 'widest',
  name = 'nextOfKins'
}: NextOfKinProps) {
  const getShape = val => set({}, name, val);

  return (
    <StackChild size={size}>
      <BasicField labelId="AddMember.NextOfKin.label">
        <FieldArray name={name}>
          {({ fields }) => (
            <div aria-label="Next of Kin">
              <Render
                condition={some(kin => !kin.isMarkedForDestruction, fields.value)}
                fallbackId="AddMember.NextOfKin.empty.label"
              >
                {fields.map((name, index) => {
                  if (fields.value[index].isMarkedForDestruction) {
                    return null;
                  }
                  return (
                    <Row
                      data={data}
                      key={name}
                      name={name}
                      index={index}
                      TextField={TextField}
                      SelectField={SelectField}
                      PhoneField={PhoneField}
                      onRemove={() => {
                        const formKin = fields.value[index];
                        const kin = formKin.isSpraypaintInstance ? formKin.dup() : formKin;
                        kin.isMarkedForDestruction = true;
                        fields.update(index, kin);
                      }}
                    />
                  );
                })}
              </Render>
              <LinkButton
                as="button"
                onClick={e => {
                  e.preventDefault();
                  const nextOfKin = new NextOfKinModel({});
                  fields.push(nextOfKin);
                  onChange(getShape([...fields.value, nextOfKin]));
                }}
              >
                <Text id="AddMember.NextOfKin.addKin.button" />
              </LinkButton>
            </div>
          )}
        </FieldArray>
      </BasicField>
    </StackChild>
  );
}

export function nextOfKinKey(index: number) {
  return `nextOfKins[${index}]`;
}

export function nameKey(index: number) {
  return `${nextOfKinKey(index)}.name`;
}

export function relationshipKey(index: number) {
  return `${nextOfKinKey(index)}.relationship`;
}

export function phoneNumberKey(index: number) {
  return `${nextOfKinKey(index)}.phoneNumber`;
}

export function isMarkedForDestruction(index: number, allData) {
  return get(`${nextOfKinKey(index)}.isMarkedForDestruction`, allData);
}

NextOfKin.validate = ({ nextOfKins }) => {
  const validations = {};

  if (nextOfKins != null) {
    nextOfKins.forEach((_, index: number) => {
      validations[nameKey(index)] = {
        isRequired(_, allData) {
          return !isMarkedForDestruction(index, allData);
        }
      };
      validations[relationshipKey(index)] = {
        isRequired(_, allData) {
          return !isMarkedForDestruction(index, allData);
        }
      };
      validations[phoneNumberKey(index)] = {
        isRequired(_, allData) {
          return !isMarkedForDestruction(index, allData);
        },
        custom(phoneNumber, allData) {
          if (isMarkedForDestruction(index, allData)) {
            return null;
          }

          if (phoneNumberValidator(phoneNumber) === VALID) {
            return null;
          }

          return phoneNumberValidator(phoneNumber);
        }
      };
    });
  }

  return validations;
};
