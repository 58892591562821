import React from 'react';
import get from 'lodash/fp/get';
import size from 'lodash/fp/size';
import getOr from 'lodash/fp/getOr';
import keyBy from 'lodash/fp/keyBy';
import filter from 'lodash/fp/filter';

import { FieldArray } from 'react-final-form-arrays';

import Button from '@kwara/components/src/Button';

import { Text } from '@kwara/components/src/Intl';
import { Link } from '@kwara/components/src/Link';
import { LoanType } from '@kwara/models/src/models/Loan';
import { FeeTriggersMap } from '@kwara/models/src/models/LoanProduct';

import { Panel } from '../../components/ActionModal';
import { FeeAmount, FeesList } from '../../components/FeesPanel';

type FeesPropTypes = {
  loan: LoanType;
};

export function Fees({ loan }: FeesPropTypes) {
  const { fees: allFees = [] } = loan.product || {};
  const fees = filter(fee => fee.trigger === FeeTriggersMap.MANUAL, allFees);

  if (size(fees) < 1) return null;

  const feesMap = keyBy('id', fees);

  return (
    <Panel>
      <FieldArray name="fees">
        {({ fields }) => (
          <div aria-label="Fees" key={fields.name}>
            {fields.map((name, index) => {
              const fieldValue = get(['value', index], fields);
              const thisFeeId = getOr('', 'id', fieldValue);
              const thisFee = get(thisFeeId, feesMap);
              const isRequiredFee = false;

              return (
                <div key={name} aria-label="Fee" className="bb b--light-grey-400 mb2">
                  <FeesList name={name} thisFee={thisFee} isRequiredFee={isRequiredFee} optionalFees={fees} />
                  <FeeAmount feesMap={feesMap} thisFee={thisFee} name={name} thisFeeId={thisFeeId} />
                  <div className="mb4 mt1">
                    <Link
                      aria-label="Remove"
                      type="destructive"
                      disabled={isRequiredFee}
                      onClick={() => fields.remove(index)}
                    >
                      <Text id="PaymentForm.fee.remove" />
                    </Link>
                  </div>
                </div>
              );
            })}
            <Button onClick={() => fields.push({})}>
              <Text id="PaymentForm.fee.add" />
            </Button>
          </div>
        )}
      </FieldArray>
    </Panel>
  );
}
