import { useCallback } from 'react';

import { formatIsoDate } from '@kwara/lib/src/dates';

import { LoanPayOffData } from '.';
import { useAuth } from '../../hooks';

export function usePayoffLoan() {
  const auth = useAuth();

  const payOffLegacyLoan = async ({
    loan,
    method,
    notes,
    bankGlId,
    bankBranch,
    bankName,
    accountNumber,
    chequeNumber,
    drawer,
    fees,
    accountId: depositAccount
  }: LoanPayOffData) => {
    const didSave = await loan.payOff({
      method,
      notes,
      bankGlId,
      bankName,
      bankBranch,
      accountNumber,
      chequeNumber,
      drawer,
      depositAccount,
      fees
    });

    if (!didSave) throw loan.errors;
  };

  const payOffV1Loan = async ({ loan, notes, date, paymentMethodId }: LoanPayOffData) => {
    const didSave = await loan.v1PayOff({ notes, transactionDate: formatIsoDate(date), paymentMethodId });

    if (!didSave) throw loan.errors;
  };

  const payOffLoan = useCallback(
    (data: LoanPayOffData) => (auth.isV1() ? payOffV1Loan(data) : payOffLegacyLoan(data)),
    [auth]
  );

  return payOffLoan;
}
