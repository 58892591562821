import React from 'react';
import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';

import { Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import { GlAccountSelect } from '@kwara/components/src/Form';
import { TransactionChannels } from '@kwara/models/src/models/Transactions';

import MemberPanel from '../../components/Payment/MemberPanel';

import { Fees } from './Fees';
import { useAuth } from '../../hooks';
import { Grid } from '../../components/Grid';
import { Currency } from '../../components/Currency';
import { Panel } from '../../components/ActionModal';
import { LoanPayOffPropTypes, LoanPayOffData } from '.';
import { LegacyPaymentMethod } from './LegacyPaymentMethod';
import { SubscribedDatePicker, Field } from '../../components/Form';
import { V1PaymentMethod } from '../../components/v1/V1PaymentMethod';
import { useSaccoProfileContext } from '../../models/Profile/ProfileProvider';

const showAccountSelectionFor = [TransactionChannels.fosa];

export function PayOffForm(props: LoanPayOffPropTypes) {
  const {
    StackChild,
    TextField,
    TextArea,
    data: { loan }
  } = props;

  const store = useSaccoProfileContext();
  const auth = useAuth();

  const isV1 = auth.isV1();
  const product = get('product', loan);

  return (
    <StackChild>
      <MemberPanel member={loan.member} />

      <Panel>
        <Statistic
          size="medium"
          containerClassName="mb2"
          title={<Text id="LoanPayOff.Confirm.Account" />}
          value={`${loan.id} - ${product.name}`}
        />
        <Grid columns={2} width="w-50" border={false}>
          <Statistic
            title={<Text id="LoanPayOff.Confirm.PrincipalBalance" />}
            value={<Currency value={loan.principal.balance} />}
            compact
          />
          <Statistic
            title={<Text id="LoanPayOff.Confirm.InterestBalance" />}
            value={<Currency value={loan.interest.balance} />}
            compact
          />
          <Statistic
            title={<Text id="LoanPayOff.Confirm.FeesBalance" />}
            value={<Currency value={loan.fees.balance} />}
            compact
          />
          <Statistic
            title={<Text id="LoanPayOff.Confirm.PenaltiesBalance" />}
            value={<Currency value={loan.penalties.balance} />}
            compact
          />
          {store.accruedInterestEnabled ? (
            <Statistic
              title={<Text id="LoanPayOff.Confirm.AccruedInterest" />}
              value={<Currency value={loan.accruedInterest} />}
              compact
            />
          ) : null}
        </Grid>
      </Panel>

      <Panel>
        <Grid columns={2} width="w-50" border={false}>
          <TextField name="amount" labelId="LoanPayOff.Confirm.amount.label" type="number" disabled isCurrency />
          <Field name="date" size="medium" labelId="LoanPayOff.Confirm.date.label" required disabled>
            <SubscribedDatePicker name="date" disabled />
          </Field>
        </Grid>
        {isV1 ? <V1PaymentMethod /> : <LegacyPaymentMethod {...props} />}
      </Panel>

      <Fees loan={loan} />

      <Panel>
        <TextArea name="notes" labelId="LoanPayOff.Confirm.notes.label" />
      </Panel>
    </StackChild>
  );
}

PayOffForm.validate = {
  method: {
    isRequired: (_: string, { isV1 }: LoanPayOffData) => !isV1
  },
  paymentMethodId: {
    isRequired: (_: string, { isV1 }: LoanPayOffData) => isV1
  },
  accountId: {
    isRequired: (_: string, allData: LoanPayOffData) => {
      return includes(allData.method, showAccountSelectionFor);
    }
  },
  ...GlAccountSelect.validate
};
