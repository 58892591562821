export const ROUTES = Object.freeze({
  login: '/',
  home: '/',
  members: '/',
  confirm: '/confirm',
  till: '/till',
  loans: '/loans',
  debug: '/debug',
  notFound404: '*',
  unlock: '/unlock',
  success: '/success',
  tellers: '/tellers',
  savings: '/savings',
  welcome: '/welcome',
  confirmEmail: '/confirm',
  '2faActivation': '/auth/2fa',
  smsRenew: '/settings/sms/renew',
  creditSubmit: '/credit/:action?',
  smsTriggersEdit: '/settings/sms/edit',
  passwordReset: '/password/reset',
  forgetPassword: '/password/forgot',
  loanRoutes: '/loans/:loanId',
  tillSessionDetail: '/tellers/:tillSessionId',
  tillsManagement: '/tillsManagement',
  utilityReceipt: '/till/receipt',
  utilityPayment: '/till/payment',
  invitationRedeem: '/invitation',
  savingsRoutes: '/savings/:savingId',
  memberRoutes: '/members/:memberId',
  loanAdd: '/loans/create/:step?/:subStep?',
  topupRequest: '/topup/new/:step?/:subStep?',
  tillToVaultTransfer: '/tillToVault/new/:step?/:subStep?',
  savingAdd: '/savings/create/:step?/:subStep?',
  memberAdd: '/members/create/:step?/:subStep?',
  topupReview: '/topup/:topupId/:step?/:subStep?',
  reconciliationReportIndex: '/finance/reconciliations',
  reconciliationReportCreate: '/finance/reconciliations/create',
  appRoleAdd: '/settings/roles/new/:step?/:subStep?',
  transactionDetail: '/transactions/:transactionId',
  memberEdit: '/members/:memberId/edit/:step?/:subStep?',
  loanProductAdd: '/settings/loans/new/:step?/:subStep?',
  userEdit: '/settings/team/:userId/edit/:step?/:subStep?',
  inviteUser: '/settings/workspace/invite/:step?/:subStep?',
  savingProductAdd: '/settings/saving/new/:step?/:subStep?',
  batchTransactionImports: '/batch_transaction_imports/:id?',
  appRoleEdit: '/settings/roles/:roleId/edit/:step?/:subStep?',
  sasraReports: '/sasra/reports',
  guarantorListingReports: '/operational/reports/guarantorListings',
  loanAgeingReports: '/operational/reports/loanAgeing',
  loanListingReports: '/operational/reports/loanListing',
  depositListingReports: '/operational/reports/depositListings',
  checkOffAdviceReports: '/operational/reports/checkOffAdvice',
  memberListingReports: '/operational/reports/memberListing',
  sasraReport4BGenerate: '/finance/sasra/generate/:step?/:subStep?',
  sasraReportTemplates: '/sasra/config',
  sasraReportConfig: '/sasra/config/:reportTemplateId',
  batchTransactionImportsCreate: '/batch_transaction_imports/add',
  journalEntryAdd: '/finance/journalEntries/new/:step?/:subStep?',
  directDebitReports: '/finance/reports/generate/:step?/:subStep?',
  dividendReportGenerate: '/finance/dividends/reports/dividend/generate/:step?/:subStep?',
  interestReportGenerate: '/finance/dividends/reports/interest/generate/:step?/:subStep?',
  memberReportGenerate: '/settings/data/generate/:step?/:subStep?',
  loanProductEdit: '/settings/loans/:productId/edit/:step?/:subStep?',
  savingProductEdit: '/settings/saving/:productId/edit/:step?/:subStep?',
  loanSchedulePreview: '/settings/loans/:productId/schedule/:step?/:subStep?',
  memberDetail: '/members/:memberId',
  MemberDeposit: '/members/:memberId/deposit',
  MemberRepayment: '/members/:memberId/repayment',
  memberWithdrawal: '/members/:memberId/withdrawal',
  memberStatementGenerate: '/members/:memberId/statement/:step?/:subStep?',
  MemberClose: '/members/:memberId/close/:step?/:subStep?',
  memberApprove: '/members/:memberId/approve/:step?/:subStep?',
  savingFee: '/savings/:savingId/fee',
  savingDetail: '/savings/:savingId',
  savingDeposit: '/savings/:savingId/deposit',
  savingWithdraw: '/savings/:savingId/withdrawal',
  savingStatementGenerate: '/savings/:savingId/statement/:step?/:subStep?',
  savingClose: '/savings/:savingId/close/:step?/:subStep?',
  makeTransfer: '/savings/:savingId/transfer/:step?/:subStep?',
  loanDetail: '/loans/:loanId',
  loanFee: '/loans/:loanId/fee',
  loanRepayment: '/loans/:loanId/repayment',
  loanDisbursal: '/loans/:loanId/disbursal/:step?/:subStep?', // New
  loanStatementGenerate: '/loans/:loanId/statement/:step?/:subStep?',
  loanPayOff: '/loans/:loanId/payOff/:step?/:subStep?',
  loanTopup: '/loans/:loanId/topup/:step?/:subStep?',
  undoTopup: '/loans/:loanId/undoTopup/:step?/:subStep?',
  loanTopupDisbursal: '/loans/:loanId/topupDisbursal/:step?/:subStep?',
  loanAppraisal: '/loans/:loanId/appraisal/:step?/:subStep?',
  loanWriteOff: '/loans/:loanId/writeoff/:step?/:subStep?',
  loanRefinance: '/loans/:loanId/refinance/:step?/:subStep?',
  loanReschedule: '/loans/:loanId/reschedule/:step?/:subStep?',
  dashboard: '/dashboard',
  credit: '/credit',
  allBatchTransactionImports: '/batch_transaction_imports',
  allSettings: '/settings',
  allFinance: '/finance',
  financeJournalEntriesNewConfirm: '/finance/journalEntries/new/confirm',
  financeReconciliationsCreate: '/finance/reconciliations/create',
  financeReportsGenerateConfirm: '/finance/reports/generate/confirm',
  financeSasraGenerateConfirm: '/finance/sasra/generate/confirm',
  reports: '/reports',
  accountingReports: '/accounting/reports',
  accountingReportView: '/accounting/reports/:reportId',
  dividendReportsView: '/finance/dividends/reports/:reportId/contributions',
  financeSavingTransactions: '/finance/savingTransactions',
  financeLoanTransactions: '/finance/loanTransactions',
  financeLoanDisbursementTransactions: '/finance/loanDisbursementTransactions',
  financeC2b: '/finance/c2b',
  financeB2c: '/finance/b2c',
  financeStk: '/finance/stk',
  financeReports: '/finance/reports',
  financeLoanDisbursementReport: '/finance/loanDisbursementReport',
  financeChequeReports: '/finance/chequeReports',
  financeDividends: '/finance/dividends',
  financeJournalEntries: '/finance/journalEntries',
  financeReconciliations: '/finance/reconciliations',
  financeSasra: '/finance/sasra',

  settingsGeneral: '/settings/general',
  settingsLoans: '/settings/loans',
  settingsWorkspace: '/settings/workspace',
  settingsActivity: '/settings/activity',
  settingsTeam: '/settings/team',
  settingsEvents: '/settings/events',
  settingsRolesPermissions: '/settings/rolesPermissions',
  settingsSmsSettings: '/settings/smsSettings',
  settingsManageCredentials: '/settings/manageCredentials',
  settingsSavings: 'settings/savings',
  settingsData: '/settings/data',
  v1: {
    accounting: {
      journalEntries: '/v1/accountingReports/journalEntries'
    },
    accountingReports: {
      glReport: '/v1/accountingReports/glReport',
      trialBalance: '/v1/accountingReports/trialBalance',
      profitLoss: '/v1/accountingReports/profitLoss',
      balanceSheet: '/v1/accountingReports/balanceSheet'
    }
  }
});
