import React from 'react';

import { HttpErrorObject } from 'GlobalTypes';

import ScrollIntoFocus from '@kwara/components/src/ScrollIntoFocus';

import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import { Loadable } from '@kwara/components/src/Loadable';
import { SchedulePreviewT } from '@kwara/models/src/models/SchedulePreview';
import { FullPageLoader } from '@kwara/components/src/Loadable/FullPageLoader';

import { hasErrors } from '../../../hooks';
import { Schedule as ScheduleTable } from '../../LoanDetail/components/Schedule';

type ScheduleProps = {
  isLoading: boolean;
  error: HttpErrorObject[] | {};
  schedule: SchedulePreviewT | null;
};

export function Schedule({ isLoading, error, schedule }: ScheduleProps) {
  return (
    <If
      condition={schedule || isLoading || hasErrors(error)}
      do={
        <div className="mt3 mb3">
          <If condition={!!schedule} do={<Text id="LoanSchedulePreview.Table.title" />} />
          <Loadable loading={<FullPageLoader />} isLoading={isLoading} data={schedule} error={error}>
            {scheduleData => (
              <ScrollIntoFocus>
                <ScheduleTable schedule={scheduleData} initiallyOpen />
              </ScrollIntoFocus>
            )}
          </Loadable>
        </div>
      }
    />
  );
}
