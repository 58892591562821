import React from 'react';
import cx from 'classnames';

import { Text, Date, Currency } from '@kwara/components/src/Intl';

import { TransactionType } from './useSubmit';

import styles from './index.module.scss';

type TransactionDetailsProps = {
  transaction: TransactionType;
};

export function TransactionDetails({ transaction }: TransactionDetailsProps) {
  return (
    <div className={cx(styles['items'], 'neutral-900')} aria-label="Transaction Details">
      <div className="flex flex-column">
        <div className="kw-text-medium">
          <Text id="MemberDetail.Transactions.Subsection.Actions.Adjust.Modal.deposit" />
        </div>
        <div className="mt1 kw-text-large kw-weight-bold">
          <Currency value={transaction.amount} format="currency" />
        </div>
      </div>
      <div className="flex flex-column">
        <div className="kw-text-medium">
          <Text id="MemberDetail.Transactions.Subsection.Actions.Adjust.Modal.date" />
        </div>
        <div className="mt1 kw-text-large kw-weight-bold">
          <Date value={transaction.createdAt} />
        </div>
      </div>
    </div>
  );
}
