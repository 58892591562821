import React from 'react';
import find from 'lodash/fp/find';
import { LoanType } from '@kwara/models/src';

export function useRepaymentForm({ accounts, accountId, loan }) {
  const selectedAccount = React.useMemo(() => loan || find<LoanType>({ id: accountId }, accounts), [
    accounts,
    accountId,
    loan
  ]);

  return { selectedAccount };
}
