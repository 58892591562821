import * as React from 'react';

import { NamedRoute } from '@kwara/components/src/Route';

import * as pages from '../../../pages';
import { ROUTES } from '../../utils/routes';
import { usePermissions, useAuth } from '../../../hooks';
import { PermittedRoute } from '../PermittedRoute/PermittedRoute';

type MemberRoutesParamType = {
  memberId: string;
};

export function MemberRoutes() {
  const { AppPermissions } = usePermissions();

  const auth = useAuth();
  const isV1 = auth.isV1();

  return (
    <>
      <PermittedRoute<MemberRoutesParamType>
        exact
        name="MemberStatementGenerate"
        permission={AppPermissions.ViewMembers}
        path={ROUTES.memberStatementGenerate}
        render={props => (
          <pages.MemberStatementGenerate baseUrl={`/members/${props.match.params.memberId}/statement`} {...props} />
        )}
      />
      <PermittedRoute<MemberRoutesParamType>
        exact
        name="MemberClose"
        permission={AppPermissions.ExitMembers}
        path={ROUTES.MemberClose}
        render={props => <pages.MemberClose baseUrl={`/members/${props.match.params.memberId}/close`} {...props} />}
      />
      <NamedRoute
        exact
        name="MemberApprove"
        path={ROUTES.memberApprove}
        render={props => <pages.MemberApprove baseUrl={`/members/${props.match.params.memberId}/approve`} {...props} />}
      />
      <PermittedRoute
        exact
        name="MemberWithdrawal"
        permission={AppPermissions.AddWithdrawal}
        path={ROUTES.memberWithdrawal}
        render={props => (isV1 ? <pages.MemberWithdrawalV1 {...props} /> : <pages.MemberWithdrawal {...props} />)}
      />
      <PermittedRoute
        exact
        name="MemberRepayment"
        permission={AppPermissions.AddRepayments}
        path={ROUTES.MemberRepayment}
        render={props => (isV1 ? <pages.MemberRepaymentV1 {...props} /> : <pages.MemberRepayment {...props} />)}
      />
      <PermittedRoute
        exact
        name="MemberDeposit"
        permission={AppPermissions.AddDeposits}
        path={ROUTES.MemberDeposit}
        render={props => (isV1 ? <pages.MemberDepositV1 {...props} /> : <pages.MemberDeposit {...props} />)}
      />
      {/********************************************************
       * @MemberDetailRoute is a Component that decides
       * whether to redirect to MemberApprove or MemberDetail
       *********************************************************/}
      <PermittedRoute
        permission={AppPermissions.ShowMember}
        name="MemberDetail"
        path={ROUTES.memberDetail}
        render={props => <pages.MemberDetailRoute {...props} />}
      />
    </>
  );
}
