import React from 'react';
import find from 'lodash/fp/find';

import { SavingType } from '@kwara/models/src';

function findMaximumAmount(account: SavingType) {
  return account.balance;
}

export function useWithdrawalForm({ accounts, accountId, saving }) {
  const selectedAccount = React.useMemo(() => {
    return saving || find<SavingType>({ id: accountId }, accounts);
  }, [accounts, accountId, saving]);

  const maximumAmount = findMaximumAmount(selectedAccount);

  return { selectedAccount, maximumAmount };
}
