import React from 'react';

import { Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import { SubscribedTextField as TextField, SubscribedDatePicker as DatePicker } from '@kwara/components/src/Form';
import { formatHumanDate, getCurrentDate } from '@kwara/lib/src/dates';
import { PaymentMethodT, SavingType, LoanType, PaymentMethodGlLinkT } from '@kwara/models/src';

import { Grid } from '../../Grid';
import { Panel } from '../../ActionModal';

import { AccountSelector } from './components/AccountSelector';
import { PaymentMethodSelector } from './components/PaymentMethodSelector';
import { GlLinkSelector } from './components/GlLinkSelector';

export function SubscribedTransactionForm({
  accounts,
  selectedAccount,
  glLinks,
  paymentMethods,
  hasAccountSelection,
  maximumAmount,
  addData
}: {
  accounts: (SavingType | LoanType)[];
  paymentMethods: PaymentMethodT[];
  glLinks?: PaymentMethodGlLinkT[];
  selectedAccount?: SavingType | LoanType;
  hasAccountSelection: boolean;
  maximumAmount?: number;
  addData?: (p: any) => void;
}) {
  return (
    <Panel>
      <div className="bb b--light-grey-400 mb4">
        {hasAccountSelection ? <AccountSelector name="accountId" accounts={accounts} /> : null}

        {!hasAccountSelection && selectedAccount ? (
          <Statistic
            size="medium"
            title={<Text id="PaymentForm.account" />}
            value={`${selectedAccount.id} — ${selectedAccount.name || selectedAccount.product.name}`}
          />
        ) : null}
      </div>
      <Grid columns={2} width="w-50" border={false}>
        <TextField
          required
          isCurrency
          name="amount"
          type="number"
          step="0.01"
          size="medium"
          leftGlyph="Currency.orgCurrency"
          labelId="PaymentForm.amount"
          info={maximumAmount ? <Text id="SavingPayment.maximumAmountInfo" values={{ maximumAmount }} /> : null}
        />
        <DatePicker
          name="valueDate"
          size="medium"
          labelId="SavingPayment.valueDate"
          inputPlaceholder={formatHumanDate(getCurrentDate())}
          disabledDays={{
            after: getCurrentDate()
          }}
        />
      </Grid>
      <PaymentMethodSelector name="method" methods={paymentMethods} onChange={() => addData({ glLinkId: '' })} />
      <GlLinkSelector name="glLinkId" glLinks={glLinks} />
      <TextField name="reference" size="medium" labelId="PaymentForm.reference" infoId="PaymentForm.reference.info" />
    </Panel>
  );
}

SubscribedTransactionForm.defaultValidation = {
  amount: {
    isRequired: () => true
  },
  method: {
    isRequired: () => true
  },
  valueDate: {
    isRequired: () => true
  }
};
