// @flow
import * as React from 'react';

import { Loan } from '@kwara/models/src';
import { Duration, Percent, Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';
import { PrincipalAmountField } from '@kwara/components/src/pages/LoanAdd/components/Configure/PrincipalAmountField';
import { LoanTermsFields } from '@kwara/components/src/pages/LoanAdd/components/Configure/LoanTermsFields';

import { memberPath } from '../../../lib/urls';
import { EditableSection } from '../../../components/EditableSection/v1/EditableSection';
import { DetailOverviewSection } from '../../../components/Detail/DetailOverviewSection';
import { OverviewMetadata } from './OverviewMetadata';
import { Currency } from '../../../components/Currency';

import { usePermissions } from '../../../hooks';

export type Props = {
  actions: React.Node,
  loan: typeof Loan,
  navigation: React.Node,
  showBack: boolean,
  linkToMember: boolean,
  readOnly?: boolean
};

const config = {
  Component: props => (
    <div>
      <PrincipalAmountField {...props} />
      <LoanTermsFields {...props} />
    </div>
  ),
  validate: { ...PrincipalAmountField.validate, ...LoanTermsFields.validate }
};

export const Overview = (props: Props) => {
  const { loan, navigation, linkToMember, readOnly: readOnlyProp } = props;

  const { permission, AppPermissions } = usePermissions();

  const readOnly = readOnlyProp || !permission.to(AppPermissions.EditLoanDetails);

  return (
    <DetailOverviewSection
      metadata={<OverviewMetadata {...props} />}
      secondary={navigation}
      title={<Text id="LoanDetail.title" values={{ id: loan.id }} />}
      ariaLabel="Loan Metrics"
    >
      <section className="mb5">
        <EditableSection config={config} initialData={loan} readOnly={readOnly}>
          <StatisticRow widthClass="w-50">
            <Statistic
              title={<Text id="LoanDetail.Overview.principalAmount" />}
              value={<Currency value={loan.amount} />}
              color={'red-600'}
              size={'5x-large'}
            />

            <Statistic
              title={<Text id="LoanDetail.Overview.totalBalance" />}
              value={<Currency value={loan.totalBalance} />}
              size={'5x-large'}
            />
          </StatisticRow>
          <StatisticRow border={true} widthClass="w-50">
            <Statistic
              data-testid="LoanDetail.interestAmount"
              title={<Text id="LoanDetail.Overview.totalPaid" />}
              value={<Currency value={loan.totalPaid} />}
              size={'5x-large'}
            />
            <Statistic
              title={<Text id="LoanDetail.Overview.totalDue" />}
              value={<Currency value={loan.totalDue} />}
              size={'5x-large'}
            />
          </StatisticRow>
          <StatisticRow border={true} widthClass="w-25">
            <Statistic title={<Text id="LoanDetail.Overview.loanProduct" />} value={loan.name} size={'large'} />
            <Statistic
              data-testid="LoanDetail.interestRate"
              title={<Text id="LoanDetail.Overview.interestRate" />}
              value={<Percent value={loan.interestRatePercent()} />}
              size={'large'}
            />
            <Statistic
              title={<Text id="LoanDetail.Overview.duration" />}
              value={<Duration value={loan.duration} />}
              size={'large'}
            />
            <Statistic
              title={<Text id="LoanDetail.Overview.frequency" />}
              value={<Duration value={loan.repaymentFrequency} />}
              size="large"
            />
          </StatisticRow>
          <StatisticRow border={true} widthClass="w-50">
            <Statistic
              title={<Text id="LoanDetail.Overview.borrowerId" />}
              value={loan.member && loan.member.id}
              size="large"
              linkTo={linkToMember && loan.member ? memberPath({ id: loan.member.id }) : undefined}
            />
            <Statistic
              title={<Text id="LoanDetail.Overview.borrower" />}
              value={loan.member && loan.member.fullName()}
              size="large"
              linkTo={linkToMember && loan.member ? memberPath({ id: loan.member.id }) : undefined}
            />
          </StatisticRow>
        </EditableSection>
      </section>
    </DetailOverviewSection>
  );
};
