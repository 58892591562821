import React from 'react';

import MemberPanel from '../../../../../components/Payment/MemberPanel';
import NotesPanel from '../../../../../components/Payment/NotesPanel';

import { SubscribedTransactionForm } from '../../../../../components/v1/PaymentForm/SubscribedTransactionForm';
import { SubStepComponentProps } from '../../../../../components/Wizard';

import { useTransactionForm } from '../../../../../components/v1/PaymentForm/useTransactionForm';

import { useDepositForm } from '../../useDepositForm';

export function DepositFormV1({ StackChild, data, addData, formProps }: SubStepComponentProps<FormData>) {
  const { values } = formProps;
  const { accountId } = values;
  const { member, accounts, saving } = data;

  const { selectedAccount, maximumAmount } = useDepositForm({ accounts, saving, accountId });
  const { paymentMethods, glLinks } = useTransactionForm({ method: values.method, transactionType: 'deposit' });

  // const activationDate = get('activationDate', selectedAccount);

  return (
    <StackChild>
      <MemberPanel member={member} showFinancialInfo />

      <SubscribedTransactionForm
        accounts={[]}
        paymentMethods={paymentMethods}
        glLinks={glLinks}
        selectedAccount={selectedAccount}
        maximumAmount={maximumAmount}
        hasAccountSelection={false}
        addData={addData}
      />

      <NotesPanel />
    </StackChild>
  );
}

DepositFormV1.validate = SubscribedTransactionForm.defaultValidation;
