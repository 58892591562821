import * as React from 'react';

import { LoanFormData } from '@kwara/components/src/pages/LoanAdd';
import { If } from '@kwara/components/src/If/If';
import { LoanSubstepProps } from 'GlobalTypes';

import { Security } from './components/Security/Security';
import { Attachments } from './components/Attachments/Attachments';
import { IncomeDetails } from './components/IncomeDetails/IncomeDetails';
import { Text } from '@kwara/components/src/Intl';
import Empty from '@kwara/components/src/Empty';
import { Loan, LoanType } from '@kwara/models/src';

import { useAuth } from '../../../../../hooks';
import { Notes } from '../../../../../components/Notes/Notes';
import Guarantors from '../../../../../components/Guarantors/Guarantors';
import Collaterals from '../../../../../components/Collaterals/Collaterals';
import { Updater } from '../../../../../components/EditableSection/v1/EditableSection';

interface IncomeAndSecurityPropTypes extends LoanSubstepProps {}

const includes = ['product', 'guarantees.member', 'collaterals.attachments'];

function IncomeAndSecurity(props: IncomeAndSecurityPropTypes) {
  const auth = useAuth();

  const { formProps } = props;
  const { loan } = props.data;
  const { loanApplication } = loan;
  const isV1 = auth.isV1();

  async function onUpdateCollateral(loan: LoanType) {
    const updatedLoan = await Loan.includes(includes)
      .find(loan.id)
      .then(res => res.data.deserialize());

    await props.addData({ collaterals: updatedLoan.collaterals });
    await props.onChange({ collaterals: updatedLoan.collaterals });
  }

  return (
    <Updater value={{ onUpdate: onUpdateCollateral }}>
      <If
        condition={!!loanApplication}
        do={<IncomeDetails {...props} />}
        else={
          <Empty>
            <Text id="LoanDetail.Income.empty" />
          </Empty>
        }
      />

      <Attachments member={props.data.member} />
      <Security {...props} disableEditing={isV1} />
      <If
        condition={!isV1}
        do={<Notes name="notes.income_and_security.no_section" className="mt3" formValues={formProps.values} />}
      />
    </Updater>
  );
}

export default {
  Component: IncomeAndSecurity,
  validate(args: LoanFormData) {
    return {
      ...Guarantors.validate({ ...args }),
      ...Collaterals.validate({ ...args })
    };
  }
};
