import React from 'react';

import { GlReportT } from '@kwara/models/src/models/v1/GLReport/Report';
import Empty from '@kwara/components/src/Empty';
import { Date, Currency, Text } from '@kwara/components/src/Intl';
import Table, { Row, Heading, Footer, Cell } from '../../../../../components/Table';

interface TableT {
  isLoading: boolean;
  report: GlReportT;
}

export function ReportsTable({ report, isLoading }: TableT) {
  const heading = React.useMemo(
    () => (
      <Row>
        <Heading translationId="AccountingReports.V1.GlReport.Table.header.entryDate" />
        <Heading translationId="AccountingReports.V1.GlReport.Table.header.description" />
        <Heading />
        <Heading translationId="AccountingReports.V1.GlReport.Table.header.creditAmount" />
        <Heading translationId="AccountingReports.V1.GlReport.Table.header.debitAmount" />
        <Heading translationId="AccountingReports.V1.GlReport.Table.header.runningBalance" />
      </Row>
    ),
    []
  );

  if (!report) {
    return null;
  }

  const rows = report.rows || [];
  if (rows.length === 0) {
    return (
      <Table
        heading={heading}
        footer={
          <Row>
            <Cell colSpan={6}>
              <Empty>
                <Text id="AccountingReports.V1.GlReport.Table.footer.empty" />
              </Empty>
            </Cell>
          </Row>
        }
      />
    );
  }

  return (
    <Table heading={heading} footer={<Footer colSpan={4} hasMore={false} isLoading={isLoading} items={rows} />}>
      {
        <Row>
          <Cell>Opening Balance</Cell>
          <Cell />
          <Cell />
          <Cell />
          <Cell />
          <Cell>
            <Currency value={report.openingBalance} />
          </Cell>
        </Row>
      }
      {rows.map(row => {
        return (
          <Row key={row.id}>
            <Cell>
              <Date value={row.entryDate} />
            </Cell>
            <Cell colSpan={2}>{row.description}</Cell>
            <Cell>
              <Currency value={row.creditAmount} />
            </Cell>
            <Cell>
              <Currency value={row.debitAmount} />
            </Cell>
            <Cell>
              <Currency value={row.runningBalance} />
            </Cell>
          </Row>
        );
      })}
      <Row>
        <Cell>Closing Balance</Cell>
        <Cell />
        <Cell />
        <Cell />
        <Cell />
        <Cell>
          <Currency value={report.closingBalance} />
        </Cell>
      </Row>
    </Table>
  );
}
