import React from 'react';
import { useQuery, QueryFunctionContext } from 'react-query';
import { ProfitLoss } from '@kwara/models/src/models/v1/ProfitLoss/Report';
import { formatIsoDate } from '@kwara/lib/src/dates';

type QueryKey = ['profit_loss', string, string];

async function fetchProfitLoss({ queryKey }: QueryFunctionContext<QueryKey>) {
  const [_key, startDate, endDate] = queryKey;

  return ProfitLoss.extraParams({
    start_date: formatIsoDate(startDate),
    end_date: formatIsoDate(endDate)
  })
    .find()
    .then(res => res.data);
}

export function useProfitLoss({ startDate, endDate }: { startDate: string; endDate: string }) {
  return useQuery(['profit_loss', startDate, endDate], fetchProfitLoss, {
    enabled: !!startDate && !!endDate,
    retry: false
  });
}

export function useProfitLossPage() {
  const [params, setParams] = React.useState<{ startDate: string | null; endDate: string | null }>({
    startDate: null,
    endDate: null
  });

  const { data: report, isLoading } = useProfitLoss(params);

  const onSubmit = React.useCallback(
    ({ startDate, endDate }) => setParams(prev => ({ ...prev, startDate, endDate })),
    []
  );

  return { report, onSubmit, isLoading };
}
