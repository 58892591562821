import React from 'react';

import { Form } from 'react-final-form';

import Button from '@kwara/components/src/Button';
import createValidator from '@kwara/lib/src/validator';

import { Text } from '@kwara/components/src/Intl';
import { getCurrentDate } from '@kwara/lib/src/dates';
import { ButtonBar } from '@kwara/components/src/ButtonBar';
import { SubscribedDatePicker } from '@kwara/components/src/Form';
import { SubscribedTextArea } from '@kwara/components/src/Wizard/deprecated/FormFields';

import { TransactionType, FormValues } from './useSubmit';

const validationConfig = {
  notes: { isRequired: () => true },
  valueDate: { isRequired: () => true }
};

type TransactionFormProps = {
  transaction: TransactionType;
  onSubmit(values: FormValues): void;
  onClose(): void;
};

export function TransactionForm({ transaction, onSubmit, onClose }: TransactionFormProps) {
  return (
    <Form
      validate={createValidator(validationConfig)}
      initialValues={{ transaction, valueDate: getCurrentDate() }}
      onSubmit={onSubmit}
      render={({ valid, form, submitting }) => (
        <>
          <SubscribedDatePicker
            name="valueDate"
            size="medium"
            labelId="MemberDetail.Transactions.Subsection.Actions.Adjust.Modal.ValueDate.label"
            required
            disabledDays={{
              after: getCurrentDate()
            }}
          />
          <SubscribedTextArea
            name="notes"
            labelId="MemberDetail.Transactions.Subsection.Actions.Adjust.Modal.note"
            required
          />
          <ButtonBar
            className="mt4"
            left={
              <Button type="secondary" disabled={submitting} onClick={onClose}>
                <Text id="MemberDetail.Transactions.Subsection.Actions.Adjust.Modal.Button.cancel" />
              </Button>
            }
            right={
              <Button disabled={!valid || submitting} type="primary" classNames="mr3" onClick={form.submit}>
                <Text id="MemberDetail.Transactions.Subsection.Actions.Adjust.Modal.Button.adjust" />
              </Button>
            }
          />
        </>
      )}
    />
  );
}
