import { useQuery } from 'react-query';

import { GlLink } from '@kwara/models/src/models/v1/PaymentMethodGlLink';

function fetchGlLinks({ queryKey }) {
  const [_, paymentMethodId, transactionType] = queryKey;
  return GlLink(paymentMethodId)
    .where({ transactionType })
    .all()
    .then(res => res.data);
}

export function useGlLinks({
  paymentMethodId,
  transactionType
}: {
  paymentMethodId?: string;
  transactionType: string;
}) {
  return useQuery(['payment_methods_gl_links', paymentMethodId, transactionType], fetchGlLinks, {
    placeholderData: [],
    enabled: !!paymentMethodId
  });
}
