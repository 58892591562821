import React from 'react';

import MemberPanel from '../../../../../components/Payment/MemberPanel';
import NotesPanel from '../../../../../components/Payment/NotesPanel';

import { SubscribedTransactionForm } from '../../../../../components/v1/PaymentForm/SubscribedTransactionForm';
import { SubStepComponentProps } from '../../../../../components/Wizard';
import { useTransactionForm } from '../../../../../components/v1/PaymentForm/useTransactionForm';

import { useWithdrawalForm } from '../../useWithdrawalForm';

export function WithdrawalFormV1({ StackChild, data, addData, formProps }: SubStepComponentProps<FormData>) {
  const { values } = formProps;
  const { accountId } = values;
  const { member, accounts, saving } = data;

  const { paymentMethods, glLinks } = useTransactionForm({ method: values.method, transactionType: 'withdrawal' });
  const { selectedAccount, maximumAmount } = useWithdrawalForm({ accounts, saving, accountId });
  // const activationDate = get('activationDate', selectedAccount);

  return (
    <StackChild>
      <MemberPanel member={member} showFinancialInfo />

      <SubscribedTransactionForm
        accounts={accounts}
        selectedAccount={selectedAccount}
        paymentMethods={paymentMethods}
        glLinks={glLinks}
        maximumAmount={maximumAmount}
        hasAccountSelection={true}
        addData={addData}
      />

      <NotesPanel />
    </StackChild>
  );
}

WithdrawalFormV1.validate = SubscribedTransactionForm.defaultValidation;
