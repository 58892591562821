import { useCallback } from 'react';
import get from 'lodash/fp/get';
import find from 'lodash/find';

import { formatIsoDate } from '@kwara/lib/src/dates';
import { AccountTransfer } from '@kwara/models/src/models/v1/AccountTransfer';
import { SavingsTransaction, MemberType, SavingType, LoanType } from '@kwara/models/src';

import { useAuth } from '../../hooks';

const DEPOSIT = 'DEPOSIT';
const LOAN = 'LOAN';
const INTRA = 'intra';
const INTER = 'inter';

export type FormData = {
  amount: number;
  saving: SavingType;
  recipient: MemberType;
  sender: MemberType;
  linkedAccountId: string;
  notes: string;
  valueDate: Date | string;
  linkedAccountType: typeof DEPOSIT | typeof LOAN;
};

function getReceivingAccount({ savings, loans }: MemberType, toAccountId: string) {
  return find([...savings, ...loans], a => a.id === toAccountId);
}

function linkedAccountType(account: SavingType | LoanType) {
  const resourceType = get('resourceIdentifier.type', account);

  if (resourceType === 'loans') return LOAN;

  if (resourceType === 'savings') return DEPOSIT;

  throw account;
}

function legacyTransfer({ amount, recipient, linkedAccountId, notes, saving }: FormData) {
  const receivingAccount = getReceivingAccount(recipient, linkedAccountId);
  const transfer = new SavingsTransaction({
    savingsId: saving.id,
    type: 'TRANSFER',
    amount,
    linkedAccountId,
    linkedAccountType: linkedAccountType(receivingAccount),
    notes
  });

  return transfer;
}

const v1AccountTypeMap = {
  [DEPOSIT]: 'savings_account',
  [LOAN]: 'loan_account'
};

function v1Transfer({ amount, recipient, linkedAccountId, notes, valueDate, saving }: FormData) {
  const receivingAccount = getReceivingAccount(recipient, linkedAccountId);
  const accountType = linkedAccountType(receivingAccount);
  const transfer = new AccountTransfer({
    sourceAccountId: saving.id,
    sourceAccountType: 'savings_account',
    destinationAccountId: linkedAccountId,
    destinationAccountType: v1AccountTypeMap[accountType],
    valueDate: formatIsoDate(valueDate),
    amount,
    notes
  });

  return transfer;
}

function useMakeTransfer() {
  const auth = useAuth();

  const isV1 = auth.isV1();

  const makeTransfer = useCallback(
    async (data: FormData) => {
      const transfer = isV1 ? v1Transfer(data) : legacyTransfer(data);
      const didSave = await transfer.save();

      if (!didSave) throw transfer.errors;
    },
    [isV1]
  );

  return makeTransfer;
}

export { useMakeTransfer, getReceivingAccount, linkedAccountType, DEPOSIT, LOAN, INTRA, INTER };
