import React from 'react';
import lodashMap from 'lodash/map';
import lodashOrderBy from 'lodash/orderBy';

import { ValueOf, InferredModel } from 'GlobalTypes';

import StatusTag from '@kwara/components/src/StatusTag';

import { If } from '@kwara/components/src/If/If';
import { SavingType } from '@kwara/models/src/models/Saving';
import { Percent, Date, DateTime } from '@kwara/components/src/Intl';

import Table, * as table from '../../../../components/Table';

import { useAuth } from '../../../../hooks';
import { useFormatter } from './useFormatter';
import { savingPath } from '../../../../lib/urls';
import { Currency } from '../../../../components/Currency';

export const Groups = { state: 'state' };

export const Orders = { updated: 'updated_at' };

type Order = ValueOf<typeof Orders>;

type Savings = Array<InferredModel<SavingType>>;

function orderByAttribute(savings: Savings, attribute: Order) {
  return (lodashOrderBy(savings, [({ attributes }) => attributes[attribute]], ['desc']) as unknown) as Array<
    SavingType
  >;
}

type RowPropTypes = {
  pathTo: ReturnType<typeof savingPath>;
  item: SavingType;
};
const Row = ({ item, pathTo }: RowPropTypes) => {
  const f = useFormatter(item);
  const auth = useAuth();

  const isV1 = auth.isV1();

  return (
    <>
      <table.Row to={pathTo}>
        <table.Cell to={pathTo} className="grey-400 kw-numeric">
          {f.id}
        </table.Cell>
        <table.Cell to={pathTo} className="mw5">
          {f.name}
        </table.Cell>
        <table.Cell to={pathTo}>{f.account}</table.Cell>
        {isV1 ? null : (
          <table.Cell to={pathTo} align="right">
            <Currency value={f.balance} />
          </table.Cell>
        )}
        {isV1 ? null : (
          <table.Cell to={pathTo} align="right">
            <If condition={f.interestRate != null} do={<Percent value={f.interestRate / 100} />} else="-" />
          </table.Cell>
        )}
        <table.Cell to={pathTo}>
          <If condition={isV1} do={<Date value={f.updated} />} else={<DateTime value={f.updated} />} />
        </table.Cell>
        <table.Cell to={pathTo}>
          <StatusTag size="small" state={f.state} />
        </table.Cell>
      </table.Row>
    </>
  );
};

type ListTablePropTypes = {
  loading: boolean;
  savings: Array<SavingType>;
  onLoadMoreData(): void;
  hasMore: boolean;
  errors: Array<Object> | null;
};

export function ListTable({ loading, errors, savings, onLoadMoreData, hasMore = false }: ListTablePropTypes) {
  const auth = useAuth();

  const isV1 = auth.isV1();
  const groupedSavings = { none: savings };
  const columns = isV1 ? 5 : 7;

  return (
    <Table
      heading={
        <table.Row>
          <table.Heading translationId="SavingListTable.id" />
          <table.Heading translationId="SavingListTable.member" />
          <table.Heading translationId="SavingListTable.account" />
          {isV1 ? null : <table.Heading align="right" translationId="SavingListTable.balance" />}
          {isV1 ? null : <table.Heading align="right" translationId="SavingListTable.interestRate" />}
          <table.Heading translationId="SavingListTable.updated" />
          <table.Heading translationId="SavingListTable.status" />
        </table.Row>
      }
      footer={
        <table.Footer
          colSpan={columns}
          isLoading={loading}
          onNext={onLoadMoreData}
          hasMore={hasMore}
          errors={errors}
          items={savings}
          translationBaseId="SavingListTable"
        />
      }
    >
      {lodashMap(groupedSavings, (savings, group) => (
        <React.Fragment key={group}>
          {group !== 'none' && <table.GroupingRow cols={columns} translationId={`SavingListTableGroup.${group}`} />}
          {orderByAttribute((savings as unknown) as Savings, null).map(item => (
            <Row key={item.id} item={item} pathTo={savingPath({ id: item.id })} />
          ))}
        </React.Fragment>
      ))}
    </Table>
  );
}
