import { useMemo } from 'react';
import { useQuery } from 'react-query';
import toLower from 'lodash/fp/toLower';

import { formatIsoDate } from '@kwara/lib/src/dates';
import { calculateNumberInstallments } from '@kwara/models/src/models/Loan';
import { SchedulesPreview } from '@kwara/models/src/models/SchedulePreview';

import { LoanAddData } from '../..';

export function useActions({
  product,
  amount,
  member,
  loanDuration,
  firstRepaymentDate,
  anticipatedDisbursementDate,
  repaymentPeriod: period,
  repaymentPeriodUnit: unit
}: LoanAddData) {
  const params = useMemo(() => {
    const repaymentInstallments = calculateNumberInstallments({
      period: Number(period),
      loanDuration: Number(loanDuration)
    });

    return {
      amount,
      repaymentInstallments,
      productId: product.id,
      memberId: member.id,
      repaymentPeriod: period,
      repaymentPeriodUnit: toLower(unit),
      firstRepaymentDate: formatIsoDate(firstRepaymentDate),
      anticipatedDisbursementDate: formatIsoDate(anticipatedDisbursementDate)
    };
  }, [amount, anticipatedDisbursementDate, firstRepaymentDate, loanDuration, member.id, period, product.id, unit]);

  const enabled = Object.values(params).every(Boolean);

  const { isLoading, error, data: schedule } = useQuery(
    ['schedules_preview'],
    () => SchedulesPreview.generateV1(params),
    { enabled, retry: false }
  );

  return {
    isLoading,
    schedule,
    error
  };
}
