import * as React from 'react';

import { getCurrentDate } from '@kwara/lib/src/dates';
import { Loadable } from '@kwara/components/src/Loadable';
import { useSaving } from '@kwara/models/src/models/request/hooks';

import { steps } from './config';
import { WizardPageProps } from '..';
import { savingPath } from '../../lib/urls';
import Wizard, { SubStepComponentProps } from '../../components/Wizard';
import {
  useMakeTransfer,
  getReceivingAccount,
  linkedAccountType,
  DEPOSIT,
  LOAN,
  INTRA,
  INTER,
  FormData
} from './useMakeTransfer';

export type SubstepProps = SubStepComponentProps<FormData>;

type Props = WizardPageProps<{ savingId: string }>;

const MakeTransfer = ({ baseUrl, match, history }: Props) => {
  const r = useSaving(match.params.savingId);
  const makeTransfer = useMakeTransfer();

  return (
    <Loadable {...r}>
      {saving => {
        const { member } = saving;

        return (
          <Wizard
            analyticsId="MakeTransfer"
            baseUrl={baseUrl}
            history={history}
            initialData={{
              saving,
              recipient: member,
              sender: member,
              transferType: INTRA,
              valueDate: getCurrentDate()
            }}
            cancelReturnsTo={savingPath({ id: saving.id })}
            currentStep={match.params.step}
            currentSubStep={match.params.subStep != null ? parseInt(match.params.subStep, 10) : null}
            onSubmit={makeTransfer}
            steps={steps}
            startId="recipient"
            titleId="MakeTransfer.shortTitle"
          />
        );
      }}
    </Loadable>
  );
};

export { MakeTransfer, getReceivingAccount, linkedAccountType, DEPOSIT, LOAN, INTRA, INTER };
