import { usePagination } from './usePagination';
import { IncludesT, WhereT } from '@kwara/models/src';

export async function fetchWithoutPagination(model, { queryKey }) {
  const [_, includes, where] = queryKey;

  return await model
    .where(where)
    .includes(includes)
    .all()
    .then(res => res.data);
}

export type PaginationQueryType = { pageParam: number; pageSizeParam: number; queryKey: string };

export async function fetchWithPagination(model, { pageParam = 1, pageSizeParam = 10, queryKey }) {
  const [_, includes, where, select] = queryKey;

  return await model
    .stats({ total: ['count', 'pages'] })
    .where(where)
    .includes(includes)
    .select(select)
    .per(pageSizeParam)
    .page(pageParam)
    .all();
}

// This function does nothing, but works harmoniously with the `usePagination` hook
// when no filter is necessary, so the usePagination hook can be used in all cases.
// When filtering is necessary a unique userWhere should be passed in depending on which
// filters are necessary.
const useNoFilter = () => ({});

type FilterProps = {
  where?: WhereT;
  onReset?: () => void;
  onFilterChange?: Function;
  onSearchChange?: (val: string) => void;
  dateRangeConfig?: {
    onDateChange?: (val: string) => void;
    dateValues?: { from: string; to: string };
    disabled?: boolean;
    disabledDays?: { before?: string; after?: string };
  };
  filterValue?: string;
};

type Props = {
  queryKey: string;
  fetchFn: (opts: any) => Promise<any>;
  includes: IncludesT;
  select?: any;
  useWhere?: () => FilterProps;
  enabled?: boolean;
};
export function useOverviewList({ queryKey, fetchFn, includes, select, enabled, useWhere = useNoFilter }: Props) {
  const { where = {}, onFilterChange, onSearchChange, dateRangeConfig, filterValue, ...rest } = useWhere();
  const { allData, totalResults, ...r } = usePagination({ queryKey, fetchFn, includes, where, select, enabled });

  const searchValue = where.search;

  return {
    allData,
    filterValue,
    onFilterChange,
    onSearchChange,
    searchValue,
    totalResults,
    dateRangeConfig,
    where,
    ...rest,
    ...r
  };
}
