import * as React from 'react';
import groupBy from 'lodash/fp/groupBy';
import lodashMap from 'lodash/map';
import isEmpty from 'lodash/fp/isEmpty';

import { getYear } from '@kwara/lib/src/dates';
import { sumBy } from '@kwara/lib/src/currency';
import { Date } from '@kwara/components/src/Intl';
import { RepaymentType } from '@kwara/models/src';

import StatusTag from '@kwara/components/src/StatusTag';

import { useAuth } from '../../../hooks';
import { Currency } from '../../../components/Currency';
import Table, { Cell, Collapsible, Heading, Row } from '../../../components/Table';

const sharedProps = {
  hideDecimals: false,
  size: 'small'
};

type BodyPropTypes = {
  repayments: Array<RepaymentType> | null;
  initiallyOpen: boolean;
};

type PropTypes = {
  repayments: Array<RepaymentType> | null;
  initiallyOpen?: boolean;
  groupedByYear?: boolean;
};

function RepaymentRow({ repayment }: { repayment: RepaymentType }) {
  return (
    <Row>
      <Cell>
        <Date value={repayment.dueAt} size="small" />
      </Cell>
      <Cell align="right">
        <Currency value={repayment.principal.due} {...sharedProps} />
      </Cell>
      <Cell align="right">
        <Currency value={repayment.interest.due} {...sharedProps} />
      </Cell>
      <Cell align="right">
        <Currency value={repayment.fees.due} {...sharedProps} />
      </Cell>
      <Cell align="right">
        <Currency value={repayment.penalty.due} {...sharedProps} />
      </Cell>
      <Cell align="right">
        <Currency value={repayment.totalDue} {...sharedProps} />
      </Cell>
      <Cell align="right">
        <Currency value={repayment.principal.balance} {...sharedProps} />
      </Cell>
      <Cell align="right">
        <StatusTag state={repayment.state} size="small" />
      </Cell>
    </Row>
  );
}

function Body({ repayments = [] }: { repayments: RepaymentType[] }) {
  return (
    <>
      {repayments.map(repayment => (
        <RepaymentRow key={repayment.dueAt} repayment={repayment} />
      ))}
    </>
  );
}

const GroupedBody = React.memo(function GroupedBody({ repayments, initiallyOpen }: BodyPropTypes) {
  const groups: { [year: string]: RepaymentType[] } = groupBy(
    (repayment: RepaymentType) => getYear(repayment.dueAt),
    repayments
  );

  return (
    <>
      {lodashMap(groups, (items: RepaymentType[], year: string) => (
        <Collapsible key={year} initiallyOpen={initiallyOpen}>
          <Row>
            <Cell>
              <span className="dib kw-text-small" style={{ width: 90 }}>
                {year}
              </span>
            </Cell>
            <Cell align="right">
              <Currency value={sumBy('principal.due', items)} {...sharedProps} />
            </Cell>
            <Cell align="right">
              <Currency value={sumBy('interest.due', items)} {...sharedProps} />
            </Cell>
            <Cell align="right">
              <Currency value={sumBy('fees.due', items)} {...sharedProps} />
            </Cell>
            <Cell align="right">
              <Currency value={sumBy('penalty.due', items)} {...sharedProps} />
            </Cell>
            <Cell align="right">
              <Currency value={sumBy('totalDue', items)} {...sharedProps} />
            </Cell>
            <Cell align="right">
              <Currency value={null} {...sharedProps} />
            </Cell>
          </Row>

          {lodashMap(items, repayment => (
            <RepaymentRow key={repayment.dueAt} repayment={repayment} />
          ))}
        </Collapsible>
      ))}
    </>
  );
});

export default function RepaymentsTable({ repayments, initiallyOpen = false, groupedByYear }: PropTypes) {
  const auth = useAuth();

  const isV1 = auth.isV1();

  const shouldGroupByYear = groupedByYear ?? !isV1;
  const shouldRender = repayments != null || !isEmpty(repayments);

  if (!shouldRender) {
    return null;
  }

  return (
    <>
      <Table
        heading={
          <Row>
            <Heading width="125px" translationId="RepaymentsTable.dueDate" />
            <Heading align="right" translationId="RepaymentsTable.principal" />
            <Heading align="right" translationId="RepaymentsTable.interest" />
            <Heading align="right" translationId="RepaymentsTable.fees" />
            <Heading align="right" translationId="RepaymentsTable.penalties" />
            <Heading align="right" translationId="RepaymentsTable.total" />
            <Heading align="right" translationId="RepaymentsTable.balance" />
            <Heading align="right" translationId="RepaymentsTable.state" />
          </Row>
        }
      >
        {shouldGroupByYear ? (
          <GroupedBody initiallyOpen={initiallyOpen} repayments={repayments} />
        ) : (
          <Body repayments={repayments} />
        )}
      </Table>
    </>
  );
}
