import pick from 'lodash/fp/pick';

import { LoanFormData } from 'LoanSharedTypes';

import { TransactionChannels, TransactionChannelT } from '@kwara/models/src/models/Transactions';

export interface DataType extends Partial<LoanFormData> {
  paymentMethod?: TransactionChannelT; // for Legacy API
  paymentMethodId?: string; // for v1 API
  glLinkId?: string; // for v1 API
  [key: string]: unknown;
}

type ArgType = {
  data: DataType;
  phoneNumber?: string;
  isV1?: boolean;
};
export function getPaymentInfo({ data, phoneNumber, isV1 = false }: ArgType) {
  if (isV1) {
    return {
      paymentMethodId: data.paymentMethodId,
      glLinkId: data.glLinkId
    };
  }

  const paymentInfo = pick(
    ['reference', 'bankName', 'bankBranch', 'chequeNumber', 'drawer', 'accountNumber', 'accountId', 'bankGlId'],
    data
  );

  if (data.paymentMethod === TransactionChannels.mobileMpesaInstant) {
    return {
      ...paymentInfo,
      onlinePayoutDetails: {
        account_number: phoneNumber
      },
      paymentMethod: 'online_mobile_payout'
    };
  }

  return { ...paymentInfo, paymentMethod: data.paymentMethod };
}
