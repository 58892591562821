import * as React from 'react';
import lodashMap from 'lodash/map';

import { LoanType } from '@kwara/models/src/models/Loan';

import { useAuth } from '../../../../hooks';
import { orderByAttribute } from './utils';
import Table, * as table from '../../../../components/Table';
import { CreateGroupWithLoans } from './CreateGroupWithLoans';

type LoanListTablePropTypes = {
  hasMore: boolean;
  loading: boolean;
  loans: LoanType[];
  errors?: Object[];
  onLoadMoreData(): void;
};

export default function LoanListTable(props: LoanListTablePropTypes) {
  const { loading, loans, errors, onLoadMoreData, hasMore = false } = props;

  const auth = useAuth();

  const isV1 = auth.isV1();
  const groupedLoans = { none: loans };

  const heading = (
    <table.Row>
      <table.Heading translationId="LoanListTable.id" />
      <table.Heading translationId="LoanListTable.member" />
      <table.Heading translationId="LoanListTable.loan" />
      <table.Heading align="right" translationId="LoanListTable.amount" />
      {isV1 ? null : <table.Heading align="right" translationId="LoanListTable.interestRate" />}
      {isV1 ? null : <table.Heading translationId="LoanListTable.duration" />}
      <table.Heading translationId="LoanListTable.updated" />
      <table.Heading translationId="LoanListTable.status" />
      {isV1 ? null : <table.Heading translationId="LoanListTable.disbursalApprovedBy" />}
    </table.Row>
  );

  const columns = heading.props.children.filter(heading => heading).length;

  return (
    <Table
      heading={heading}
      footer={
        <table.Footer
          colSpan={columns}
          isLoading={loading}
          onNext={onLoadMoreData}
          hasMore={hasMore}
          errors={errors}
          items={loans}
          translationBaseId="LoanListTable"
        />
      }
    >
      {lodashMap(groupedLoans, (loans, group) => (
        <CreateGroupWithLoans
          isV1={isV1}
          key={group}
          columns={columns}
          group={group}
          loans={orderByAttribute(loans, null)}
        />
      ))}
    </Table>
  );
}
