//@flow

import { useInfiniteQuery } from 'react-query';

import get from 'lodash/fp/get';
import size from 'lodash/fp/size';
import flatMap from 'lodash/fp/flatMap';
import first from 'lodash/fp/first';

import { IncludesT, WhereT } from '@kwara/models/src';

type UsePaginationArgType = {
  where?: WhereT,
  queryKey: string,
  fetchFn: (opts: any) => Promise<any>,
  includes: IncludesT,
  select?: any,
  enabled?: boolean
};

export function usePagination({ queryKey, fetchFn, includes, where, select, enabled }: UsePaginationArgType) {
  const r = useInfiniteQuery([queryKey, includes, where, select], fetchFn, {
    getNextPageParam: (lastPage, allPages) => {
      const totalPages = get('meta.stats.total.pages', lastPage);

      return size(allPages) < totalPages ? size(allPages) + 1 : undefined;
    },
    enabled
  });

  const proxies = get('data.pages', r);
  const proxy = first(proxies);
  const totalResults = get('meta.stats.total.count', proxy);
  const allData = flatMap(proxy => proxy.data, proxies) || [];

  return { ...r, totalResults, allData };
}
