// @flow

import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';

import IncomeForm from './components/IncomeForm/IncomeForm';

function IncomeHeader() {
  return <Text id="MemberDetail.income" />;
}

function IncomeBody(props) {
  return <IncomeForm.Component {...props} />;
}

export default {
  Title: IncomeHeader,
  Body: IncomeBody,
  validate: {
    'member.employmentStatus': {
      isRequired: () => true
    },
    'member.netIncome': {
      isRequired: () => true,
      currency: true
    },
    'member.employer': {
      isRequired: () => false
    },
    'member.otherIncomeAmount': {
      currency: true
    },
    'member.otherDeductibles': {
      currency: true
    }
  }
};
