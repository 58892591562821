import * as React from 'react';

import { Statistic } from '@kwara/components/src/Statistic';
import { Text } from '@kwara/components/src/Intl';
import { Note } from '@kwara/components/src/Note';
import { MemberType } from '@kwara/models/src';

import { SubStepComponentProps } from '../../../../components/Wizard';
import PersonalDetails, { Header } from '../PersonalDetails';

import { WizardData } from '../..';

const isApplicationFromConnect = (member: MemberType) => member.source === 'connect';
export const Review = ({
  data: { member },
  StackChild,
  onChange,
  Checkbox,
  isV1
}: SubStepComponentProps<WizardData>) => (
  <StackChild size="widest">
    {member.notes && <Note items={[{ notes: member.notes }]} />}
    <PersonalDetails member={member} onChange={onChange} isV1={isV1} />
    <Header>
      <Text id="MemberApprove.joiningFee" />
    </Header>
    <Statistic title={<Text id="MemberApprove.JoiningFee.reference" />} value={member.joiningFeeReference} />
    {!isApplicationFromConnect(member) && (
      <Checkbox required name="joiningFeeReceived" labelId="MemberApprove.JoiningFee.labelId" />
    )}
  </StackChild>
);

Review.validate = {
  joiningFeeReceived: {
    isRequired: (_: string, data: WizardData) => !isApplicationFromConnect(data.member)
  }
};
