import axios from 'axios';
import { attr } from 'spraypaint';

import { Logger } from '@kwara/lib/src/logger';

import { MemberType } from '../../Member';
import Base from '../../Base';
import { FileUpload, FileUploadT } from '../FileUpload';
import { AttachmentT } from '../../Attachment';

export const MemberAttachment = Base.extend({
  static: {
    jsonapiType: 'member_attachments',
    async attachS3FileUploadsToMember(member: MemberType, uploads: FileUploadT[] = []) {
      if (uploads.length === 0) return true;

      const url = `${MemberAttachment.fullBasePath()}/members/${member.id}/attachments`;

      const attachments = uploads.map(s => ({ upload_id: s.id }));
      const data = { data: { type: 'member_attachments', attributes: { attachments } } };
      const opts = MemberAttachment.fetchOptions();

      return axios.post(url, data, opts).then(() => true);
    },

    async s3FullUploadAndAttachForMember(member: MemberType, attachments: AttachmentT[]) {
      const uploads = await Promise.allSettled(
        attachments.map(att => FileUpload.s3UploadWithPresignedUrl(att.file, { uploadType: att.v1Name }))
      );

      const successfulUploads = uploads
        .filter(u => u.status === 'fulfilled')
        .map((u: PromiseFulfilledResult<FileUploadT>) => u.value);

      const totalCount = uploads.length;
      const failCount = totalCount - successfulUploads.length;

      if (failCount > 0) {
        const message = `S3 Upload Failure: Member ${member.fullName()} ${
          member.id
        }; ${failCount} of ${totalCount} failed;`;
        Logger.error(message);
      }

      MemberAttachment.attachS3FileUploadsToMember(member, successfulUploads).catch(() => {
        const message = `S3-Member Attachment Failure:\nMember ${member.fullName()} ${member.id}; `;
        Logger.error(message);
      });

      return true;
    }
  },
  attrs: {
    name: attr(),
    type: attr(),
    content: attr()
  }
});
