import React from 'react';

import Button from '@kwara/components/src/Button';

import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import { ButtonBar } from '@kwara/components/src/ButtonBar';
import { SchedulePreviewT } from '@kwara/models/src/models/SchedulePreview';

type ButtonsProps = {
  invalid: boolean;
  isLoading: boolean;
  schedule: SchedulePreviewT | null;
  clear(): void;
  onSubmit(): Promise<void>;
};

export function Buttons({ invalid, isLoading, schedule, clear, onSubmit }: ButtonsProps) {
  return (
    <ButtonBar
      className="mb4"
      right={[
        <If
          condition={!!schedule}
          do={
            <Button type="secondary" disabled={isLoading} onClick={clear}>
              <Text id="LoanSchedulePreview.Preview.button.clearSchedule" />
            </Button>
          }
        />,
        <Button type="primary" disabled={isLoading || invalid} onClick={onSubmit}>
          <Text id="LoanSchedulePreview.Preview.button.previewSchedule" />
        </Button>
      ]}
    />
  );
}
