import * as React from 'react';
import { Form as ReactFinalForm } from 'react-final-form';

import Button from '@kwara/components/src/Button';
import createValidator from '@kwara/lib/src/validator';

import { Text } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src/If/If';
import { SubscribedDatePicker } from '@kwara/components/src/Form';
import { getCurrentDate, isAfter, differenceInDays, addDays, min } from '@kwara/lib/src/dates';
import { ErrorTextWithI18n } from '@kwara/components/src/ErrorText';

import { useDownloadGlReport } from '../useDownloadGlReport';
import { V1AllGeneralLedgerAccountCombobox } from '../../../../../components/Form/v1/V1AllGlAccountSelect';

import styles from '../index.module.scss';

const MAX_DATE_RANGE = 366;

type Payload = {
  endDate: string;
  startDate: string;
  glAccountId: string;
};

export function Form({ onSubmit }: { onSubmit: (data: Payload) => void }) {
  const { onDownloadXlsx, isDownloading } = useDownloadGlReport();

  return (
    <ReactFinalForm
      onSubmit={onSubmit}
      validate={createValidator({
        startDate: {
          isRequired: () => true,
          isNotFuture: true
        },
        endDate: {
          isRequired: () => true,
          isNotFuture: true,
          custom: (endDate: string, { startDate }: { startDate?: string }) => {
            if (isAfter(startDate, endDate)) {
              return 'START_DATE_AFTER_END_DATE';
            }

            if (differenceInDays(startDate, endDate) > MAX_DATE_RANGE) {
              return 'MAXIMUM_RANGE_EXCEEDED';
            }

            return null;
          }
        }
      })}
      render={({ hasValidationErrors, handleSubmit, hasSubmitErrors, submitting, values }) => {
        const { startDate, endDate, glAccountId } = values;
        const daysInFuture = addDays(startDate, MAX_DATE_RANGE);
        const maxEndDate = min(getCurrentDate(), daysInFuture);

        return (
          <form className="w-100 flex items-center" aria-label="Account Report Form" onSubmit={handleSubmit}>
            <div className="dib mr3">
              <V1AllGeneralLedgerAccountCombobox margin={false} compact required name="glAccountId" />
            </div>
            <div className="dib mr3">
              <SubscribedDatePicker
                disabled={submitting}
                disabledDays={{ after: getCurrentDate() }}
                showInfo={false}
                compact
                margin={false}
                required
                name="startDate"
                leftGlyph="AccountingReports.Index.Form.StartDate.leftGlyph"
              />
            </div>
            <div className="dib mr3">
              <SubscribedDatePicker
                disabled={submitting}
                disabledDays={{ after: maxEndDate }}
                showInfo={false}
                compact
                margin={false}
                required
                name="endDate"
                leftGlyph="AccountingReports.Index.Form.EndDate.leftGlyph"
              />
            </div>

            <div className={styles.submit}>
              <Button
                disabled={hasValidationErrors || submitting}
                isSubmit
                type="primary"
                glyphRightId={submitting ? Button.Glyphs.Spinner : null}
              >
                <Text id="AccountingReports.Index.Form.submit.generate" />
              </Button>
              <Button
                className="ml3"
                disabled={hasValidationErrors || submitting || isDownloading}
                glyphRightId={isDownloading ? Button.Glyphs.Spinner : null}
                onClick={() => onDownloadXlsx({ start_date: startDate, end_date: endDate, gl_account_id: glAccountId })}
              >
                <Text id="Generic.download" />
              </Button>
              <If
                condition={hasSubmitErrors}
                do={<ErrorTextWithI18n id="AccountingReports.Index.Form.submit.error" />}
              />
            </div>
          </form>
        );
      }}
    />
  );
}
