import * as React from 'react';

import { LoanSubstepProps } from 'GlobalTypes';

import { Text, Currency } from '@kwara/components/src/Intl';
import { Render } from '@kwara/components/src';

import TabNavigation from '../../../../../components/TabNavigation';

import { AttributesRow } from '../../../../../components/AttributesRow';
import { GuarantorsLister } from '../../../../../components/Guarantors/components/GuarantorsLister';
import { CollateralsLister } from '../../../../../components/Collaterals/components/CollateralsLister/CollateralsLister';

import { useAuth } from '../../../../../hooks';
import { useEligibilityTabData } from './useEligibilityTabData';
import { useRepaymentTabData } from './useRepaymentTabData';
import { useLoanDetailsData } from './useLoanDetailsData';

export const currency = value => (value == null ? '-' : <Currency value={value} format={'currency'} />);
function LoanDetails(props: LoanSubstepProps) {
  const { data } = props;
  const { loan } = data;

  const auth = useAuth();
  const { eligibilityAttributesRow } = useEligibilityTabData(loan);
  const { repaymentAttributesRow1, repaymentAttributesRow2, repaymentAttributesRow3 } = useRepaymentTabData(loan);
  const { loanDetailsAttributesRow } = useLoanDetailsData(loan);

  const isV1 = auth.isV1();

  const tabConfig = [
    {
      title: <Text id={'LoanDisbursal.LoanDetails.NavigationTabs.eligibility'} />,
      content: (
        <section className="bg-white pt3">
          <AttributesRow attributes={eligibilityAttributesRow} />
        </section>
      )
    },
    isV1
      ? null
      : {
          title: <Text id={'LoanDisbursal.LoanDetails.NavigationTabs.repayment'} />,
          content: (
            <section className="bg-white pt3">
              <AttributesRow attributes={repaymentAttributesRow1} />
              <AttributesRow attributes={repaymentAttributesRow2} />
              <AttributesRow attributes={repaymentAttributesRow3} />
            </section>
          )
        },
    {
      title: <Text id={'LoanDisbursal.LoanDetails.NavigationTabs.security'} />,
      content: (
        <section className="bg-white pt3">
          <Render condition={!!data.guarantors.length} fallbackId="Guarantor.noGuarantors">
            <GuarantorsLister guarantors={data.guarantors} readonly={true} />
          </Render>

          <Render condition={!!data.collaterals.length} fallbackId="Collateral.noCollaterals">
            <CollateralsLister
              loanApplication={data.loan.loanApplication}
              collaterals={data.collaterals}
              fileName="proofOfAsset"
              withNarration
            />
          </Render>
        </section>
      )
    }
  ].filter(Boolean);

  return (
    <>
      <AttributesRow attributes={loanDetailsAttributesRow} useBackground={false} />
      <TabNavigation.SwitchTab tabConfig={tabConfig} className="pt4" />
    </>
  );
}

export default {
  Component: LoanDetails,
  validate: {}
};
