import * as React from 'react';
import get from 'lodash/fp/get';

import { LoanFormData } from '@kwara/components/src/pages/LoanAdd';
import { LoadingSkeleton } from '@kwara/components/src/Loadable';
import { Text, Currency } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src/If/If';
import { LoanType } from '@kwara/models/src';

import { useMemberEligibility } from '../../../../../hooks/useRequest';
import { useAnticipatedPayoutApiFetch, useFeeValues } from '../../../hooks';
import { AnticipatedPayoutBreakdown } from '../../../components/AnticipatedPayoutBreakdown';
import { pickMemberEligibilityPerLoanProduct } from '../../../utils/pickMemberEligibilityPerLoanProduct';

type GetAttributeRowsArgTypes = {
  loan: LoanType;
  anticipatedPayout: number | string;
  principalAmount: number | string;
  mambuFees: number | string;
  additionalFees: number | string;
  loanPayoffAmount: number | string;
  isFetchingAnticipatedPayout: boolean;
  eligibleAmount: number;
  isFetchingMemberEligibility: boolean;
  isV1: boolean;
};

function getAttributeRows(arg: GetAttributeRowsArgTypes) {
  const {
    loan: { amount },
    anticipatedPayout,
    principalAmount,
    mambuFees,
    additionalFees,
    loanPayoffAmount,
    isFetchingAnticipatedPayout,
    eligibleAmount,
    isFetchingMemberEligibility,
    isV1
  } = arg;

  const attributesRow1 = [
    {
      ariaLabel: 'Applied amount',
      title: <Text id="LoanAppraisal.Overview.appliedAmount" />,
      value: <Currency value={amount} format="currency" />
    },
    {
      ariaLabel: 'Eligible amount',
      title: <Text id="LoanAppraisal.Overview.eligibleAmount" />,
      value: (
        <If
          condition={isFetchingMemberEligibility}
          do={<LoadingSkeleton />}
          else={<Currency format="currency" value={eligibleAmount} />}
        />
      )
    },
    isV1
      ? null
      : {
          ariaLabel: 'Anticipated payout',
          title: (
            <>
              <Text id="LoanAppraisal.Overview.anticipatedPayout" />
              &nbsp;
              <AnticipatedPayoutBreakdown
                principalAmount={principalAmount}
                mambuFees={mambuFees}
                additionalFees={additionalFees}
                loanPayoffAmount={loanPayoffAmount}
                anticipatedPayout={anticipatedPayout}
              />
            </>
          ),
          value: (
            <If
              condition={isFetchingAnticipatedPayout}
              do={<LoadingSkeleton />}
              else={<Currency format="currency" value={anticipatedPayout} />}
            />
          )
        }
  ].filter(Boolean);

  return { attributesRow1 };
}

export function useRecommendationsSection(
  data: LoanFormData,
  addData: (updated: LoanFormData) => Promise<void>,
  isV1: boolean
) {
  const { loan, member } = data;
  const { amount, product, loanApplication = {} } = loan;
  const { payOffLoans = [], loanApplicationFees = [] } = loanApplication;
  const { totalAdditionalFees } = useFeeValues({ product, feeApplications: loanApplicationFees, amount });

  const args = {
    member,
    payOffLoans: payOffLoans.map((loan: LoanType) => loan.id),
    amount,
    product,
    totalAdditionalFees,
    isV1
  };
  const {
    anticipatedPayout,
    isFetching: isFetchingAnticipatedPayout,
    principalAmount,
    mambuFees,
    additionalFees,
    loanPayoffAmount
  } = useAnticipatedPayoutApiFetch(args);

  const { data: memberEligibilityData, isFetching: isFetchingMemberEligibility } = useMemberEligibility({
    memberId: get('member.id', data)
  });

  const { eligibleAmount } = pickMemberEligibilityPerLoanProduct(loan, memberEligibilityData);

  const { attributesRow1 } = React.useMemo(
    () =>
      getAttributeRows({
        loan,
        anticipatedPayout,
        principalAmount,
        mambuFees,
        additionalFees,
        loanPayoffAmount,
        isFetchingAnticipatedPayout,
        eligibleAmount,
        isFetchingMemberEligibility,
        isV1
      }),
    [
      loan,
      anticipatedPayout,
      principalAmount,
      mambuFees,
      additionalFees,
      loanPayoffAmount,
      isFetchingAnticipatedPayout,
      eligibleAmount,
      isFetchingMemberEligibility,
      isV1
    ]
  );

  addData({ totalAdditionalFees, anticipatedPayout });

  return { attributesRow1 };
}
