import { PayOffForm } from './PayOffForm';

export const steps = {
  confirm: {
    Component: PayOffForm,
    validate: PayOffForm.validate,
    actions: [
      {
        appearsAs: 'submit',
        behavesAs: 'complete'
      }
    ]
  }
};
