import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { formatHumanDate } from '@kwara/lib/src/dates';
import LoanTransaction, { LoanTransactionType } from '@kwara/models/src/models/LoanTransaction';
import SavingsTransaction, { SavingsTransactionType } from '@kwara/models/src/models/SavingsTransaction';

export type TransactionType = LoanTransactionType | SavingsTransactionType;

export type TransactionModelType = typeof SavingsTransaction | typeof LoanTransaction;

export type FormValues = {
  notes: string;
  valueDate: string;
  transaction: TransactionType;
};

type ParamsType = {
  loanId: string;
  savingId: string;
};

export function useSubmit(TransactionModel: TransactionModelType, onSuccess: () => void) {
  const [errors, setErrors] = useState(null);
  const [success, setSuccess] = useState(false);
  const { loanId, savingId } = useParams<ParamsType>();

  const onSubmit = useCallback(
    async ({ notes, valueDate, transaction }: FormValues) => {
      const model = new TransactionModel({ ...transaction });
      const id = transaction.isType(LoanTransaction.jsonapiType) ? loanId : savingId;
      const isSuccess = await model.v1Adjust(id, { notes, valueDate: formatHumanDate(valueDate) });

      if (isSuccess) {
        setErrors(null);
        setSuccess(true);
        onSuccess();
      } else {
        setErrors(model.errors);
        setSuccess(false);
      }
    },
    [TransactionModel, loanId, onSuccess, savingId]
  );

  return { errors, success, onSubmit };
}
