import * as React from 'react';
import concat from 'lodash/fp/concat';
import map from 'lodash/fp/map';
import size from 'lodash/fp/size';

import { If } from '@kwara/components/src/If/If';
import { useNextOfKins } from '@kwara/models/src/models/request/hooks';
import { Loadable } from '@kwara/components/src';
import Banner from '@kwara/components/src/Banner';
import { MemberType } from '@kwara/models/src';
import { Text, Percent } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';

import { EditableSection, EditableConfig } from '../../EditableSection';
import { NextOfKin as Kin, NextOfKinProps } from '../../../pages/MemberAdd/components/NextOfKin';
import { useAuth } from '../../../hooks';

const KinWrapper = (props: NextOfKinProps) => <Kin {...props} size="stretch" />;

const config = { Component: KinWrapper };

function ViewDeprecatedNextOfKins({ deprecatedNextOfKins }) {
  return (
    <Banner
      state="warning"
      text={
        <div>
          <p className="b">Historical kin data</p>
          <ul>
            {map(
              kin => (
                <li key={kin}>{kin}</li>
              ),
              deprecatedNextOfKins
            )}
          </ul>

          <p>
            Kindly click <span className="b">Add next of kin</span> and save this data in the improved format.
          </p>
        </div>
      }
    />
  );
}

export function NextOfKin(props: EditableConfig<MemberType>) {
  const auth = useAuth();
  const { remove, refetch, ...r } = useNextOfKins(props?.member?.id);

  const isV1 = auth.isV1();

  return (
    <Loadable {...r}>
      {member => {
        const nextOfKins = member?.nextOfKins || [];
        const deprecatedNextOfKins = member?.deprecatedNextOfKins || [];
        const showDeprecated = size(deprecatedNextOfKins) > 0 && size(nextOfKins.filter(kin => kin.isPersisted)) === 0;

        return (
          <>
            <EditableSection
              config={config}
              onSave={async instance => {
                const didSave = await instance.save({ with: ['nextOfKins'] });
                if (didSave) {
                  remove();
                  refetch();
                }
              }}
              initialData={member}
              readOnly={props.readOnly}
              isEditing={showDeprecated}
            >
              {nextOfKins.map((nextOfKin, index) => {
                return (
                  <StatisticRow compact widthClass="w-33" key={concat(nextOfKin.id, index)}>
                    <Statistic
                      title={<Text id="MemberDetail.NextOfKin.fullName" />}
                      value={nextOfKin.name}
                      size="medium"
                    />
                    <Statistic
                      title={<Text id="MemberDetail.NextOfKin.phoneNumber" />}
                      value={nextOfKin.phoneNumber}
                      size="medium"
                    />
                    <Statistic
                      title={<Text id="MemberDetail.NextOfKin.relationship" />}
                      value={nextOfKin.relationship}
                      size="medium"
                    />
                    {isV1 ? (
                      <Statistic
                        title={<Text id="MemberDetail.NextOfKin.shares" />}
                        value={nextOfKin.percentageShares ? <Percent value={nextOfKin.percentageShares / 100} /> : '-'}
                        size="medium"
                      />
                    ) : null}
                  </StatisticRow>
                );
              })}
            </EditableSection>
            <If
              condition={showDeprecated}
              do={<ViewDeprecatedNextOfKins deprecatedNextOfKins={deprecatedNextOfKins} />}
            />
          </>
        );
      }}
    </Loadable>
  );
}

NextOfKin.Title = () => <Text id="MemberDetail.nextOfKin" />;
