import { useCallback, useState } from 'react';

import { HttpErrorObject } from 'GlobalTypes';

import { formatIsoDate } from '@kwara/lib/src/dates';
import { calculateNumberInstallments } from '@kwara/models/src/models/Loan';
import { SchedulesPreview, SchedulePreviewT } from '@kwara/models/src/models/SchedulePreview';

import { Data } from '..';
import { EMPTY } from '../../../hooks';

export function useActions({
  product: { id: productId, interestRate },
  amount,
  loanDuration,
  firstRepaymentDate,
  repaymentPeriod: period,
  repaymentPeriodUnit: unit
}: Data) {
  const [isLoading, setIsLoading] = useState(false);
  const [schedule, setSchedule] = useState<SchedulePreviewT | null>(null);
  const [error, setError] = useState<Array<HttpErrorObject> | {}>(EMPTY);

  const params = useCallback(() => {
    const repaymentInstallments = calculateNumberInstallments({
      loanDuration: Number(loanDuration),
      period: Number(period)
    });

    return {
      amount,
      repaymentInstallments,
      repaymentPeriod: period,
      repaymentPeriodUnit: unit,
      interestRate: interestRate.percentage,
      firstRepaymentDate: formatIsoDate(firstRepaymentDate)
    };
  }, [amount, firstRepaymentDate, interestRate, loanDuration, period, unit]);

  const clear = useCallback(() => {
    setSchedule(null);
    setError(EMPTY);
  }, []);

  const onSubmit = useCallback(async () => {
    clear();
    setIsLoading(true);

    try {
      const schedule = await SchedulesPreview.generate(productId, params());
      setSchedule(schedule);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, [clear, params, productId]);

  return {
    isLoading,
    schedule,
    error,
    clear,
    onSubmit
  };
}
