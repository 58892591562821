import React from 'react';
import { withRouter } from 'react-router-dom';

import Button from '@kwara/components/src/Button';

import { Text } from '@kwara/components/src/Intl';

import Visible from '../../../../components/Visible';

import { loanPath } from '../../../../lib/urls';
import { usePermissions } from '../../../../hooks';

export const CreateLoanButtonWithPermissions = withRouter(() => {
  const { AppPermissions } = usePermissions();
  return (
    <Visible to={AppPermissions.AddLoans}>
      <Button size="regular" type="primary" to={loanPath({ action: 'create' })}>
        <Text id="LoanList.create" />
      </Button>
    </Visible>
  );
});
