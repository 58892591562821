import React from 'react';

import { BalanceSheetT } from '@kwara/models/src/models/v1/BalanceSheet/Report';
import Empty from '@kwara/components/src/Empty';
import { Currency, Text } from '@kwara/components/src/Intl';
import Table, { Row, Heading, Footer, Cell } from '../../../../../components/Table';

interface TableT {
  isLoading: boolean;
  report: BalanceSheetT;
}

export function ReportsTable({ report, isLoading }: TableT) {
  const heading = React.useMemo(
    () => (
      <Row>
        <Heading translationId="AccountingReports.V1.BalanceSheet.Table.header.glName" />
        <Heading translationId="AccountingReports.V1.BalanceSheet.Table.header.glCode" />
        <Heading translationId="AccountingReports.V1.BalanceSheet.Table.header.balanceType" />
        <Heading translationId="AccountingReports.V1.BalanceSheet.Table.header.balance" />
      </Row>
    ),
    []
  );

  if (!report) {
    return null;
  }

  const rows = report.rows || [];
  if (rows.length === 0) {
    return (
      <Table
        heading={heading}
        footer={
          <Row>
            <Cell colSpan={4}>
              <Empty>
                <Text id="AccountingReports.V1.BalanceSheet.Table.footer.empty" />
              </Empty>
            </Cell>
          </Row>
        }
      />
    );
  }

  return (
    <Table heading={heading} footer={<Footer colSpan={4} hasMore={false} isLoading={isLoading} items={rows} />}>
      {rows.map(row => {
        return (
          <Row key={row.id}>
            <Cell>{row.glName}</Cell>
            <Cell>{row.glCode}</Cell>
            <Cell>{row.balanceType}</Cell>
            <Cell>
              <Currency value={row.balance} />
            </Cell>
          </Row>
        );
      })}
    </Table>
  );
}
