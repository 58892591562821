//@flow

import * as React from 'react';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/fp/sortBy';

import { type TransactionType } from '@kwara/models/src';
import Empty from '@kwara/components/src/Empty';
import Table, { Cell, Collapsible, Row } from '../Table';
import { Text } from '@kwara/components/src/Intl';
import { getYear } from '@kwara/lib/src/dates';

type Props = {
  heading: React.Element<typeof Row>,
  renderRow: (transaction: TransactionType) => React.Node,
  transactions: Array<TransactionType>,
  whitelist?: Array<string>,
  showAccount?: boolean,
  maximumEntries?: number,
  initiallyOpen?: boolean
};

export default ({ transactions, whitelist, maximumEntries, heading, renderRow, initiallyOpen }: Props) => {
  const displayableTransactions = whitelist
    ? transactions.filter((transaction: TransactionType) => whitelist.includes(transaction.type))
    : transactions;

  const orderedTransactions = sortBy(
    // Descending by id, which for Mambu
    // is also descending chronological
    t => -Number(t.id),
    displayableTransactions
  );

  const groupedTransactions = groupBy(orderedTransactions, (transaction: TransactionType) =>
    getYear(transaction.createdAt || transaction.valueDate)
  );

  let entriesTruncated = false;
  if (maximumEntries && transactions.length === maximumEntries) {
    entriesTruncated = true;
  }

  const tableWidth = React.Children.count(heading.props.children);

  return (
    <React.Fragment>
      <Table
        heading={heading}
        emptyState={
          <Row>
            <Cell colSpan={tableWidth}>
              <Empty>
                <Text id="TransactionsTable.empty" />
              </Empty>
            </Cell>
          </Row>
        }
      >
        {Object.keys(groupedTransactions)
          .reverse()
          .map(year => (
            <Collapsible initiallyOpen={initiallyOpen} key={year}>
              <Row size="large">
                <Cell colSpan={tableWidth - 1}>
                  <span className="dib">{year}</span>
                </Cell>
              </Row>
              {groupedTransactions[year].map(transaction => renderRow(transaction))}
            </Collapsible>
          ))}
      </Table>
      {entriesTruncated && (
        <div className="mt3 grey-400">
          <Text id="TransactionsTable.truncated" />
        </div>
      )}
    </React.Fragment>
  );
};
