import invoke from 'lodash/fp/invoke';
import get from 'lodash/fp/get';
import lodashOrderBy from 'lodash/fp/orderBy';
import lodashGroupBy from 'lodash/fp/groupBy';

import { ValueOf } from 'GlobalTypes';

import { LoanDisbursementAppraisalsType } from '@kwara/models/src';
import { LoanType, LoanState, V1LoanBaseStates } from '@kwara/models/src/models/Loan';

import { useMaxDisbursers } from '../../../Loan/LoanDisbursal/useMaxDisbursers';

const Groups = { state: 'state' };
const Orders = { updated: 'updated_at' };

export type Group = ValueOf<typeof Groups>;
export type Order = ValueOf<typeof Orders>;
type GroupedItems = { [id: Group]: LoanType[] };

type UseStatusTagPropsArgTypes = {
  state: LoanState;
  noOfAppraisals?: number;
};

type FormattedLoan = {
  id: string;
  name: string;
  loan: string;
  amount: number;
  interestRate: number | null;
  repaymentFrequency: string | null;
  duration: string | null;
  state: LoanState;
  updated: string;
  appraisals: LoanDisbursementAppraisalsType[];
};

function itemsGroupedByAttribute(loans: LoanType[], attribute: Group): GroupedItems {
  return lodashGroupBy(({ attributes }) => attributes[attribute], loans);
}

function orderByAttribute(loans: LoanType[], attribute: Order): LoanType[] {
  return lodashOrderBy([({ attributes }) => attributes[attribute]], ['desc'], loans);
}

function useStatusTagProps({ state, noOfAppraisals }: UseStatusTagPropsArgTypes) {
  const maxDisbursers = useMaxDisbursers();
  if (noOfAppraisals == null) return { state };

  if (state == 'APPROVED') {
    return {
      state: 'GENERIC_NO_OF_APPRAISALS',
      values: { ratio: `${noOfAppraisals}/${maxDisbursers}` }
    };
  }

  return { state };
}

function getAmount(isV1: boolean, loan: LoanType) {
  if (!isV1) return loan.amount;

  const state = loan.state.current;

  if (state === V1LoanBaseStates.APPROVED_AWAITING_DISBURSEMENT) {
    return loan.recommendedAmount;
  }

  if (state === V1LoanBaseStates.LIVE) {
    return loan.disbursedAmount;
  }

  return loan.amount;
}

function formatter(model: LoanType, isV1?: boolean): FormattedLoan {
  return {
    id: model.id,
    loan: model.name,
    amount: getAmount(isV1!, model),
    duration: model.duration,
    updated: model.updatedAt,
    state: model.state.current,
    name: isV1 ? get('memberName', model) : invoke('member.fullName', model),
    interestRate: model.interestRatePercent(),
    repaymentFrequency: model.repaymentFrequency,
    appraisals: model.loanApplication?.disbursement?.appraisals
  };
}

function getDisbursers(appraisals: LoanDisbursementAppraisalsType[], andSymbol: string) {
  const usersFullNames = appraisals.map(({ user }) => user.fullName());

  /**
   * The below regexp does the following:
   * 1. Coverts ["Name1"] to Name1
   * 2. Converts ["Name 1", "Name2"] to Name1 and Name2
   * 3. Converts ["Name 1", "Name2", "Name3", "LastNameInTheList"] to
   *    Name1, Name2, Name3 and LastNameInTheList
   */
  return usersFullNames.join(', ').replace(/, ((?:.(?!, ))+)$/, ` ${andSymbol} $1`);
}

export { itemsGroupedByAttribute, orderByAttribute, useStatusTagProps, formatter, getDisbursers, Groups, Orders };
