import React from 'react';
import cx from 'classnames';

import { LoadingSkeleton } from '@kwara/components/src/Loadable/LoadingSkeleton';

import styles from './FullPageLoader.module.scss';

export function FullPageLoader() {
  return (
    <div className="w-100 pa5" data-testid="loanRDLoader">
      <div className="flex justify-between mb3">
        <LoadingSkeleton shape="radius-horizone" width="150px" height="35px" />
        <LoadingSkeleton shape="radius-horizone" width="150px" height="35px" />
      </div>

      <div className={cx('mb5 w-40', styles['wrapper'])}>
        <LoadingSkeleton shape="radius-horizone" width="100%" height="35px" />
        <LoadingSkeleton shape="radius-horizone" width="100%" height="35px" />
        <LoadingSkeleton shape="radius-horizone" width="100%" height="35px" />
      </div>

      <div className="flex justify-between">
        <div className="w-30 pr3">
          {[...new Array(10)].map((_, i) => (
            <LoadingSkeleton key={i.toString()} shape="radius-horizone" width="100%" height="35px" noChildren />
          ))}
        </div>

        <div className={cx('w-70', styles['wrapper'])}>
          {[...new Array(10)].map((_, i) => (
            <LoadingSkeleton key={i.toString()} shape="radius-horizone" width="100%" height="50px" noChildren />
          ))}
        </div>
      </div>
    </div>
  );
}
