import * as React from 'react';
import { LoanSubstepProps } from 'GlobalTypes';

import { Container } from '../../../../../../../layouts';
import Guarantors from '../../../../../../../components/Guarantors/Guarantors';
import Collaterals from '../Collaterals/Collaterals';

interface SecurityPropTypes extends LoanSubstepProps {
  disableEditing?: boolean;
}

export function Security(props: SecurityPropTypes) {
  return (
    <Container.Root>
      <Container.Heading id="LoanAppraisal.IncomeAndSecurity.Security.heading" />

      <Container.Body>
        <Guarantors.Component {...props} withControls readonly saveAs="SERVER" />
        <Collaterals.Component {...props} />
      </Container.Body>
    </Container.Root>
  );
}
