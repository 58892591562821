import { useQuery } from 'react-query';

import { ApiError } from 'GlobalTypes';

import { MemberSaving } from '@kwara/models/src';
import { SavingType } from '@kwara/models/src/models/Saving';

import { useAuth } from './useAuth/useAuth';

export async function fetchSavings({ queryKey }) {
  const [_, id, where, includes] = queryKey;

  const res = await MemberSaving(id)
    .where(where)
    .includes(includes)
    .all();
  return res.data;
}

const defaultWhere = {};
const defaultIncludes = ['product'];
export const useSavingsForMember = (memberId, where = defaultWhere, includes = defaultIncludes) => {
  const auth = useAuth();

  return useQuery<SavingType[], ApiError>(['member_savings', memberId, where, includes], fetchSavings, {
    enabled: !auth.isV1(),
    retry: (failCount, error) => ((error as any).status === 404 ? false : failCount < 3)
  });
};
