import { GLReports } from './GLReports';
import { TrialBalance } from './TrialBalance';
import { ProfitLoss } from './ProfitLoss';
import { BalanceSheet } from './BalanceSheet';

export const AccountingReports = {
  GLReport: GLReports,
  TrialBalance: TrialBalance,
  ProfitLoss: ProfitLoss,
  BalanceSheet: BalanceSheet
};
