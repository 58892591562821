import * as React from 'react';
import { Form } from 'react-final-form';
import cx from 'classnames';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';

import { formatHumanDate } from '@kwara/lib/src/dates';
import { Text, Date as DateIntl, Currency } from '@kwara/components/src/Intl';
import Modal from '@kwara/components/src/Modal';
import { ButtonBar } from '@kwara/components/src/ButtonBar';
import Button from '@kwara/components/src/Button';
import { SubscribedSelectField, SubscribedDatePicker, Condition } from '@kwara/components/src/Form';
import { SubscribedTextArea } from '@kwara/components/src/Wizard/deprecated/FormFields';
import createValidator from '@kwara/lib/src/validator';
import { ModelErrorBanner } from '@kwara/components/src/ModelErrorBanner';
import { If } from '@kwara/components/src/If/If';
import { SavingsTransaction, LoanTransaction } from '@kwara/models/src';

import { AutoDismissableCompletion } from '../Completion';
import { Panel } from '../ActionModal';
import { AdjustmentTransactionType } from '.';

import styles from './index.module.scss';

const DATE_OPTION = {
  BACKDATE: 'backdate',
  ORIGINAL: 'original_date'
};

type PropTypes = {
  transaction: AdjustmentTransactionType;
  spraypaintModel: SavingsTransaction | LoanTransaction;
  onClose(): void;
  onSuccess(): void;
};

const selectOptions = [
  {
    key: DATE_OPTION.ORIGINAL,
    value: 'MemberDetail.Transactions.Subsection.Actions.Adjust.Modal.Select.Option.originalDate'
  },
  {
    key: DATE_OPTION.BACKDATE,
    value: 'MemberDetail.Transactions.Subsection.Actions.Adjust.Modal.Select.Option.backDate'
  }
];

export function TransactionAdjustModal({ transaction, spraypaintModel, onClose, onSuccess }: PropTypes) {
  const [errors, setErrors] = React.useState(null);
  const [success, setSuccess] = React.useState(false);

  async function onSubmit({
    notes,
    bookingDate,
    transaction,
    dateOption
  }: {
    notes: string;
    bookingDate: string;
    transaction: AdjustmentTransactionType;
    dateOption: string;
  }) {
    const transactionInstance = new spraypaintModel({ ...transaction });
    const resultBookingDate =
      dateOption === DATE_OPTION.BACKDATE ? { bookingDate: formatHumanDate(bookingDate) } : null;
    const response = await transactionInstance.adjust({ notes, ...resultBookingDate });

    if (!response) {
      setErrors(transactionInstance.errors);
    } else {
      setErrors(null);
      setSuccess(true);
      onSuccess();
    }
  }

  return (
    <If
      condition={!!transaction}
      do={
        <Modal isOpen overlayClassName={styles['overlay']}>
          <div className="flex w-100 h-100 items-center justify-center">
            <Panel>
              <ModelErrorBanner errors={errors} />
              <If
                condition={success}
                do={<AutoDismissableCompletion onConfirm={onClose} type="good" />}
                else={
                  <div className={styles['container']}>
                    <h2 className="mb0 kw-text-large kw-weight-bold neutral-900">
                      <Text id="MemberDetail.Transactions.Subsection.Actions.Adjust.Modal.title" />
                    </h2>
                    <div className={cx(styles['items'], 'neutral-900')} aria-label="Transaction Details">
                      <div className="flex flex-column">
                        <div className="kw-text-medium">
                          <Text id="MemberDetail.Transactions.Subsection.Actions.Adjust.Modal.deposit" />
                        </div>
                        <div className="mt1 kw-text-large kw-weight-bold">
                          <Currency value={transaction?.amount} format="currency" />
                        </div>
                      </div>
                      <div className="flex flex-column">
                        <div className="kw-text-medium">
                          <Text id="MemberDetail.Transactions.Subsection.Actions.Adjust.Modal.date" />
                        </div>
                        <div className="mt1 kw-text-large kw-weight-bold">
                          <DateIntl value={transaction?.createdAt} />
                        </div>
                      </div>
                    </div>
                    <h3 className="ma0 kw-text-medium kw-weight-bold neutral-900">
                      <Text id="MemberDetail.Transactions.Subsection.Actions.Adjust.Modal.adjustDate" />
                    </h3>
                    <Form
                      validate={createValidator(adjustTransactionValidateConfig)}
                      onSubmit={onSubmit}
                      initialValues={{ transaction, dateOption: DATE_OPTION.ORIGINAL }}
                      render={({ valid, form, submitting }) => {
                        const transactionCreatedAt = new Date(get('createdAt', transaction));
                        const adjustmentCanBeBackdated = get('adjustmentCanBeBackdated', transaction);
                        return (
                          <>
                            <If
                              condition={adjustmentCanBeBackdated}
                              do={
                                <SubscribedSelectField margin={false} name="dateOption" showInfo={false} required>
                                  {map(
                                    option => (
                                      <SubscribedSelectField.Option
                                        key={option.key}
                                        value={option.key}
                                        translationId={option.value}
                                      />
                                    ),
                                    selectOptions
                                  )}
                                </SubscribedSelectField>
                              }
                            />

                            <Condition when="dateOption" is={DATE_OPTION.BACKDATE}>
                              <SubscribedDatePicker
                                disabledDays={{ before: transactionCreatedAt }}
                                name="bookingDate"
                                size="medium"
                                labelId=""
                                required
                              />
                            </Condition>

                            <SubscribedTextArea
                              name="notes"
                              labelId="MemberDetail.Transactions.Subsection.Actions.Adjust.Modal.note"
                              required
                            />
                            <ButtonBar
                              className="mt4"
                              left={
                                <Button type="secondary" disabled={submitting} onClick={onClose}>
                                  <Text id="MemberDetail.Transactions.Subsection.Actions.Adjust.Modal.Button.cancel" />
                                </Button>
                              }
                              right={
                                <Button
                                  disabled={!valid || submitting}
                                  classNames="mr3"
                                  type="primary"
                                  onClick={form.submit}
                                >
                                  <Text id="MemberDetail.Transactions.Subsection.Actions.Adjust.Modal.Button.adjust" />
                                </Button>
                              }
                            />
                          </>
                        );
                      }}
                    />
                  </div>
                }
              />
            </Panel>
          </div>
        </Modal>
      }
    />
  );
}

const adjustTransactionValidateConfig = {
  notes: {
    isRequired: () => true
  },
  dateOption: {
    isRequired: () => true
  },
  bookingDate: {
    isRequired: (_, allData) => allData.dateOption === DATE_OPTION.BACKDATE
  }
};
