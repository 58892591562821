import find from 'lodash/fp/find';
import get from 'lodash/fp/get';

import { SavingType } from '@kwara/models/src';
import { minus } from '@kwara/lib/src/currency';

export function getMaxAmount(maxOpeningBalance: number, account?: SavingType): number {
  if (maxOpeningBalance == null || account == null) {
    return null;
  }

  return Number(minus(maxOpeningBalance, account.balance));
}

function findMaximumAmount(account) {
  const maxOpeningBalance = get('product.maximumOpeningBalance', account);

  return getMaxAmount(maxOpeningBalance, account);
}

export function useDepositForm({ accounts, accountId, saving }) {
  const selectedAccount = saving || find<SavingType>({ id: accountId }, accounts);
  const maximumAmount = findMaximumAmount(selectedAccount);

  return { selectedAccount, maximumAmount };
}
