import React from 'react';
import get from 'lodash/fp/get';

import { LoanSubstepProps } from 'GlobalTypes';

import { MemberType } from '@kwara/models/src';

import { useSavingsForMember, useAuth } from '../../../../../hooks';
import { AttributesRow } from '../../../../../components/AttributesRow';
import { LoansHorizontalScroller } from '../../../../../components/LoansHorizontalScroller';
import { SavingsHorizontalScroller } from '../../../../../components/SavingsHorizontalScroller';
import { DefaultedLoansHorizontalScroller } from '../../../../../components/DefaultedLoansHorizontalScroller';
import { getAttributeRowsForLoan } from '../LoanDetails/useLoanDetailsData';

function MemberProfile({ data: { loan } }: LoanSubstepProps) {
  const memberData: MemberType = get('member', loan);
  const memberId = get('id', memberData);
  const loanAttributesRow = getAttributeRowsForLoan(loan);

  const savingsR = useSavingsForMember(memberId, { state: 'ACTIVE,LOCKED,DORMANT,MATURED,APPROVED' });

  const auth = useAuth();

  const isV1 = auth.isV1();

  return (
    <>
      <AttributesRow attributes={loanAttributesRow} useBackground={false} />
      {isV1 ? null : (
        <>
          <LoansHorizontalScroller memberId={memberId} />
          <SavingsHorizontalScroller savingsR={savingsR} />
          <DefaultedLoansHorizontalScroller memberId={memberId} />
        </>
      )}
    </>
  );
}

export default {
  Component: MemberProfile,
  validate: {}
};
