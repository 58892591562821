import * as React from 'react';
import get from 'lodash/fp/get';

import { useQueryClient } from 'react-query';

import { LoanSubstepProps } from 'LoanSharedTypes';

import { getCurrentDate } from '@kwara/lib/src/dates';
import { Loadable } from '@kwara/components/src/Loadable';
import { LoanType } from '@kwara/models/src/models/Loan';
import { FeeType } from '@kwara/models/src/models/LoanProduct';
import { useLoan } from '@kwara/models/src/models/request/hooks';

import Wizard from '../../components/Wizard';

import { steps } from './config';
import { useAuth } from '../../hooks';
import { loanPath } from '../../lib/urls';
import { usePayoffLoan } from './usePayoffLoan';

export type LoanPayOffData = {
  isV1: boolean;
  loanId: string;
  loan: LoanType;
  amount: number;
  date: string;
  firstName: string;
  notes?: string;
  /**
   * legacy api only
   */
  method?: string;
  bankGlId?: string;
  bankBranch?: string;
  bankName?: string;
  accountNumber?: string;
  chequeNumber?: string;
  drawer?: string;
  fees?: Array<FeeType>;
  accountId?: string;
  /**
   * v1 api only
   */
  paymentMethodId?: string;
};

export interface LoanPayOffPropTypes extends LoanSubstepProps<LoanPayOffData> {}

export const LoanPayOff = ({ baseUrl, match, history }) => {
  const queryClient = useQueryClient();

  const auth = useAuth();
  const r = useLoan(match.params.loanId);
  const payOffLoan = usePayoffLoan();

  const isV1 = auth.isV1();

  return (
    <Loadable {...r}>
      {(loan: LoanType) => (
        <Wizard
          startId="confirm"
          analyticsId="LoanPayOff"
          titleId="LoanPayOff.shortTitle"
          steps={steps}
          baseUrl={baseUrl}
          history={history}
          initialData={{
            isV1,
            loan,
            fees: [],
            firstName: get('member.firstName', loan),
            loanId: loan.id,
            amount: loan.totalBalance,
            date: getCurrentDate()
          }}
          cancelReturnsTo={loanPath({ id: loan.id })}
          currentStep={match.params.step}
          currentSubStep={match.params.subStep != null ? parseInt(match.params.subStep, 10) : null}
          onSubmit={payOffLoan}
          onSubmitCompletion={() => {
            r.refetch();
            queryClient.invalidateQueries('activity');
            queryClient.invalidateQueries('loan_extras');
            queryClient.invalidateQueries('loan_transactions');
          }}
        />
      )}
    </Loadable>
  );
};
