import { ProfitLoss, DownloadXlsxParams } from '@kwara/models/src/models/v1/ProfitLoss/Report';

import { useWaitingCursor } from '../../../../hooks/useWaitingCursor';

export function useDownloadProfitLoss() {
  const { onWaitingStart, onWaitingEnd, isWaiting: isDownloading } = useWaitingCursor();

  function onDownloadXlsx(params: DownloadXlsxParams) {
    onWaitingStart();
    return ProfitLoss.downloadXlsx(params).finally(onWaitingEnd);
  }

  return { onDownloadXlsx, isDownloading };
}
