import * as React from 'react';
import { Form as ReactFinalForm } from 'react-final-form';

import Button from '@kwara/components/src/Button';
import createValidator from '@kwara/lib/src/validator';

import { Text } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src/If/If';
import { SubscribedDatePicker } from '@kwara/components/src/Form';
import { getCurrentDate } from '@kwara/lib/src/dates';
import { ErrorTextWithI18n } from '@kwara/components/src/ErrorText';

import { useDownloadBalanceSheet } from '../useDownloadBalanceSheet';

import styles from '../index.module.scss';

type Payload = { endDate: string };

export function Form({ onSubmit }: { onSubmit: (data: Payload) => void }) {
  const { onDownloadXlsx, isDownloading } = useDownloadBalanceSheet();

  return (
    <ReactFinalForm
      onSubmit={onSubmit}
      validate={createValidator({
        endDate: {
          isRequired: () => true,
          isNotFuture: true
        }
      })}
      render={({ hasValidationErrors, handleSubmit, hasSubmitErrors, submitting, values }) => {
        const { endDate } = values;

        return (
          <form className="w-100 flex items-center" aria-label="Account Report Form" onSubmit={handleSubmit}>
            <div className="dib mr3">
              <SubscribedDatePicker
                disabled={submitting}
                disabledDays={{ after: getCurrentDate() }}
                showInfo={false}
                compact
                margin={false}
                required
                name="endDate"
                leftGlyph="AccountingReports.Index.Form.EndDate.leftGlyph"
              />
            </div>

            <div className={styles.submit}>
              <Button
                disabled={hasValidationErrors || submitting}
                isSubmit
                type="primary"
                glyphRightId={submitting ? Button.Glyphs.Spinner : null}
              >
                <Text id="AccountingReports.Index.Form.submit.generate" />
              </Button>
              <Button
                className="ml3"
                disabled={hasValidationErrors || submitting || isDownloading}
                glyphRightId={isDownloading ? Button.Glyphs.Spinner : null}
                onClick={() => onDownloadXlsx({ end_date: endDate })}
              >
                <Text id="Generic.download" />
              </Button>
              <If
                condition={hasSubmitErrors}
                do={<ErrorTextWithI18n id="AccountingReports.Index.Form.submit.error" />}
              />
            </div>
          </form>
        );
      }}
    />
  );
}
