import React from 'react';
import get from 'lodash/fp/get';

import { LoanSubstepProps } from 'GlobalTypes';

import { RepaymentMethod } from '../RepaymentMethod';

import { RepaymentFieldsBase } from './RepaymentFieldsBase';
import { getRepaymentTerms } from './utils';
import { useLoanRepaymentFields } from './useLoanRepaymentFields';

type Props = LoanSubstepProps & { isV1?: boolean };

export const LoanRepaymentFields = (props: Props) => {
  const { data, formProps, isV1 } = props;
  const { product, feeApplications } = data;
  const { amount, loanDuration, repaymentPeriodUnit } = formProps.values;

  const args = { product, feeApplications, amount, loanDuration, repaymentPeriodUnit };
  const { fixedRepayment, repaymentAmountR } = useLoanRepaymentFields(args, isV1);

  return (
    <RepaymentFieldsBase {...props} fixedRepayment={fixedRepayment} repaymentAmountR={repaymentAmountR} isV1={isV1} />
  );
};

LoanRepaymentFields.validate = data => {
  return {
    ...(data.isV1 ? null : RepaymentMethod.validateConfig),
    repaymentPeriod: {
      isRequired: () => true,
      positiveInteger: true
    },
    loanDuration: {
      isRequired: (_, allData) => !get('product.instantLoan', allData),
      custom: (target, allData) => {
        const { repaymentPeriod } = allData;

        const { maxDurationValue, minDurationValue } = getRepaymentTerms(allData);

        if (Number(target) > maxDurationValue) {
          return 'MaxDurationExceeded';
        }

        if (Number(target) < minDurationValue) {
          return 'MinDurationExceeded';
        }

        // Prevents "NaN Installments" from displaying if other
        // field ('repaymentPeriod') has non-positive input
        if (Number.isNaN(repaymentPeriod)) {
          return 'NaNError';
        }

        if (Number(target) < Number(repaymentPeriod)) {
          return 'extendLoanDuration';
        }

        return null;
      },
      positiveInteger: true
    }
  };
};
