import { useQuery } from 'react-query';

import { PaymentMethod } from '@kwara/models/src';

function fetchPaymentMethods() {
  return PaymentMethod.all().then(res => res.data);
}

export function usePaymentMethods() {
  return useQuery(['payment_methods'], fetchPaymentMethods, { placeholderData: [] });
}
