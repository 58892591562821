import React from 'react';

import MemberPanel from '../../../../../components/Payment/MemberPanel';
import NotesPanel from '../../../../../components/Payment/NotesPanel';

import { SubscribedTransactionForm } from '../../../../../components/v1/PaymentForm/SubscribedTransactionForm';
import { SubStepComponentProps } from '../../../../../components/Wizard';
import { useTransactionForm } from '../../../../../components/v1/PaymentForm/useTransactionForm';

import { useRepaymentForm } from '../../useRepaymentForm';

export function RepaymentFormV1({ StackChild, data, addData, formProps }: SubStepComponentProps<FormData>) {
  const { values } = formProps;
  const { accountId } = values;
  const { member, accounts, loan } = data;

  const { paymentMethods, glLinks } = useTransactionForm({ method: values.method, transactionType: 'repayment' });
  const { selectedAccount } = useRepaymentForm({ accounts, loan, accountId });

  return (
    <StackChild>
      <MemberPanel member={member} showFinancialInfo />

      <SubscribedTransactionForm
        accounts={accounts}
        selectedAccount={selectedAccount}
        paymentMethods={paymentMethods}
        glLinks={glLinks}
        hasAccountSelection={true}
        addData={addData}
      />

      <NotesPanel />
    </StackChild>
  );
}

RepaymentFormV1.validate = SubscribedTransactionForm.defaultValidation;
